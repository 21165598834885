import {useEffect, useState} from 'react'
import {connect, useSelector, useDispatch} from 'react-redux'
import {useHistory} from 'react-router'

import {changeLogo, setGoRouter} from '../../redux/modules/app'
import {
  getRecentlyViewed,
  getShoppingCart,
  saveShoppingCart,
} from '../../Utils/service'
import Modal from 'react-modal'
import CommunesSearch from '../../Components/CommunesSearch'
import ProductSearch from '../../Components/ProductSearch'
import {ENUM_MENU} from '../../Utils/constants'
import {getHash, _formatPrice} from '../../Utils/tools'
import {fetchCommunes} from '../../redux/modules/delivery'
import {addEventNameWithParams, cleanText, logEvent} from '../../Utils/utils'
import StepperLocal from '../../Components/Stepper'
import ButtonYapp from '../../Components/Buttons/ButtonYapp'
import ModalAlertLocation from '../../Components/Modals/ModalAlertLocation'
import ModalPreview from '../../Components/Modals/ModalPreview'
import {getValues} from '../../Utils/rc_get_instance'
import BadgesRefundable from '../../Components/Badges/BadgesRefundable'

/* imgs */
import loadingYapp from '../../assets/img/yapp_loading.gif'
import productImg from '../../assets/img/prescription.png'
import minsalUso from '../../assets/img/minsal-uso-racional.png'
import minsalVenta from '../../assets/img/minsal_ventas_directas.png'
import TypeSidetab from '../../Components/TypeForm/TypeSidetab'

const _ = require('lodash')

function InitialSearch(props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const [appState, setAppState] = useState({loading: true, data: []})
  const [loadingProducts, setLoadingProducts] = useState(false)
  const [productDelete, setProductDelete] = useState(null)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [visibleAlertLocation, setVisibleAlertLocation] = useState(false)
  const [resultRemoteConfig, setResultRemoteConfig] = useState([])
  const [recentlyViewed, setRecentlyViewed] = useState([])
  const [showPreview, setShowPreview] = useState(false)
  const [modalImage, setModalImage] = useState([])
  const enabledInsurance = useSelector((state) => state.app?.enabledInsurance)

  const onePharmacy = useSelector((state) => state.app.one_pharmacy)

  useEffect(() => {
    if (getHash()) {
      dispatch(fetchCommunes())
    }

    try {
      if (process.env.REACT_APP_CLIENT_NAME === 'UC Christus') {
        let router = []
        router.push({
          go: 'https://www.ucchristus.cl/yapp',
          target: '_blank',
          title: 'Buscador de medicamentos',
          description: 'Red de Salud UC CHRISTUS',
        })
        dispatch(props.setGoRouter(router))
      }
    } catch (ignore) {}

    getRecentlyViewed()
      .then((result) => {
        setRecentlyViewed([...result])
      })
      .catch((err) => console.log(err))

    const remoteConfigFn = async () => {
      let result = await getValues('Banners_web_patients')
      setResultRemoteConfig(JSON.parse(result?._value))
    }
    remoteConfigFn()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getArrFirestore = async () => {
      try {
        setLoadingProducts(true)
        let arrFirestore = await getShoppingCart()
        setAppState({loading: false, data: arrFirestore})
        setLoadingProducts(false)
      } catch (error) {
        console.log(error)
      }
    }
    getArrFirestore()
  }, [])

  const onClickSearch = async (newValue, origin = null) => {
    if (newValue?.value) {
      setLoadingProducts(true)
      let arrFirestore = await getShoppingCart()
      let product = newValue

      if (Array.isArray(arrFirestore) && product) {
        if (
          arrFirestore.find((el) => el.product_id === product.value) ===
          undefined
        ) {
          product.data.counter = 1
          product.data.origin = origin
          arrFirestore.push(product.data)
          saveShoppingCart(arrFirestore)
        }
      }

      setAppState({loading: false, data: arrFirestore})
      setLoadingProducts(false)
    } else {
      console.log('Error value')
    }
  }

  function clickShowDetail(productId, product) {
    addEventNameWithParams('gtm-shopping-bag-show-detail', {
      product_id: product?.product_id,
    })
    history.push(`/product/detail/${productId}`, {
      product_id: productId,
      external_vademecum: 0,
      resultSearch: product?.resultSearch,
    })
  }

  function bioequivalentReplace(props) {
    let path =
      '/search/bioequivalent/' +
      Math.floor(Math.random() * Math.floor(100000000000000000000))
    history.push(path, {productSelect: props})
  }

  const clickUpdateCounter = async (element, value, confirm = false) => {
    let arrFirestore = await getShoppingCart()
    let newArr = []
    if (value === 0) {
      setProductDelete(element)
      openModal()
      if (confirm === true) {
        newArr = arrFirestore.filter((el) => {
          if (el.product_id !== element.product_id) {
            return el
          }
        })

        saveShoppingCart(newArr)
        setAppState({data: newArr})
        closeModal()
      }
    } else {
      newArr = arrFirestore.map((el) => {
        if (el.product_id === element.product_id) {
          el.counter = value
        }

        return el
      })

      saveShoppingCart(newArr)
      setAppState({data: newArr})
    }
  }

  const onClickEmptyCart = () => {
    let newShoppingCart = []
    saveShoppingCart(newShoppingCart)
    setAppState({data: newShoppingCart})
  }

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  async function actionNext() {
    await saveShoppingCartTag()

    // let enableBeneficiaries = process.env.REACT_APP_PROFILE === 'INSURANCE'

    // if (enableBeneficiaries) {
    //   history.push('/cia/beneficiaries')
    // } else {
    history.push('/shopping_bag/quotation')
    // }
  }

  async function saveShoppingCartTag() {
    let shoppingCart = await getShoppingCart()
    if (shoppingCart) {
      let productQuantity = 0

      for (const product of shoppingCart) {
        productQuantity += product.counter
      }

      logEvent('Product Added', {
        'Total Cart Size': productQuantity,
      })
    }
  }

  const goTo = (item) => {
    logEvent('Banner Checked', {
      'Banner Name': item?.title,
    })
  }

  const onClickShare = () => {
    logEvent('Referral Checked')
    history.push('/share')
  }
  const handleClose = () => {
    setShowPreview(false)
    setModalImage({})
  }

  function onClickShowPreview(product_logo) {
    setModalImage(product_logo)
    setShowPreview(true)
  }

  const showProductBadge = (product) => {
    let badge = null

    if (![null, undefined].includes(product?.id)
      && (process.env.REACT_APP_PROFILE === 'INSURANCE' || enabledInsurance)
    )
      badge = <BadgesRefundable label="Reembolsable" />
    else if ((product?.client_benefit)
      && (![null, undefined,'FALSE'].includes(process.env.REACT_APP_CLIENT_BENEFIT)))
      badge = (
        <BadgesRefundable 
          label={process.env.REACT_APP_CLIENT_BENEFIT}
          color={process.env.REACT_APP_CLIENT_BENEFIT_COLOR}
          dataTip="Beneficio de hasta 50% de descuento"
        />
      )
    
    return badge
  }

  return (
    <div className="flex-grow-1 pb-4">
      <div className="content-responsive pt-4">
        <div className="card-container mt-2">
          <h3 className="txt-2--bold-db mb-3 mt-2">
            ¡En pocos pasos estarás ahorrando en tus medicamentos!
          </h3>
          <div className="mb-3 pb-2">
            <div className="content-number-text mb-1">
              <div className="cirle-number">
                <span className="txt-5--regular-li">1</span>
              </div>
              <p className="txt-4--regular-db ml-2 mb-1">
                ¿En qué comuna quieres cotizar tu medicamento? 
              </p>
            </div>
            <div className="mb-3">
              <CommunesSearch
                isNotShow
                themeColor={'--theme-color'}
                newStyle
                nextAction={() => {
                  return
                }}
              />
            </div>
            <div className="content-number-text mb-1">
              <div className="cirle-number">
                <span className="txt-5--regular-li">2</span>
              </div>
              <p className="txt-4--regular-db ml-2">
                ¿Qué medicamento buscas?
              </p>
            </div>
            <div>
              <ProductSearch
                themeColor={'--theme-color'}
                styleSecondary
                onlyProducts={true}
                isNotShow
                resetStyle
                onClickSearch={onClickSearch}
                from="search"
                request_from={ENUM_MENU.medication_buy}
              />
            </div>
          </div>
          <div className="w-100">
            <div className="d-flex justify-center-between w-100">
              <h3 className="txt-4--bold-db">Tu lista de medicamentos</h3>
              {appState?.data.length > 0 && !loadingProducts && (
                <div
                  className="ml-auto d-flex c-pointer"
                  onClick={() => onClickEmptyCart()}>
                  <span className="txt-5--regular-pr mr-1">
                    Vaciar{' '}
                    <span className="d-none d-md-inline">
                      lista de medicamentos
                    </span>
                  </span>
                  <i className="icon-delete color-lilac"></i>
                </div>
              )}
            </div>
            <div>
              <div
                className={` ${
                  appState?.data.length > 0 ? 'new-patient d-flex flex-nowrap bag-content flex-lg-wrap mt-0 pt-2 with-border' : 'no-border'
                }`}>
                {appState.data && !loadingProducts ? (
                  appState.data?.map((el, key) => {
                    return (
                      <div
                        key={key}
                        className="product-detail-new mb-2 c-pointer position-relative">
                        { showProductBadge(el) }
                        <div
                          className="d-none d-md-flex"
                          onClick={() => clickUpdateCounter(el, 0, true)}>
                          <i className="icon-delete ml-auto color-darkblue-ds"></i>
                        </div>
                        <div
                          id="gtm-webapp-mobile-product-detail"
                          // onClick={() => clickShowDetail(el.product_id)}
                          className="">
                          <div className="product-detail-new-img text-center">
                            <img
                              src={el.product_logo}
                              className="product-detail-img mx-auto"
                              alt={el.product_name}
                              onClick={() =>
                                onClickShowPreview(el?.product_logo)
                              }
                            />
                          </div>
                          <div
                            className="content-txt mb-3"
                            onClick={() => clickShowDetail(el.product_id)}>
                            <p className="txt-4--regular  line-14 mt-2 mb-2  text-ellipsis--2 color-darkblue-ds">
                              {cleanText(el.product_name)}
                            </p>
                            <p className="txt-paragraph--medium line-14 color-darkblue-ds">
                              {cleanText(el.presentation)}
                            </p>
                            <p className="txt-paragraph--medium line-14 color-darkblue-ds">
                              {cleanText(el.laboratory_name)}
                            </p>
                          </div>
                        </div>
                        <StepperLocal
                          className="mt-auto justify-content-center"
                          counter={el.counter}
                          style={localStyles.stepper}
                          clickUpdateCounter={(event) => {
                            clickUpdateCounter(el, event)
                          }}
                        />
                        <div className="d-flex justify-content-center mt-3">
                          <p
                            id="gtm-webapp-shoping-bag-bioequivalent-mobile"
                            className="txt-5--bold color-theme-secondary text-center"
                            onClick={() => bioequivalentReplace(el)}>
                            Ver bioequivalentes
                          </p>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className="d-flex justify-content-center w-100 py-5">
                    <img src={loadingYapp} alt="loading" height={70} />
                  </div>
                )}
                
              </div>
            </div>
            {!appState?.data.length > 0 && !loadingProducts && (
              <div className="content-list-product-empty">
                <p className="txt-4--regular-db">
                  Aún no has agregado ningún medicamento a tu lista
                </p>
              </div>
            )}
            {!loadingProducts && appState.data?.length > 0 && (
              <div className="d-flex justify-content-center mt-4 mb-4">
                <ButtonYapp
                  className="px-5"
                  id="gtm-webapp-shopping-bag-btn-next"
                  color={'--theme-color'}
                  big
                  title={
                    ![undefined, null, false].includes(onePharmacy)
                      ? 'Cotizar'
                      : 'Cotizar en farmacias'
                  }
                  onClick={() => {
                    if (!props.commune) {
                      setVisibleAlertLocation(true)
                    } else {
                      actionNext()
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {recentlyViewed?.length > 0 && (
          <div className="card-container" style={{marginTop: '32px', marginBottom: '52px'}}>
            <h3 className="txt-2--bold-db mb-3 mt-2">Vistos recientemente</h3>
            <div
              className="d-flex flex-nowrap flex-lg-wrap bag-content"
              style={{border: '1px solid transparent'}}>
              {recentlyViewed.map((el, key) => {
                return (
                  <div key={key} className="product-detail-new mb-2 c-pointer">
                    <div
                      id="gtm-webapp-mobile-product-detail"
                      // onClick={() => clickShowDetail(el.product_id)}
                      className="">
                      <div className="product-detail-new-img text-center">
                        <img
                          src={el.product_logo}
                          className="product-detail-img mx-auto"
                          alt={el.product_name}
                          onClick={() => onClickShowPreview(el.product_logo)}
                        />
                      </div>
                      <div
                        className="content-txt mb-3"
                        onClick={() => clickShowDetail(el.product_id)}>
                        <p className="txt-4--regular  line-14 mt-2 mb-2  text-ellipsis--2">
                          {cleanText(el.product_name)}
                        </p>
                        <p className="txt-paragraph line-14 color-darkblue-ds">
                          {cleanText(el.presentation)}
                        </p>
                        <p className="txt-paragraph line-14 color-darkblue-ds">
                          {cleanText(el.laboratory_name)}
                        </p>
                      </div>
                    </div>
                    <div className="w-100 mt-auto">
                      <ButtonYapp
                        onClick={() =>
                          onClickSearch(
                            {value: el.product_id, data: el},
                            'Recently Viewed',
                          )
                        }
                        color={'--theme-color'}
                        className="w-100"
                        title="Agregar"
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      
      <div className="content-responsive maxw-584 mx-auto d-flex justify-content-center minsal-img-container mt-4" style={{gap:'2%'}}>
        <a
          rel="noreferrer"
          className=""
          href="https://www.minsal.cl/wp-content/uploads/2016/04/MAGNETO-USO-RACIONAL-DE-MEDICAMENTOS-PARA-IMPRESION.pdf"
          target="_blank">
          <img
            src={minsalUso}
            alt="minsal uso racional"
            className="minsal-img w-100"
          />
        </a>
        <a
          className=""
          rel="noreferrer"
          href="https://www.minsal.cl/wp-content/uploads/2016/08/30-06-2016-AFICHES_GONDOLAS-DE-FARMACIAS_final_imprimir.pdf"
          target="_blank">
          <img
            src={minsalVenta}
            alt="minsal venta directa"
            className="minsal-img w-100 "
          />
        </a>
      </div>
      </div>

      {process.env.REACT_APP_CLIENT_NAME === 'Pacientes' && (
        <TypeSidetab
          id='I1p4HO0C'
          buttonColor={'#497AD7'}          
          buttonText={'Sugerencias!'}
        />
      )}
      
      {/* modals */}
      <ModalAlertLocation
        title="Necesitamos una ubicación"
        message="Ingresa una comuna para continuar con la búsqueda de tus medicamentos."
        visibleAlertLocation={visibleAlertLocation}
        setVisibleAlertLocation={setVisibleAlertLocation}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="Modal1"
        ariaHideApp={false}>
        {productDelete && (
          <>
            <h4 className="txt-3--bold-db text-center mt-3">
              ¿Deseas eliminar este producto de tu carrito?
            </h4>
            <div className="d-flex flex-column justify-content-center mb-5">
              <img
                src={
                  productDelete?.product_logo
                    ? productDelete.product_logo
                    : productImg
                }
                alt="titulo del producto"
                className="modalt-img"
              />
              <p className="modal-text-product text-center">
                {productDelete?.product_name}
              </p>
            </div>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <ButtonYapp
                className="w-100 mb-3"
                id="gtm-webapp-shopping-bag-btn-delete"
                onClick={() => clickUpdateCounter(productDelete, 0, true)}
                color={'--theme-color'}
                title="Eliminar"
              />
              <ButtonYapp
                className="w-100 mb-3"
                id="gtm-webapp-shopping-bag-btn-leave"
                onClick={closeModal}
                stroke
                color={'--theme-color'}
                title="Dejar en el carrito"
              />
            </div>
          </>
        )}
      </Modal>
      <ModalPreview show={showPreview} onClose={handleClose} img={modalImage} />
    </div>
  )
}

const localStyles = {
  stepper: {
    input: {borderColor: 'transparent', width: '30%', padding: '0.4rem'},
    buttons: {
      backgroundColor: '#46CEBE',
      borderColor: 'transparent',
      padding: '0.4rem',
      width: '3rem',
    },
  },
  rowButton: {
    justifyContent: 'center',
    marginTop: '1rem',
  },
}

const mapStateToProps = (state) => {
  return {
    products: state.cart.products,
    commune: state.app.commune,
    disabledThemeCheckout: state.app.config?.disabled_theme_checkout,
    themeColor: state.app.config?.custom_styles?.theme_color,
    clientLogo: state.app.config?.logo,
    config: state.app.config,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    changeLogo: (logo) => dispatch(changeLogo(logo)),
    setGoRouter: (router) => dispatch(setGoRouter(router)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InitialSearch)
