import React, {useState, forwardRef, useEffect} from 'react'
import Modal from 'react-modal'

import Envelope from '../../assets/img/envelope.svg'
import {_validateMail} from '../../Utils/tools'
import loadingYapp from '../../assets/img/yapp_loading.gif'
import imgResultError from '../../assets/img/i_error.svg'
import Input from '../Forms/Input'
import Toasts from '../Toasts/Toasts'
const ModalSendPrices = forwardRef((props) => {
  const {modalSendPricesState, setModalSendPricesState, showError, stateToasts, item} = props
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [isValid, setIsValid] = useState(true)
  

  useEffect(() => {
    if (modalSendPricesState) {
      setIsValid(true)
    }
  }, [modalSendPricesState])

  function closeModalSend() {
    setLoading(false)
    setModalSendPricesState(false)
  }

  function onChangeEmail(email) {
    setEmail(email)
    setIsValid(_validateMail(email))
  }

  async function handleSendEmail() {
    setLoading(true)
    if (_validateMail(email)) {
      setIsValid(email)
      setEmail('')
      props.onClickButton(email)
      props.onClickButton(item)
    } else {
      setLoading(false)
    }
    setModalSendPricesState(false)
    onChangeEmail(email)
  }

  return (
    <> 
    <Modal
      isOpen={modalSendPricesState}
      onAfterOpen={null}
      onRequestClose={closeModalSend}
      className="modal-custom"
      overlayClassName="modal-custom-bg"
      contentLabel="ModalTypeFormsHome"
      ariaHideApp={false}>
      {!showError && (
        <div>
          <button className="btn-circle-close" onClick={() => closeModalSend()}>
            <i className="icon-close"></i>
          </button>
          {loading ? (
            <div className="w-100 d-flex flex-column justify-content-center align-items-center mt-3 mb-4">
              <img
                src={loadingYapp}
                alt="loading"
                className="loading-yapp--normal m-0"
              />
            </div>
          ) : (
            <div>
              <div className="w-100 d-flex flex-column justify-content-center align-items-center w-100 mt-3">
                <h2 className="txt-3--bold mb-2">Compartir información</h2>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <img
                    src={Envelope}
                    style={{width: '91px', height: '99px'}}
                    alt="envelope"
                  />
                </div>

                <div className="mb-2 w-100 mt-3 mb-4">
                  <Input
                    id="gtm-webapp-validation-email-input"
                    showCopyButton
                    error={
                      !isValid && {
                        message:
                          email.length <= 0
                            ? '*Obligatorio'
                            : '*Correo inválido',
                      }
                    }
                    label="Correo electrónico"
                    placeholder="Correo paciente"
                    doChange={(value) => onChangeEmail(value)}
                  />
                </div>
              </div>
              <button
                id={'gtm-webapp-validation-email-btn'}
                className="btn-default--small font--14 btn-next mx-auto"
                onClick={handleSendEmail}>
                Enviar
              </button>
            </div>
            
          )}
        </div>
      )}
      {showError && (
        <div className="w-100 d-flex flex-column justify-content-center align-items-center w-100 mt-3">
          <button className="btn-circle-close" onClick={() => closeModalSend()}>
            <i className="icon-close"></i>
          </button>
          <img
            src={imgResultError}
            alt="icon-status"
            className="mb-3"
            style={{height: '48px', width: '48px'}}
          />
          <h4 className={`txt-4--bold-db mb-3 text-center`}>
            Ocurrió un error, por favor vuelve a intentarlo
          </h4>
          <button
            id={'gtm-webapp-validation-email-btn'}
            className="btn-default--small font--14 btn-next mx-auto"
            onClick={() => closeModalSend()}>
            Aceptar
          </button>
        </div>
      )}
    </Modal>
    </>
    
  )
})
export default ModalSendPrices
