import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector, connect} from 'react-redux'
import {getJWTdata} from '../../Utils/tools'
import {getProfessionalDiscountCode} from '../../Utils/api'
import icNuevosLanzamientosHomePro from '../../assets/img/ic_nuevos_lanzamientos_home_pro.svg'
import icContactarLaboratorioHomePro from '../../assets/img/ic_contactar_laboratorio_home_pro.svg'
import icEstudiosClinicosHomePro from '../../assets/img/clinical-reseachs.svg'
import IcBuscarFichasMedicamentosHomePro from '../../assets/img/lupa.svg'
import IcInteraccionesMedicamentosaPro from '../../assets/img/Interactions.svg'
import IcProgramaPacientePro from '../../assets/img/programasPacientesInicio.svg'
import IcCuponesDescuento from '../../assets/img/cupones.svg'
import {Menus} from '../../Utils/constants'
import {changeInitialLoading, setMenu} from '../../redux/modules/app'
import {setUserProperties} from '../../Utils/utils'
import {setProfessionalDiscountCode} from '../../redux/modules/user'
import {logEvent} from '../../Utils/utils'
import InstallButton from '../../Components/InstallButton/InstallButton'

function Explore(props) {
  const history = useHistory()
  const dispatch = useDispatch()
  // localStates
  const fromYappApp = localStorage.getItem('fromYappApp') === 'true'
  const [patientSavings, setPatientSavings] = useState(null)
  // globalStates
  const config = useSelector((state) => state.app.config)
  const menu = useSelector((state) => state.app.menu)
  const identity = useSelector((state) => state.user.identity)
  // constants

  useEffect(() => {
    async function fetchData() {
      menu[0].isDisabled && dispatch(changeInitialLoading(true))
      let additionalMenu = []
      let newMenu = []
      let menus = [...Menus]

      try {
        let jwtData = getJWTdata()
        additionalMenu = await getProfessionalDiscountCode(
          jwtData?.user_id,
          identity,
        )
      } catch (e) {}

      if (
        additionalMenu.data?.length > 0 &&
        additionalMenu.data[0]?.length > 0
      ) {
        /* setUserProperties({'Benefit Code': true}) */
        dispatch(
          setProfessionalDiscountCode({
            code: additionalMenu.data[0][0].code,
            products: additionalMenu.data[1],
            categories: additionalMenu.data[2],
          }),
        )
        menus.push('agreement')
      } else {
        setUserProperties({'Benefit Code': false})
        menus.push('agreement-locked')
      }

      if (
        additionalMenu.data?.length > 3 &&
        additionalMenu.data[3]?.length > 0
      ) {
        setPatientSavings(additionalMenu.data[3][0].patient_savings)
      }

      for (const el of menu) {
        newMenu.push({
          ...el,
          isDisabled: !menus.includes(el.id),
          isHidden: !menus.includes(el.id),
        })
      }

      dispatch(setMenu(newMenu))
      dispatch(changeInitialLoading(false))
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goTo = (path) => {
    if (path !== '' && path) {
      if (path === '/search') {
        logEvent('Medication Buy Opened')
        window.open(config?.host_patient)
        try {
          setTimeout(function () {
            var userProperties = {
              'Web Name': process.env?.REACT_APP_CLIENT_NAME,
            }
            setUserProperties(userProperties)
          }, 3000)
        } catch (ignore) {}
      } else {
        history.push(path)
      }
    }
  }

  const goToPage = (url) => {
    switch (url) {
      case '/pro/new_agreement':
        logEvent('Discounts Opened')
        break
      case '/pro/drug_interactions':
        logEvent('Drug Interaction Opened')
        break
      case '/pro/programs':
        logEvent('Program Information Opened')
        break
      case '/pro/new_drugs':
        logEvent('New Releases Opened')
        break
      case '/pro/laboratory':
        logEvent('Contact Laboratory Opened')
        break
      case '/pro/medication-sheet':
        logEvent('Medication Sheet Opened')
        break
      default:
        break
    }
    goTo(url)
  }

  const goToClinicalStudies = () => {
    logEvent('Clinical Studies Opened')
    window.open('https://estudiosclinicos.cl/', '_blank')
  }

  return (
    <div className="flex-grow-1 px-3">
      <div className="pt-3 content-responsive ml-00">
        <div className="mx-auto">
          {/*  {(process.env.REACT_APP_MEDICAL_SAVINGS) && !fromYappApp ? 
          (
            <div className="text-center txt-3--bold mb-4">
              {`Juntos hemos ahorrado más de $${process.env.REACT_APP_MEDICAL_SAVINGS} a los pacientes`}
            </div>
          ):(null)} */}

          <div className="d-flex flex-column flex-md-row flex-wrap justify-content-start row  ml-xs-0 ml-sm-2">
            {process.env.REACT_APP_CLIENT_NAME === 'Médicos' &&
              <div className="d-block d-lg-none w-100 mb-4 mt-2">
                <InstallButton text={'Descargar la App'}/>
              </div>
            }
            <div
              id="gtm-webpro-goto-drug-interactions"
              className="white-card card-btn-home m-2 col-sm-12 col-md-5"
              onClick={() => goToPage('/pro/medication-sheet')}>
              <div className="d-flex align-items-center">
                <img
                  className="icon-card-btn-home-large"
                  src={IcBuscarFichasMedicamentosHomePro}
                  alt="Información y precios de productos"
                />
                <div className="ml-3">
                  <h3 className="title-home m-0 mb-1">
                    Información y precios de productos
                  </h3>
                  <p className="txt-paragraph">
                    Medicamentos, dispositivos médicos...
                  </p>
                </div>
              </div>
              <i className="icon-chevron-right color-lilac"></i>
            </div>
            <div
              id="gtm-webpro-goto-pro-programs"
              className="white-card card-btn-home m-2 col-sm-12 col-md-5"
              onClick={() => goToPage('/pro/programs')}>
              <div className="d-flex align-items-center">
                <img
                  className="icon-card-btn-home-large"
                  src={IcProgramaPacientePro}
                  alt="programas de paciente"
                />
                <div className="ml-3">
                  <h3 className="title-home m-0 mb-1">
                    Programas para pacientes
                  </h3>
                </div>
              </div>
              <i className="icon-chevron-right color-lilac"></i>
            </div>
            <div
              id="gtm-webpro-goto-pro-agreements"
              className="white-card card-btn-home m-2 col-sm-12 col-md-5"
              onClick={() => goToPage('/pro/new_agreement')}>
              <div className="d-flex align-items-center">
                <img
                  className="icon-card-btn-home-large"
                  src={IcCuponesDescuento}
                  alt="Cupones de descuento"
                />
                <div className="ml-3">
                  <h3 className="title-home m-0 mb-1">
                  Inicios de tratamiento / Cupones de descuento
                  </h3>
                </div>
              </div>
              <i className="icon-chevron-right color-lilac"></i>
            </div>
            <div
              id="gtm-webpro-goto-pro-programs"
              className="white-card card-btn-home m-2 col-sm-12 col-md-5"
              onClick={() => goToPage('/pro/drug_interactions')}>
              <div className="d-flex align-items-center">
                <img
                  className="icon-card-btn-home-large"
                  src={IcInteraccionesMedicamentosaPro}
                  alt="Interaciones Medicamentosa"
                />
                <div className="ml-3">
                  <h3 className="title-home m-0 mb-1">
                    Interacciones entre medicamentos
                  </h3>
                </div>
              </div>
              <i className="icon-chevron-right color-lilac"></i>
            </div>
            <div
              id="gtm-webpro-goto-pro-new-drugs"
              className="white-card card-btn-home m-2 col-sm-12 col-md-5"
              onClick={() => goToPage('/pro/new_drugs')}>
              <div className="d-flex align-items-center">
                <img
                  className="icon-card-btn-home"
                  src={icNuevosLanzamientosHomePro}
                  alt="Interaciones Medicamentosa"
                />
                <div className="ml-3">
                  <h3 className="title-home m-0 mb-1">Nuevos lanzamientos</h3>
                </div>
              </div>
              <i className="icon-chevron-right color-lilac"></i>
            </div>
            <div
              id="gtm-webpro-goto-pro-laboratory"
              className="white-card card-btn-home m-2 col-sm-12 col-md-5"
              onClick={() => goToPage('/pro/laboratory')}>
              <div className="d-flex align-items-center">
                <img
                  className="icon-card-btn-home"
                  src={icContactarLaboratorioHomePro}
                  alt="Interaciones Medicamentosa"
                />
                <div className="ml-3">
                  <h3 className="title-home m-0 mb-1">
                    Contactar laboratorios
                  </h3>
                </div>
              </div>
              <i className="icon-chevron-right color-lilac"></i>
            </div>
            <div
              id="gtm-webpro-goto-pro-clinical-researchs"
              className="white-card card-btn-home m-2 col-sm-12 col-md-5"
              onClick={() => goToClinicalStudies()}>
              <div className="d-flex align-items-center">
                <img
                  className="icon-card-btn-home"
                  src={icEstudiosClinicosHomePro}
                  alt="Estudios Clínicos activos en Chile"
                />
                <div className="ml-3">
                  <h3 className="title-home m-0 mb-1">
                    Estudios Clínicos activos en Chile
                  </h3>
                </div>
              </div>
              <i className="icon-chevron-right color-lilac"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    professionalDiscountCode: state.user.professional_discount_code,
  }
}

export default connect(mapStateToProps)(Explore)
