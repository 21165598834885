import React, {useState} from 'react'
import Modal from 'react-modal'
import {useSelector} from 'react-redux'
import ButtonYapp from '../../../../Components/Buttons/ButtonYapp'
import CommunesSearch from '../../../../../src/Components/CommunesSearch'


const ModalSelectComune = (props) => {

  const communeInStore = useSelector((state) => state?.app?.commune)


  function openModal() {
    props.setModalIsOpen(true)
  }

  function closeModal() {
    props.setModalIsOpen(false)
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function checkIfCommuneWasSelected(){
    if ( communeInStore ) {
      closeModal()
    }
  }




  return (
    <>
      <Modal
        isOpen={props.modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="Modal"
        ariaHideApp={false}
      >
        <button className="btn-circle-close" onClick={closeModal}>
          <i className="icon-close"></i>
        </button>
        <div className="d-flex flex-column justify-content-center my-3">
          <h4 className="title-primary--regular text-center">
            Selecciona la comuna donde deseas recibir tu medicamento
          </h4>
        </div>
        <div className="mb-3">
          <CommunesSearch
            isNotShow
            themeColor={'--theme-color'}
            newStyle
            nextAction={() => {}}
          />
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column mt-3">
          <ButtonYapp
            className="mx-auto"
            onClick={checkIfCommuneWasSelected}
            color={'--theme-color'}
            title="Continuar con la cotización"
          />
        </div>
      </Modal>
    </>
  )
}

export default ModalSelectComune