import React, {useState} from 'react'
import Modal from 'react-modal'
import ButtonYapp from '../../Components/Buttons/ButtonYapp'
import ButtonFalseInput from '../../Components/Buttons/ButtonFalseInput'

function ModalBeneficiaries(props) {
  const {
    isOpen,
    setIsOpen,
    title,
    btnText,
    className,
    beneficiaries,
    onClickBenefit,
    benefit,
    setBenefit,
  } = props

  function closeModal(params) {
    setIsOpen(false)
  }

  function nextAction(data) {
    setBenefit(data)
  }

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={null}
      onRequestClose={closeModal}
      className="modal-custom"
      overlayClassName="modal-custom-bg"
      contentLabel="ModalReported"
      ariaHideApp={false}>
      <button className="btn-circle-close" onClick={closeModal}>
        <i className="icon-close"></i>
      </button>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <p className={`txt-3--light-db text-center mb-30 ${className}`}>
          {title}
        </p>
      </div>
      {beneficiaries.filter((c) => c?.relationship?.toUpperCase() === 'TITULAR')
        .length > 0 && (
        <>
          <p className={`txt-3--bold-db pb-2 ${className}`}>Titular</p>

          <div
            className="d-flex align-items-center py-1"
            onClick={() =>
              nextAction({
                ...beneficiaries.filter(
                  (c) => c.relationship?.toUpperCase() === 'TITULAR',
                )[0],
                holder_identity: beneficiaries.filter(
                  (c) => c.relationship?.toUpperCase() === 'TITULAR',
                )[0].identity_titular,
              })
            }>
            <label className="custom-checkbox">
              <input
                type="checkbox"
                checked={
                  beneficiaries.filter(
                    (c) => c.relationship?.toUpperCase() === 'TITULAR',
                  )[0]?.identity === benefit?.identity
                }
                disabled={true}
              />
              <span className="checkmark">
                <i className="icon-checkmark"></i>
              </span>
            </label>
            <ButtonFalseInput
              text={
                beneficiaries.filter(
                  (c) => c.relationship?.toUpperCase() === 'TITULAR',
                )[0].name
              }
            />
          </div>
        </>
      )}
      {beneficiaries.filter((c) => c.relationship?.toUpperCase() !== 'TITULAR')
        .length > 0 && (
        <>
          <p className={`txt-3--bold-db pb-2 ${className}`}>Beneficiarios</p>
          {beneficiaries
            .filter((c) => c.relationship?.toUpperCase() !== 'TITULAR')
            .map((c, i) => (
              <div
                key={i}
                className="d-flex align-items-center py-1"
                onClick={() =>
                  nextAction({
                    ...c,
                    holder_identity: c.identity_titular,
                  })
                }>
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    checked={c.identity === benefit?.identity}
                    disabled={true}
                  />
                  <span className="checkmark">
                    <i className="icon-checkmark"></i>
                  </span>
                </label>
                <ButtonFalseInput key={i} text={c.name} />
              </div>
            ))}
        </>
      )}
      <div className="d-flex justify-content-center align-items-center flex-column pt-4">
        <ButtonYapp
          className="btn-default--small btn-next"
          disabled={[undefined, null, ''].includes(benefit)}
          onClick={() => onClickBenefit(benefit)}
          title={btnText}
        />
      </div>
    </Modal>
  )
}

export default ModalBeneficiaries
