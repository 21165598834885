import {useEffect, useState, useRef, createRef, useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {
  Button,
  Col,
  Accordion,
  Card,
  AccordionContext,
  useAccordionToggle,
} from 'react-bootstrap'
import Modal from 'react-modal'

import LocationAutocomplete from '../../Components/LocationAutocomplete'
import ProductSearch from '../../Components/ProductSearch'
import StepperLocal from '../../Components/Stepper'
import ModalWelcome from '../../Components/Modals/ModalWelcome'

import icSuccess from '../../assets/img/i_success.svg'
import productImg from '../../assets/img/prescription.png'
import icPillDoctor from '../../assets/img/i_pill_doctor_yay.svg'
import icErrorHash from '../../assets/img/error_hash.svg'
import icErrorPay from '../../assets/img/error_pay.svg'
import icErrorProducts from '../../assets/img/error_products.svg'
import icErrorServer from '../../assets/img/error_server.svg'
import icErrorServerTwo from '../../assets/img/error_server_two.svg'
import icInteraccionesMed from '../../assets/img/interacciones_medicamentosa.svg'

import TrackStep from '../../Components/TrackStep/TrackStep'

import '../../styles/themes/patients.css'
import './design.css'
import PharmacyCard from '../../Components/PharmacyCard/PharmacyCard'
import ButtonYapp from '../../Components/Buttons/ButtonYapp'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '85vw',
    zIndex: 1050,
  },
}

function Design(props) {
  const history = useHistory()
  const [isDesktopWidth, setIsDesktopWidth] = useState(null)
  const [locationInput, setLocationInput] = useState(null)
  const [valueColorTheme, setValueColorTheme] = useState('#6578FF')

  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalIsOpen2, setIsOpen2] = useState(false)
  const [modalIsOpen3, setIsOpen3] = useState(false)
  const [modalErrorPayIsOpen, setModalErrorPayIsOpen] = useState(false)
  const [modalErrorProductIsOpen, setModalErrorProductIsOpen] = useState(false)
  const [modalErrorServerIsOpen, setModalErrorServerIsOpen] = useState(false)
  const [modalErrorServerTwoIsOpen, setModalErrorServerTwoIsOpen] =
    useState(false)

  const [modalWelcomeIsOpen, setWelcomeIsOpen] = useState(false)

  function openModal() {
    setIsOpen(true)
  }
  function openModal2() {
    setIsOpen2(true)
  }
  function openModal3() {
    setIsOpen3(true)
  }
  function closeModal() {
    setIsOpen(false)
  }
  function closeModal2() {
    setIsOpen2(false)
  }
  function closeModal3() {
    setIsOpen3(false)
  }
  function closeModalErrorPay() {
    setModalErrorPayIsOpen(false)
  }
  function closeModalErrorProduct() {
    setModalErrorProductIsOpen(false)
  }
  function closeModalErrorServer() {
    setModalErrorServerIsOpen(false)
  }
  function closeModalErrorServerTwo() {
    setModalErrorServerTwoIsOpen(false)
  }

  function closeModalWelcomeHome() {
    setWelcomeIsOpen(false)
  }

  function onFocus() {}
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }
  function afterOpenModal3() {
    // references are now sync'd and can be accessed.
  }

  function openModalWelcome(props) {}

  const MessagesWelcome = [
    {
      title: '¡Bienvenido a nuestra comunidad Yapp!',
      message:
        'Queremos ser una ayuda para ti en Chile. Aquí encontrarás información que te ayudará en tu práctica.',
      icon: icPillDoctor,
    },
    {
      title: '¡Bienvenido a Yapp para médicos!',
      message:
        'Aquí podrás encontrar información relevante para ti y tus pacientes',
      icon: icPillDoctor,
    },
    {
      title: '¡Queremos ser una ayuda para ti y tus pacientes!',
      message:
        'Aquí podrás encontrar los mejores precios de medicamentos, programas de pacientes, interacciones medicamentosas y mucho más',
      icon: icPillDoctor,
    },
    {
      title: '¡Bienvenido a Yapp para médicos!',
      message:
        'Aquí podrás encontrar información relevante para ti y tus pacientes.',
      icon: icPillDoctor,
    },
    {
      title: '¡Bienvenido a Yapp para médicos!',
      message:
        'Sabemos que ha sido un año dificil para ustedes, por eso quisimos desarrollar esta web y facilitar la búsqueda de información para los médicos de Chile',
      icon: icPillDoctor,
    },
    {
      title: '¡Gracias por ser parte de nuestra comunidad Yapp!',
      message:
        'Queremos ser una ayuda en tus estudios, facilitarte la búsqueda de información y ahorrarte tiempo al hacerlo',
      icon: icPillDoctor,
    },
  ]

  const onClickSearch = (newValue) => {
    if (newValue?.value) {
      let path =
        '/search/shopping_bag/' +
        Math.floor(Math.random() * Math.floor(100000000000000000000))
      history.replace(path, {product: newValue, from: 'shopping_bag'})
    } else {
      console.log('Error value')
    }
  }

  const trackStep = [
    {text: 'Pedido pagado', icon: 'icon-store-check', status: 'active'},
    {
      text: 'Confirmado por farmacia',
      icon: 'icon-pharmacy-check',
      status: 'active',
    },
    {text: 'Pedido en camino', icon: 'icon-delivery', status: ''},
    {text: 'Pedido entregado', icon: 'icon-box-check', status: ''},
  ]

  const stepNumberActive = trackStep.filter((item) => {
    let active = 'active'
    return item.status.toLocaleLowerCase().indexOf(active) > -1
  })

  function ContextAwareToggle({children, eventKey, callback, subtotal}) {
    const currentEventKey = useContext(AccordionContext)
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    )
    const isCurrentEventKey = currentEventKey === eventKey

    return (
      <Accordion.Toggle
        onClick={() => decoratedOnClick()}
        as={Col}
        variant="link">
        <div className="d-flex">
          <div className="d-flex align-items-center w-100">
            <label className="custom-radio">
              <input type="radio" name="dinamicRadio" />
              <span className="checkmark"></span>
            </label>
            <p className="txt-paragraph--bold">Tu carrito</p>
          </div>
          <div className="d-flex align-items-center">
            <span className="txt-paragraph--bold  mr-2">$14.134</span>
            <i
              className={`icon-chevron-down color-lilac ${
                isCurrentEventKey ? 'icon-up' : ''
              }`}></i>
          </div>
        </div>
      </Accordion.Toggle>
    )
  }

  function changeThemeColor(props) {
    setValueColorTheme(props?.target.value)
    const root = document.documentElement
    root.style.setProperty('--theme-color', props?.target.value)
  }

  return (
    <div className="Design wrapper-location mt-2">
      <div className="content-theme-color">
        <input
          type="color"
          name="Theme"
          id="theme-color"
          value={valueColorTheme}
          onChange={(e) => changeThemeColor(e)}
        />
      </div>
      {/* Icons */}
      <div className="py-4 icons-files">
        <i className="icon-search"></i>
        <i className="icon-check"></i>
        <i className="icon-send"></i>
        <i className="icon-phone"></i>
        <i className="icon-calendar"></i>
        <i className="icon-arrow-back"></i>
        <i className="icon-delete"></i>
        <i className="icon-coments"></i>
        <i className="icon-address"></i>
        <i className="icon-chevron-right"></i>
        <i className="icon-chevron-down"></i>
      </div>
      {/* selects */}
      <div className="select-custom mb-3">
        <select
          style={localStyle.customSelect}
          className={props.class}
          name={props.name}
          id={props.id}>
          <option value={props.value}>Más económica</option>
        </select>
      </div>
      {/* Breadcrumbs */}
      <div className="breadcrumbs mb-3">
        <a href={props.back} className="d-flex align-items-center">
          <i className="icon-arrow-back"></i>
        </a>{' '}
        <span>Resumen de tu pedido</span>
      </div>
      {/* Modals */}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="Modal1"
        ariaHideApp={false}>
        <h4 className="title-modal mt-3">
          ¿Deseas eliminar este producto de tu carrito?
        </h4>
        <div className="d-flex flex-column justify-content-center mb-5">
          <img
            src={productImg}
            alt="titulo del producto"
            className="modalt-img"
          />
          <p className="modal-text-product text-center">
            Valax D 160 Mg x 35 (/12.)
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Button
            className="btn-default--small btn-next mb-3"
            variant="primary"
            onClick={() => {}}>
            Eliminar
          </Button>
          <Button
            className="btn-default--small btn-next btn-stroke"
            variant="primary"
            onClick={closeModal}>
            Dejar en el carrito
          </Button>
        </div>
      </Modal>
      <Button
        className="btn-default--small mx-auto btn-next mb-3"
        variant="primary"
        onClick={openModal}>
        Open Modal 1
      </Button>
      <Modal
        isOpen={modalIsOpen2}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal2}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="Modal2"
        ariaHideApp={false}>
        <h4 className="title-modal-altern mb-3">Lo sentimos...</h4>
        <div className="d-flex flex-column justify-content-center mb-3">
          <p className="txt-paragraph text-center">
            La farmacia que seleccionaste no tiene despacho a la nueva
            dirección.
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Button
            className="btn-default--small btn-next mb-3"
            variant="primary"
            onClick={() => {}}>
            Buscar farmacias nuevamente
          </Button>
          <Button
            className="btn-default--small btn-next btn-stroke"
            variant="primary"
            onClick={closeModal2}>
            Usar la dirección anterior
          </Button>
        </div>
      </Modal>
      <Button
        className="btn-default--small btn-next m-auto"
        variant="primary"
        onClick={openModal2}>
        Open Modal 2
      </Button>

      <Modal
        isOpen={modalIsOpen3}
        onAfterOpen={afterOpenModal3}
        onRequestClose={closeModal3}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="Modal3"
        ariaHideApp={false}>
        <h4 className="title-primary--regular mb-3">
          Selecciona los productos para los que aplica esta receta:
        </h4>

        <div className="d-flex align-items-center py-1">
          <label className="custom-checkbox">
            <input type="checkbox" />
            <span className="checkmark">
              <i className="icon-checkmark"></i>
            </span>
          </label>
          <div className="custom-checkbox-img">
            <img
              src={productImg}
              height="30"
              className="img-check"
              alt="products"
            />
          </div>
          <div className="w-50 ml-2">
            <p className="txt-paragraph--small">Glafornil 1000 mg x 30</p>
          </div>
        </div>
        <div className="d-flex align-items-center py-1">
          <label className="custom-checkbox">
            <input type="checkbox" />
            <span className="checkmark">
              <i className="icon-checkmark"></i>
            </span>
          </label>
          <div className="custom-checkbox-img">
            <img
              src={productImg}
              height="30"
              className="img-check"
              alt="products"
            />
          </div>
          <div className="w-50 ml-2">
            <p className="txt-paragraph--small">Glafornil 1000 mg x 30</p>
          </div>
        </div>

        <div className="d-flex justify-content-center align-items-center flex-column mt-3">
          <Button
            className="btn-default--small btn-next"
            variant="primary"
            onClick={closeModal3}>
            GUARDAR
          </Button>
        </div>
      </Modal>
      <Button
        className="btn-default--small btn-next mx-auto mt-3"
        variant="primary"
        onClick={openModal3}>
        Open Checkbox modal
      </Button>

      {/* Yapp Buttons */}
      <ButtonYapp
        className="my-3 "
        disabled={false}
        title={'Ver todos los productos disponibles'}
      />

      <ButtonYapp
        className="my-3"
        disabled={false}
        stroke
        title={'Ver todos los productos disponibles'}
      />

      <ButtonYapp
        className="my-3"
        disabled={false}
        icon={'icon-share'}
        color={'--color-green'}
        title={'Ver todos los productos disponibles'}
      />

      <ButtonYapp
        className="my-3"
        disabled={false}
        icon={'icon-share'}
        color={'--color-green'}
        stroke
        title={'Ver todos los productos disponibles'}
      />

      <hr className="separation-blue" />
      {/* Cambiar direccion */}

      <div className="d-flex justify-content-between py-3">
        <div className="breadcrumbs">
          <a href={props.back} className="d-flex align-items-center">
            <i className="icon-arrow-back"></i>
          </a>{' '}
          <span>Cambiar dirección</span>
        </div>
      </div>
      <div className="mb-4">
        <h5 className="txt-paragraph">Nueva dirección</h5>
        <div className="content-location mb-3">
          <LocationAutocomplete onFocus={onFocus} onBlur={onFocus} />
        </div>
        <div className="content-card-info pt-2">
          <h4 className="txt-paragraph mb-2">Direcciones guardadas</h4>
          {[1, 2, 3].map((location, key) => (
            <div key={key} className="card-info">
              <div className="d-flex flex-column justify-content-center">
                <span className="txt-sufix">Mi casa</span>
                <p className="txt-paragraph">
                  Alonso de Córdova 1234, Las Condes
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="content-card-info pt-2">
          <div className="card-info">
            <i className="icon-coments icon-x2 mr-2"></i>
            <input
              placeholder="Agregar comentarios"
              type="text"
              className="card-info-input"
            />
          </div>
        </div>
        <div className="d-flex w-100 justify-content-center pt-2">
          <Button
            className="btn-default--small btn-next m-auto"
            variant="primary"
            onClick={() => {}}>
            Guardar
          </Button>
        </div>
      </div>

      <hr className="separation-blue" />
      {/* Pedido exitoso */}
      <div className="mb-4">
        <div className="card-messege my-5">
          <h4 className="title-big color-green text-center">
            ¡Hemos procesado tu pedido con éxito!
          </h4>
          <img src={icSuccess} alt="Success" className="mb-4" />
          <p className="txt-paragraph">
            Te hemos enviado un correo para que le hagas seguimiento a tu
            pedido.
          </p>
        </div>
        <div className="d-flex justify-content-center  mb-4">
          <p className="title-primary--regular text-center maxw-250">
            ¿Te gustaría recibir siempre tus medicamentos en casa? ¡Descarga
            Yapp en tu celular ahora!
          </p>
        </div>
        <div className="d-flex w-100 justify-content-center pt-2">
          <Button
            className="btn-default--small btn-next m-auto"
            variant="primary"
            onClick={() => {}}>
            Descarga Yapp
          </Button>
        </div>
      </div>

      <hr className="separation-blue" />
      {/* Informacion de producto */}
      <div className="d-flex justify-content-between py-3">
        <div className="breadcrumbs">
          <a href={props.back} className="d-flex align-items-center">
            <i className="icon-arrow-back"></i>
          </a>{' '}
          <span>Información del producto</span>
        </div>
      </div>
      <div className="mb-4">
        <div className="content-card-info pt-2">
          <div className="card-info flex-wrap">
            <h4 className="title-primary w-100">Paracetamol</h4>
            <div className="d-flex ">
              <img src={productImg} alt="" height="45" className="" />
              <div className="ml-3">
                <p className="txt-paragraph mb-1">Nombre</p>
                <p className="txt-paragraph mb-1">Formula</p>
                <p className="txt-paragraph mb-1">Laboratorio</p>
              </div>
            </div>
          </div>
        </div>
        <div className="content-card-info  pt-2">
          <h4 className="title-primary">Vademecum</h4>
          <div className="toggle-default mb-4">
            {[1, 2, 3, 4, 5].map((item, key) => (
              <Accordion key={key}>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Col}
                      className=""
                      variant="link"
                      eventKey="0">
                      <div className="d-flex">
                        <div className="d-flex align-items-center w-100">
                          <p className="txt-paragraph">Indicaciones</p>
                        </div>
                        <div className="d-flex align-items-center">
                          <i className="icon-chevron-down color-green"></i>
                        </div>
                      </div>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div className="d-flex justify-content-between mb-1">
                        <p className="txt-paragraph mb-1">Lorem ipsum dolot</p>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
      <hr className="separation-blue" />
      {/* Recuperacion */}
      <div>
        <div className="d-flex justify-content-center">
          <img src={icPillDoctor} alt="Pill Doctr" className="pill-doctor" />
        </div>
        <div className="mb-4">
          <h4 className="title-primary text-center mb-20">
            ¡Te deseamos una pronta recuperación!
          </h4>
          <p className="txt-paragraph  line-16 text-center">
            También queremos ayudarte a obtener tus
            <br className="d-md-none" /> medicamentos{' '}
            <strong>al mejor precio</strong>. Por favor ingresa un{' '}
            <br className="d-md-none" />
            correo para comenzar la cotización.
          </p>
        </div>
        <div className="justify-content-center">
          <form className="d-flex flex-column justify-content-center maxw-250 mx-auto">
            <input
              type="email"
              className="input-custom mb-20"
              placeholder="Tu correo"
            />
            <Button
              disabled
              className="btn-default btn-next w-100 mb-4"
              variant="primary"
              onClick={() => {}}>
              Ir a cotizar medicamentos
            </Button>
          </form>
        </div>
      </div>
      <hr className="separation-blue" />
      {/* Editar */}
      <div>
        <ProductSearch onClickSearch={onClickSearch} />
        <div className="mt-3">
          {[1, 2].map(() => (
            <div className="content-edit-products mb-5">
              <div className="content-product mr-40">
                <img
                  src={productImg}
                  alt="Product Yapp"
                  className="product-img mb-2"
                />
                <p className="txt-paragraph--small">Valax D 160Mg x 35 (/12)</p>
              </div>
              <div className=" mr-40">
                <StepperLocal />
              </div>
              <div className="">
                <a className="btn-delete">
                  <i className="icon-delete"></i>
                </a>
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-center">
          <Button
            className="btn-default--small btn-next mb-3"
            variant="primary"
            onClick={() => {}}>
            Guardar
          </Button>
        </div>
      </div>
      <hr className="separation-blue" />
      {/* Track de producto */}
      <h2 className="title-primary--xxl text-center mb-2">¡Sigue tu pedido!</h2>
      <div>
        <TrackStep active={stepNumberActive.length} data={trackStep} />
      </div>
      <div className="">
        <div className="content-order ">
          <div className="mr-3 content-order-icon">
            <i className="icon-wallet"></i>
          </div>
          <div>
            <h4 className="txt-paragraph txt-bold">Orden Nº2868</h4>
            <h4 className="txt-paragraph--light ">
              Comprado el 19 de abril 2021
            </h4>
            <h4 className="txt-paragraph--light ">2 productos comprados</h4>
            <h4 className="txt-paragraph--light  ">
              Total pagado <span className="">$13.970</span>
            </h4>
          </div>
        </div>
        <div className="content-order ">
          <div className="mr-3 content-order-icon">
            <i className="icon-home-check"></i>
          </div>
          <div>
            <h4 className="txt-paragraph txt-bold">Dirección de envío</h4>
            <h4 className="txt-paragraph--light ">
              Roquedal 7524, Puente Alto
            </h4>
            <h4 className="txt-paragraph--light ">Teléfono: 999999999</h4>
          </div>
        </div>
      </div>
      <div>
        <button
          id="gtm-webapp-track-support"
          className="btn-default--small btn-stroke btn-more-info mt-5 mx-auto"
          onClick={() => null}>
          Contactar soporte
        </button>
      </div>
      <hr className="separation-blue" />
      {/* Error Hash */}
      <div className="d-flex justify-content-between py-3">
        <div className="breadcrumbs">
          <a href={props.back} className="d-flex align-items-center">
            <i className="icon-arrow-back color-lilac"></i>
          </a>
        </div>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center mb-5">
        <img src={icErrorHash} alt="Error" className="mb-35" />
        <h4 className="title-primary--3xl-bold">Lo sentimos</h4>
        <p className="title-primary--xl mb-30">
          No puedes acceder a este sitio.
        </p>
        <button
          id="gtm-webapp-track-support"
          className="btn-default--small btn-next mx-auto"
          onClick={() => null}>
          Volver atrás
        </button>
      </div>
      <hr className="separation-blue" />
      {/* Error Pay */}
      <Modal
        isOpen={modalErrorPayIsOpen}
        onAfterOpen={null}
        onRequestClose={closeModalErrorPay}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="Modal1"
        ariaHideApp={false}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img src={icErrorPay} alt="Error" className="mb-3" />
          <p className="title-primary--xl text-center mb-30">
            Ha ocurrido un error con tu
            <br /> medio de pago.
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Button
            className="btn-default--small btn-next mb-3"
            variant="primary"
            onClick={() => setModalErrorPayIsOpen(false)}>
            Volver a intentar
          </Button>
        </div>
      </Modal>
      <button
        className="btn-default--small mx-auto btn-next mb-3"
        onClick={() => setModalErrorPayIsOpen(true)}>
        Open Modal Error pay
      </button>
      {/* Error Products */}
      <Modal
        isOpen={modalErrorProductIsOpen}
        onAfterOpen={null}
        onRequestClose={closeModalErrorProduct}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="Modal1"
        ariaHideApp={false}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img src={icErrorProducts} alt="Error" className="mb-3" />
          <h4 className="title-primary--3xl-bold">Lo sentimos</h4>
          <p className="title-primary--xl text-center mb-30">
            No hemos encontrado resultados para tu búsqueda.
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Button
            className="btn-default--small btn-next mb-3"
            variant="primary"
            onClick={() => setModalErrorProductIsOpen(false)}>
            Volver atrás
          </Button>
        </div>
      </Modal>
      <button
        className="btn-default--small mx-auto btn-next mb-3"
        onClick={() => setModalErrorProductIsOpen(true)}>
        Open Modal Error Products
      </button>
      {/* Error Server */}
      <Modal
        isOpen={modalErrorServerIsOpen}
        onAfterOpen={null}
        onRequestClose={closeModalErrorServer}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="Modal1"
        ariaHideApp={false}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img src={icErrorServer} alt="Error" className="mb-3" />
          <h4 className="title-primary--3xl-bold">Lo sentimos</h4>
          <p className="title-primary--xl text-center mb-30">
            No podemos conetar con los servidores de Yapp en este momento...
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Button
            className="btn-default--small btn-next mb-3"
            variant="primary"
            onClick={() => setModalErrorServerIsOpen(false)}>
            Volver a intentar
          </Button>
        </div>
      </Modal>
      <button
        className="btn-default--small mx-auto btn-next mb-3"
        onClick={() => setModalErrorServerIsOpen(true)}>
        Open Modal Error Server
      </button>
      {/* Error Server  Two*/}
      <Modal
        isOpen={modalErrorServerTwoIsOpen}
        onAfterOpen={null}
        onRequestClose={closeModalErrorServerTwo}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="Modal1"
        ariaHideApp={false}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img src={icErrorServerTwo} alt="Error" className="mb-3" />
          <h4 className="title-primary--3xl-bold">Lo sentimos</h4>
          <p className="title-primary--xl text-center mb-30">
            No podemos conetar con los servidores de Yapp en este momento...
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Button
            className="btn-default--small btn-next mb-3"
            variant="primary"
            onClick={() => setModalErrorServerTwoIsOpen(false)}>
            Volver a intentar
          </Button>
        </div>
      </Modal>
      <button
        className="btn-default--small mx-auto btn-next mb-3"
        onClick={() => setModalErrorServerTwoIsOpen(true)}>
        Open Modal Error Server Two
      </button>
      <hr className="separation-blue" />
      {/* Mensaje Marketing */}
      <ModalWelcome
        modalWelcomeIsOpen={modalWelcomeIsOpen}
        setWelcomeIsOpen={setWelcomeIsOpen}
        title={MessagesWelcome[2].title}
        message={MessagesWelcome[2].message}
      />

      <button
        className="btn-default--small mx-auto btn-next mb-3"
        onClick={() => setWelcomeIsOpen(true)}>
        Open Modal Bienvenda Home
      </button>
      {/* Informacion de producto */}
      <div className="d-flex justify-content-between align-items-center pt-3">
        <div className="breadcrumbs">
          <a href={props.back} className="d-flex align-items-center">
            <i className="icon-chevron-right icon-reverse"></i>
          </a>{' '}
          <span className="title-primary--regular">Volver</span>
        </div>
        <div className="">
          <a
            className="link-shared d-flex align-items-center"
            onChange={() => null}>
            <i className="icon-share"></i>
          </a>
        </div>
      </div>
      <div className="">
        <div className="content-card-info m-0 pt-2">
          <div className="card-info flex-wrap">
            <h4 className="title-primary w-100">Paracetamol</h4>
            <div className="d-flex ">
              <img src={productImg} alt="" height="45" className="" />
              <div className="ml-3">
                <p className="txt-paragraph mb-1">Nombre</p>
                <p className="txt-paragraph mb-1">Formula</p>
                <p className="txt-paragraph mb-1">Laboratorio</p>
              </div>
            </div>
          </div>
        </div>
        <div className="content-card-info-pro">
          <div className="toggle-default mb-4">
            {[1, 2, 3, 4, 5].map((item, key) => (
              <Accordion>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Col}
                      className=""
                      variant="link"
                      eventKey="0">
                      <div className="d-flex">
                        <div className="d-flex align-items-center w-100">
                          <p className="txt-paragraph">Indicaciones</p>
                        </div>
                        <div className="d-flex align-items-center">
                          <i className="icon-chevron-down color-lilac"></i>
                        </div>
                      </div>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div className="d-flex justify-content-between mb-1">
                        <p className="txt-paragraph mb-1">Lorem ipsum dolot</p>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            ))}
          </div>

          <hr className="separation-blue" />
          {/* card pharmacy */}
          <div className="patients"></div>
          <div className="patients"></div>
        </div>
      </div>
      {/* Boton interacciones medicamentosa */}
      <div className="d-flex justify-content-between align-items-center btn-interaciones c-pointer">
        <div className="d-flex align-items-center ">
          <img src={icInteraccionesMed} alt="Interaciones medicamentosa" />
          <div className="ml-2">
            <h2 className="title-primary mb-1 line-16">
              Interacciones medicamentosas
            </h2>
            <p className="txt-paragraph--small m-0">
              Valida las interacciones antes de recetar
            </p>
          </div>
        </div>
        <div className="btn-interaciones-next d-flex align-items-center">
          <i className="icon-chevron-right"></i>
        </div>
      </div>
    </div>
  )
}

const localStyle = {}

export default Design
