import {connect} from 'react-redux'
import {useState, useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {hideSideBar} from '../Utils/constants'
import {useSelector} from 'react-redux'

import powerByNew from '../assets/img/power_by_header_new.svg'
import logoYapp from '../assets/img/logo_yapp.svg'
import IcAdmin from '../assets/img/avatar_admin.svg'

import Sidenav from './Sidenav/Sidenav'
import ModalAccountAdmin from './Modals/ModalAccountAdmin'
import {signOut} from '../Utils/auth'
import {clearUserState} from '../redux/modules/user'
import {clearAppState} from '../redux/modules/app'
import {logEvent} from '../Utils/utils'
import HeaderUCLegacy from './Theme/HeaderUC'

function Header(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isOpenSidenav, setIsOpenSidenav] = useState(false)
  const [pathHide, setPathHide] = useState(false)
  const [menuHide, setMenuHide] = useState(false)
  const [imgProfile, setImgProfile] = useState(IcAdmin)
  const [modalAccount, setModalAccount] = useState(false)
  const userInfo = useSelector((state) => state.user.userInfo)
  const location = useLocation()
  const isPro = useSelector((state) => state.app.isPro)
  const path = location.pathname

  const sideNavData = [
    {
      id: 'gtm-webapp-sidenav-medication-sheet',
      title: 'Ficha de medicamento',
      description: 'Revisa toda la información que necesitas saber.',
      page: '/pro/medication-sheet',
      isNew: false,
      isCommin: false,
    },
    {
      id: 'gtm-webapp-sidenav-price-finder',
      title: 'Buscador de precios',
      description: 'Busca rápidamente el costo de los medicamentos.',
      page: '/pro/price_finder',
      isNew: true,
      isCommin: false,
    },
    {
      id: 'gtm-webapp-sidenav-quotation-drugs',
      title: 'Comprar medicamentos',
      description: 'Comprar al mejor precio, siempre.',
      page: '/search',
      isNew: false,
      isCommin: false,
    },
    {
      id: 'gtm-webapp-sidenav-iteraction',
      title: 'Interacciones medicamentosas',
      description: 'Valida las interacciones antes de recetar.',
      page: '/pro/drug_interactions',
      isNew: false,
      isCommin: false,
    },
    {
      id: 'gtm-webapp-sidenav-new-drugs',
      title: 'Nuevos lanzamientos',
      description: 'Descubre los lanzamientos de medicamentos.',
      page: '/pro/new_drugs',
      isNew: false,
      isCommin: false,
    },
    {
      id: 'gtm-webapp-sidenav-lab-coontact',
      title: 'Contactar laboratorios',
      description: 'Habla directamente con los laboratorios. ',
      page: '/pro/laboratory',
      isNew: false,
      isCommin: false,
    },
    {
      id: 'gtm-webapp-sidenav-programs',
      title: 'Información de programas',
      description: 'Infórmate sobre programas de pacientes y de acceso.',
      page: '/pro/programs',
      isSoon: false,
    },
  ]

  useEffect(async () => {
    if (['/no-access', '/error'].includes(path)) {
      setMenuHide(true)
    } else {
      setMenuHide(false)
      setPathHide(checkUrl(path, hideSideBar))
      // setAvatarHide(checkUrl(path, hideAccountAvatar))
    }
  }, [path])

  function openSidenav() {
    setIsOpenSidenav(true)
  }

  function redirectUrl() {
    // try {
    //   if (props?.config?.host) {
    //     let urlObj = new URL(props?.config?.host)
    //     let url = process.env.REACT_APP_ENV_HOST === 'LOCAL'
    //       ? props?.config?.host.replace(urlObj.origin, 'https://localhost:3001') :
    //       props?.config?.host
    //     window.location.replace(url)
    //   }
    // }
    // catch (ignore) {
    // }
  }

  const checkUrl = (path, hideElement) => {
    for (let index = 0; index < hideElement.length; index++) {
      if (path.includes(hideElement[index])) return false
    }
    return true
  }

  function onSignOut() {
    signOut().then()
    dispatch(clearUserState())
    dispatch(clearAppState())
    setModalAccount(false)
    logEvent('Logged Out')
    history.replace('/')
  }

  function goTo(path) {
    setModalAccount(false)
    history.push(path)
  }

  return process.env.REACT_APP_CLIENT_NAME === 'UC Christus' ? (
    <>
      <HeaderUCLegacy />
    </>
  ) : (
    <>
      <header
        className={`header-wrapper d-flex  ${
          (props.className,
          isPro && pathHide ? 'header-wrapper-pro position-relative' : '')
        }`}>
        <div className="content-header-new d-flex align-items-center position-relative">
          {isPro && props.showAvatar && (
            <div className="sidenav-menu px-md-0" onClick={openSidenav}>
              <i className="icon-menu-hamb"></i>
            </div>
          )}

          {props.showAvatar &&
            !['anonymously', undefined, null].includes(
              userInfo?.signInMethod,
            ) && (
              <div
                id={`gtm-webapp-header-avatar-btn`}
                className="content-img-avatar c-pointer"
                onClick={() => setModalAccount(true)}>
                <img
                  id={`gtm-webapp-header-avatar-img`}
                  src={imgProfile}
                  alt="Yapp"
                  className="img-header-avatar-acount"
                />
              </div>
            )}
          <div
            className={`header-content ${
              isPro || !props.showLogo ? 'logo-center' : ''
            }`}>
            {!(isPro && pathHide) && (
              <div>
                {props.showLogo && (
                  <img
                    src={props.logo}
                    onClick={redirectUrl}
                    className="app-logo-altern"
                    alt="logo"
                  />
                )}
              </div>
            )}
            {!(isPro && pathHide) && (
              <div
                className={`powerby ${
                  props.showAvatar &&
                  !['anonymously', undefined, null].includes(
                    userInfo?.signInMethod,
                  )
                    ? 'mr-5 mr-lg-4 ml-1'
                    : ''
                }`}>
                <img
                  src={!isPro && props.showLogo ? powerByNew : logoYapp}
                  alt="Yapp"
                  onClick={redirectUrl}
                  className="logo-powerby"
                />
              </div>
            )}
          </div>
        </div>
      </header>
      {isPro && props.showAvatar && (
        <Sidenav
          menuData={sideNavData}
          isOpen={isOpenSidenav}
          setIsOpenSidenav={setIsOpenSidenav}
        />
      )}
      <ModalAccountAdmin
        onSignOut={onSignOut}
        userInfo={userInfo}
        modalAccount={modalAccount}
        setModalAccount={setModalAccount}
        goTo={() => goTo('/change_password')}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    logo: state.app?.logo,
    showLogo: state.app?.show_logo,
    showAvatar: state.app?.show_avatar,
    config: state.app?.config,
  }
}

export default connect(mapStateToProps)(Header)
