import './BenefitsCards.css'

function BenefitCards({ data }) {
  return (
    <>
      <div class="benefit-card">
        <div class="titles">
          <h2>{data.name}</h2>
          <p>{data.activePrinciple}</p>
          <p>{data.laboratory}</p>
        </div>
        <div class="image">
          <img
            src={data.image ? data.image : "/images/placeholder.png"}
            alt={`imagen de ${data.name}`}
          />
        </div>
        <div class="discount">
          <p>Hasta</p>
          <div class="discount-info">
            <div class="number">{data.discount}</div>
            <div class="percentage">
              <p>%</p>
              <p>Ahorro</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BenefitCards