import {connect, useDispatch} from 'react-redux'
import {useEffect, useRef, useState} from 'react'
import AsyncSelect from 'react-select/async'
import {autocomplete} from '../../Utils/api'
import {_formatPrice} from '../../Utils/tools'

import {changeLogo} from '../../redux/modules/app'
import {showButtonCart} from '../../redux/modules/cart'

import icSearch from '../../assets/img/ic_search.svg'
import icSearchSecondary from '../../assets/img/ic_search_secondary.svg'
import icEnter from '../../assets/img/ic_enter_search.svg'
import {addEvent, addEventNameWithParams} from '../../Utils/utils'
import {theme} from '../../Utils/constants'
import {setResultSearch as setResultSearchRdx} from '../../redux/modules/search'
import {logEvent} from '../../Utils/utils'

const isPatientProfile = process.env.REACT_APP_PROFILE === 'PATIENT'
const _get = require('lodash/get')
const debounce = require('lodash/debounce')

function Search(props) {
  const dispatch = useDispatch()
  const [resultSearch, setResultSearch] = useState(null)
  const [appState, setAppState] = useState({
    loading: false,
    products: [],
    inputValue: '',
    groupedOptions: [],
  })

  useEffect(() => {
    props.showButtonCart(false)
    if (![undefined, null].includes(props?.product_input)) {
      setAppState({
        ...appState,
        groupedOptions: [{...props?.product_input, value: 1}],
        inputValue: {...props?.product_input, value: 1},
      })
    }
  }, [setAppState])

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  )

  const promiseOptions = async (inputValue, callback) => {
    const parsedInputValue = inputValue.toUpperCase()
    if (inputValue.length > 1) {
      let products = []

      if (isPatientProfile && props.commune?.id) {
        products = await autocomplete(
          parsedInputValue,
          props.vidal,
          props.request_from,
          props.commune.id
        )
      }
      else {
        products = await autocomplete(
          parsedInputValue,
          props.vidal,
          props.request_from
        )
      }

      if (_get(products, 'data')) {
        setResultSearch({
          result_id: _get(products, 'result_id'),
          request_from: props.request_from,
        })

        let group = products.data.map((c) => {
          let price_label = c.minimal_price
            ? '(' + _formatPrice(c.minimal_price) + ') ' + c.product_name
            : c.product_name
          return {
            value: c.product_id,
            label: price_label,
            data: c
          }
        })

        if (props?.onlyProducts === true) {
          group = group.filter((item) => item.data?.is_product === 1)
        }
        let options = []
        options.push({
          label: 'Medicamentos',
          options: group,
        })
        let amplitude = options[0].options
          .map((c, index) => {
            return {
                product_id: c.value,
                lab_name: c.data.laboratory_name,
                position: index + 1,
                municipality_id: props.commune?.id ? props.commune?.id : ''
            }
          })
        logEvent('Product Found', {
          'Product searched': parsedInputValue,
          Results: amplitude.slice(0, 10),
        })
        setAppState({groupedOptions: options})
        callback(options)
      } else {
        logEvent('Product Not Found', {
          'Search Term': parsedInputValue
        })
        setResultSearch(null)
        callback([])
      }
    }
  }

  const localDebounce = debounce(
    (inputValue, callback) => promiseOptions(inputValue, callback),
    200,
  )

  const loadSuggestions = (inputValue, callback) => {
    localDebounce(inputValue, callback)
  }

  const onChangeInput = (newValue) => {
    try {
      if (props?.onClickSearch && newValue)
        if (props.from === 'search') {
          addEventNameWithParams('quotation_select_product', {
            product_id: newValue?.value,
            product_name: newValue?.label,
          })
        }

      if (props.clearInput) {
        setAppState({...appState, groupedOptions: []})
      }

      dispatch(
        setResultSearchRdx({
          ...resultSearch,
          is_active_principle: newValue?.data?.is_product === 0,
          active_principle_id: newValue?.data?.active_principle_id,
        }),
      )

      if (newValue?.data) {
        newValue.data = {...newValue?.data, resultSearch}
      }
      return props.onClickSearch(newValue)
    } catch (ignore) {}
  }

  const searchProductsStyle = {
    control: (provided, {isFocused}) => ({
      ...provided,
      backgroundColor: '#fff',
      borderRadius: '8px',
      height: '38px',
      boxShadow: '2px 2px 10px rgba(0,0,0,.1)',
      paddingLeft: '44px',
      zIndex: 1050,
      border: '1px solid #E9E6FE;',
      '&:hover': {
        border: isFocused ? '1px solid #E9E6FE;' : '1px solid #E9E6FE;',
      },
      ':before': {
        backgroundImage: `url(${icSearch})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: '21px',
        width: '21px',
        position: 'absolute',
        left: '12px',
        overflow: 'visible',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#828282',
      fontSize: '12px',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#BDBDBD',
      fontSize: '12px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: '0',
      fontSize: '12px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '0 0 8px 8px',
      boxShadow: '0 4px 10px rgba(0,0,0,.1)',
      borderWidth: '0',
      top: '23px',
      zIndex: 1051,
      border: '1px solid #E9E6FE',
    }),
    menuList: (provided) => ({
      ...provided,
      color: '#282E55',
      fontSize: '10px',
    }),
    menuPortal: (provided) => ({
      ...provided,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: '#828282',
      fontSize: '12px',
    }),
    option: (provided, {isFocused}) => ({
      ...provided,
      backgroundColor: isFocused ? 'rgba(196, 196, 196, 0.3)' : null,
      ':active': {
        ...provided[':active'],
        backgroundColor: 'rgba(196, 196, 196, 0.3)',
        fontSize: '10px',
      },
      ':before': isFocused
        ? {
            backgroundImage: `url(${icEnter})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '22px',
            backgroundPosition: 'right',
            content: '"Seleccionar"',
            color: '#828282',
            fontSize: '8px',
            display: 'flex',
            flexColumn: 'row',
            alignItems: 'center',
            marginRight: 8,
            height: '15px',
            width: '74px',
            position: 'absolute',
            right: '0',
            overflow: 'visible',
          }
        : {},
    }),
  }

  const borderColor = theme?.border_color
    ? `1px solid ${theme?.border_color}`
    : props.themeColor
    ? `1px solid var(${props.themeColor})`
    : '1px solid #6578FF'

  const searchProductsStyleSecondary = {
    control: (provided, {isFocused}) => ({
      ...provided,
      backgroundColor: '#fff',
      borderRadius: theme.border_radius,
      height: '42px',
      boxShadow: '0 rgba(0,0,0,.1)',
      paddingLeft: '21px',
      zIndex: 1050,
      border: borderColor,
      '&:hover': {
        border: borderColor,
      },
      ':before': {
        backgroundColor: theme?.border_color
          ? theme?.border_color
          : props.themeColor
          ? `var(${props.themeColor})`
          : '#6578FF',
        borderRadius: '30px',
        content: '"\\e901"',
        fontFamily: 'icomoon',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '20px',
        marginRight: 0,
        height: theme.size_icon_search,
        width: theme.size_icon_search,
        position: 'absolute',
        left: 'calc(100% - 40px)',
        overflow: 'visible',
        zIndex: 999,
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#828282',
      fontSize: '12px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '75%',
      whiteSpace: 'nowrap',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#282E55',
      fontSize: '12px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '75%',
      whiteSpace: 'nowrap',
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: '0',
      fontSize: '12px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '0 0 8px 8px',
      boxShadow: '0 4px 10px rgba(0,0,0,.1)',
      borderWidth: '0',
      top: '20px',
      zIndex: 1049,
      border: '1px solid #E9E6FE',
      paddingTop: '20px',
    }),
    menuList: (provided) => ({
      ...provided,
      color: '#282E55',
      fontSize: '12px',
    }),
    menuPortal: (provided) => ({
      ...provided,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: '#828282',
      fontSize: '12px',
    }),
    option: (provided, {isFocused, isSelected}) => ({
      ...provided,
      backgroundColor: isFocused ? 'rgba(196, 196, 196, 0.3)' : null,
      color: isSelected ? 'var(--color-dark-blue-ds)' : '#828282',
      ':active': {
        ...provided[':active'],
        backgroundColor: 'rgba(196, 196, 196, 0.3)',
        fontSize: '10px',
        color: '#828282',
      },
      ':before': isFocused
        ? {
            backgroundImage: `url(${icEnter})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '22px',
            backgroundPosition: 'right',
            content: '"Seleccionar"',
            color: '#828282',
            fontSize: '8px',
            display: 'flex',
            flexColumn: 'row',
            alignItems: 'center',
            marginRight: 8,
            height: '15px',
            width: '74px',
            position: 'absolute',
            right: '0',
            overflow: 'visible',
          }
        : {},
    }),
  }

  function onFocusInput(e) {
    addEvent('product_search_focus')
  }

  const placeholderSearch = props.placeholder
    ? props.placeholder
    : 'Busca tus medicamentos aquí'

  return (
    <div
      className={`${props.resetStyle ? '' : 'py-3 py-md-4'}  ${
        props.className
      }`}>
      {!props.isNotShow && (
        <h5 className="title-primary">Agregar medicamentos</h5>
      )}
      <AsyncSelect
        className="search-product"
        id="gtm-webapp-search-products-doctor"
        isClearable
        cacheOptions
        onFocus={onFocusInput}
        onChange={onChangeInput}
        noOptionsMessage={() => 'Sin resultados...'}
        placeholder={placeholderSearch}
        controlShouldRenderValue={false}
        formatGroupLabel={formatGroupLabel}
        defaultOptions={appState.groupedOptions}
        loadOptions={loadSuggestions}
        styles={
          props.styleSecondary
            ? searchProductsStyleSecondary
            : searchProductsStyle
        }
        value={props.showValue ? null : appState.inputValue}
      />
    </div>
  )
}

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundImage: `url(${icSearch})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 22,
    width: 22,
  },
})

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: '1em',
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    borderRadius: '0.8rem',
    borderColor: 'transparent',
    fontFamily: 'var(--font-light)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  }),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? null
        : null,
      color: '#282e55',
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : null),
      },
    }
  },
  input: (styles) => ({
    ...styles,
    ...dot(),
    fontSize: '1em',
    fontFamily: 'var(--font-light)',
    color: '#282e55',
    height: '3rem',
  }),
  placeholder: (styles) => ({
    ...styles,
    ...dot(),
    fontSize: '1.2em',
    fontFamily: 'var(--font-light)',
    color: '#BDBDBD',
  }),
  singleValue: (styles, {data}) => ({...styles, ...dot(data.color)}),
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeLogo: (logo) => dispatch(changeLogo(logo)),
    showButtonCart: (status) => dispatch(showButtonCart(status)),
  }
}

const mapStateToProps = (state) => {
  return {
    commune: state.app.commune
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
