import React, {useRef, useState, useEffect} from 'react'
import ButtonYapp from '../Buttons/ButtonYapp'

const InputButton = ({
  btnText,
  error,
  onButtonClick,
  onChange,
  discountCode,
  externalLabel,
  id,
  hideButton,
  label,
  placeholder,
  ...props
}) => {
  const inputText = useRef(null)

  return (
    <>
      {externalLabel && (
        <label className="txt-3--bold m-0 mb-2" htmlFor={id}>
          {label}
        </label>
      )}
      <div className="position-relative input-yapp">
        <div className="content-input-bg">
          <input
            type="text"
            ref={inputText}
            className="input-custom-secondary font-regular w-100"
            onChange={(inputText) => onChange(inputText.target.value)}
            value={props.value || ''}
            placeholder={placeholder ? placeholder : ''}
            readOnly={props.disabled}
            {...props}
          />
          {props.discount ? (
            <div className="mw-115 position-absolute input-btn-position d-flex align-items-center justify-content-end flex-fill">
              <i className="icon-circle-check txt-1--bold mr-2 color-theme"></i>
            </div>
          ) : (
            !hideButton && (
              <ButtonYapp
                title={btnText || 'Aplicar'}
                onClick={() => onButtonClick(inputText.current.value)}
                color={'--theme-color'}
                className="z-index-99 mw-115 position-absolute input-btn-position"
                noWrap
                small
              />
            )
          )}
        </div>
        {error && (
          <p className="txt-6--regular-er mt-1 mb-2">{error.message}</p>
        )}
      </div>
    </>
  )
}

export default InputButton
