import firebase from 'firebase/app'
import 'firebase/auth'
import {SET_SIGN_IN} from '../redux/modules/app'
import {maskEmail} from '../Utils/tools'
import {logEvent} from './utils'

const isProfessionalEnv = process.env.REACT_APP_IS_PROFESSIONAL === 'TRUE'

export const authStateChanged = () => {
  return (dispatch) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        dispatch({type: SET_SIGN_IN, signIn: true})
      } else {
        dispatch({type: SET_SIGN_IN, signIn: false})
      }
    })
  }
}

export const getRedirectResult = async () => {
  try {
    let result = await firebase.auth().getRedirectResult()
    if (result.credential) {
      /** @type {firebase.auth.OAuthCredential} */
      var credential = result.credential
      var user = result.user

      if (user) {
        let profile = result?.additionalUserInfo?.profile
        switch (credential?.signInMethod) {
          case 'facebook.com':
          case 'google.com':
          case 'apple.com':
            localStorage.setItem(
              'login_temp_data',
              JSON.stringify({
                signInMethod: credential.signInMethod,
                profile: {
                  birthday: profile?.birthday,
                  first_name: profile?.first_name || profile?.given_name,
                  gender: profile?.gender,
                  last_name: profile?.last_name || profile?.family_name,
                  picture: profile?.picture?.data?.url || profile?.picture,
                  name: profile?.name,
                  email: user?.email,
                  uid: user?.uid,
                },
              }),
            )
            return await validateUser(user.uid, credential?.signInMethod)

          default:
            localStorage.removeItem('login_temp_data')
            break
        }
      }
    } else {
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

export const loginAnonymoust = async () => {
  try {
    const anonymously = await firebase.auth().signInAnonymously()
    let user = {
      birthday: null,
      first_name: null,
      gender: null,
      last_name: null,
      picture: null,
      name: 'Usuario anónimo',
      email: null,
      uid: anonymously?.user?.uid,
    }

    localStorage.setItem(
      'login_temp_data',
      JSON.stringify({
        signInMethod: 'anonymously',
        profile: {
          ...user,
          provider: 'anonymously',
        },
      }),
    )

    return {
      status: 'success',
      data: {...user},
    }
  } catch (error) {
    return {
      status: 'error',
      data: 'cancel',
    }
  }
}

export const loginCustomToken = async (token) => {
  let loginResult = {
    status: 'error',
    data: 'cancel'
  }

  try {
    const custom = await firebase.auth().signInWithCustomToken(token)
    const provider = custom?.user?.providerData?.[0].providerId || 'custom'

    if (![undefined, null].includes(custom?.user?.uid)) {
      let userData = await getUser(custom.user.uid)

      let userLogin = {
        signInMethod: provider,
        profile: {
          uid: custom.user.uid,
          isProfessional: userData?.is_professional,
          name: userData?.name_delivery || custom.user.displayName,
          first_name: userData?.first_name || custom.user.displayName || userData?.name_delivery,
          last_name: userData?.last_name,
          rut: userData?.rut,
          email: custom.user.email,
          birthday: userData?.birthday,
          gender: userData?.gender,
          picture: custom.user.photoURL,
          provider: provider
        }
      }

      localStorage.setItem('login_temp_data', JSON.stringify(userLogin))

      if (isProfessionalEnv || userLogin.profile.is_professional) {
        loginResult.status = 'success'
        loginResult.data = { user: userLogin.profile, provider: provider}
      }
      else if (isProfessionalEnv && !(userLogin.profile.is_professional)) {
        loginResult.status =  'no-professional'
        loginResult.data = {provider}
      }
    }
  } 
  catch (error) {}
  return loginResult
}

export const loginGoogle = async () => {
  try {
    var provider = new firebase.auth.GoogleAuthProvider()
    firebase.auth().languageCode = 'es'
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        return firebase.auth().signInWithRedirect(provider)
      })
  } catch (error) {
    return {
      status: 'error',
      data: 'cancel',
    }
  }
}

export const loginApple = async () => {
  try {
    var provider = new firebase.auth.OAuthProvider('apple.com')
    provider.addScope('email')
    provider.addScope('name')
    provider.setCustomParameters({
      locale: 'es',
    })
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        return firebase.auth().signInWithRedirect(provider)
      })
      .catch((error) => {})
  } catch (error) {
    console.log(error)
    return {
      status: 'error',
      data: 'cancel',
    }
  }
}

export const loginFacebook = () => {
  try {
    var provider = new firebase.auth.FacebookAuthProvider()
    firebase.auth().languageCode = 'es'
    provider.addScope('public_profile')
    provider.addScope('user_gender')
    provider.addScope('user_birthday')

    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        return firebase.auth().signInWithRedirect(provider)
      })
  } catch (error) {
    console.log(error)
    return {
      status: 'error',
      data: 'cancel',
    }
  }
}

export const registerPassword = async (
  email,
  password,
  firstName,
  lastName,
) => {
  try {
    let userCredential = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)

    const user = userCredential.user

    localStorage.setItem(
      'login_temp_data',
      JSON.stringify({
        signInMethod: 'password',
        profile: {
          first_name: firstName,
          last_name: lastName,
          email: user?.email,
          uid: user?.uid,
        },
      }),
    )
    return {
      status: 'success',
      data: null,
    }
  } catch (error) {
    if (error.code === 'auth/email-already-in-use') {
      return {
        status: 'already-account',
        data: null,
      }
    } else {
      return {
        status: 'error',
        data: null,
      }
    }
  }
}

let validateUser = async (uid, provider) => {
  if (![undefined, null].includes(uid)) {
    let user = await getUser(uid)

    if ([undefined, null].includes(user)) {
      return {
        status: 'no-account',
        data: {provider},
      }
    } else {
      let lStorage = localStorage.getItem('login_temp_data')
      if (lStorage) {
        lStorage = JSON.parse(lStorage)
        if (user?.first_name) lStorage.profile.first_name = user?.first_name
        if (user?.last_name) lStorage.profile.last_name = user?.last_name
        localStorage.setItem('login_temp_data', JSON.stringify(lStorage))
      }
      if (isProfessionalEnv) {
        if (user?.is_professional) {
          return {
            status: 'success',
            data: {...user, provider},
          }
        } else {
          return {
            status: 'no-professional',
            data: {provider},
          }
        }
      }
      {
        return {
          status: 'success',
          data: {...user, provider},
        }
      }
    }
  } else {
    return {
      status: 'no-account',
      data: {provider},
    }
  }
}

export const signIn = async (email, password, provider) => {
  let userCredential
  try {
    switch (provider) {
      case 'email':
        userCredential = await firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
        if (userCredential.user !== undefined) {
          let currentUser = await getUser(userCredential?.user?.uid)
          localStorage.setItem(
            'login_temp_data',
            JSON.stringify({
              signInMethod: 'password',
              profile: {
                first_name: currentUser?.first_name,
                last_name: currentUser?.last_name,
                email: userCredential.user?.email,
                uid: userCredential.user?.uid,
              },
            }),
          )
          return validateUser(userCredential.user.uid, 'email')
        } else {
          return {
            status: 'error',
            data: null,
          }
        }
    }
  } catch (e) {
    return {
      status: 'error',
      data: extractMessageError(e),
    }
  }
}

export const extractMessageError = (error) => {
  return error.message.split(']')[0].replace('[', '').replace(']', '')
}

export const _isUserAnonymous = async () => {
  let exist = await _existUser()
  if (exist) {
    return firebase.auth().currentUser.isAnonymous
  } else {
    return false
  }
}

export const _existUser = async () => {
  let currentUser = await firebase.auth().currentUser
  return currentUser !== null
}

export const _getCurrentUser = async () => {
  let currentUser = await firebase.auth().currentUser
  return currentUser
}

export const _getUserId = async () => {
  let exist = await _existUser()
  if (exist) {
    return firebase.auth().currentUser.uid
  } else {
    return null
  }
}
export const getUser = async (uid) => {
  let userData = await firebase.firestore().collection('users').doc(uid).get()

  return userData.data()
}

export const getMaskEmail = async (uid) => {
  try {
    let userData = await firebase.firestore().collection('users').doc(uid).get()
    let data = userData.data()
    if (data !== undefined) {
      data.mask_email = maskEmail(data.email)
    }
    return {
      email: data.mask_email,
      uid: uid,
    }
  } catch (err) {
    return {
      email: null,
      uid: uid,
    }
  }
}

export const getUserInfo = async (uid) => {
  let exist = await _existUser()
  let currentUser = null
  if (exist) {
    let getData = await firebase.firestore().collection('users').doc(uid).get()
    let data = await firebase.auth().currentUser
    if (getData.data() !== undefined) {
      currentUser = Object.assign(getData.data(), data._user)
    } else {
      currentUser = data._user
    }
  }
  return currentUser
}

export const getUserInfoByAttr = async (email) => {
  try {
    let getData = await await firebase
      .firestore()
      .collection('users')
      .where('email', '==', email)
      .get()
    if (!getData.empty) return getData.docs[0].data()
    return false
  } catch (error) {
    return false
  }
}

export const setUserProfile = async (uid, data, policies) => {
  try {
    let dataUser = await getUser(uid)
    if (![undefined, null].includes(dataUser)) {
      let platform = dataUser.platform
      if (platform?.length > 0) {
        if (platform.includes('app')) {
          platform = ['app', 'webapp']
        } else {
          platform = ['webapp']
        }
      } else {
        platform = ['webapp']
      }
      data.platform = platform
      await firebase.firestore().collection('users').doc(uid).update(data)
    } else {
      data.platform = ['webapp']
      await firebase.firestore().collection('users').doc(uid).set(data)

      let audit_logs = {
        policies: {
          date: new Date(),
          status: policies,
        },
        terms: {
          date: new Date(),
          status: policies,
        },
      }
      await firebase
        .firestore()
        .collection('audit_logs')
        .doc(uid)
        .set(audit_logs)
    }
  } catch (error) {
    console.log(error)
  }
}

export const getIdToken = async () => {
  let newToken = await firebase.auth().currentUser.getIdTokenResult()

  return newToken.token
}

export const isRegister = async (email, provider) => {
  if (['', null, undefined].includes(email)) {
    return {code: 0, state: false}
  }
  let result = {code: 1, state: false}
  try {
    const methods = await firebase.auth().fetchSignInMethodsForEmail(email)
    methods.forEach((method) => {
      switch (method) {
        case 'facebook.com':
          if (provider === 'facebook') {
            result = {code: 1, state: true}
          }
          break
        case 'apple.com':
          if (provider === 'apple') {
            result = {code: 1, state: true}
          }
          break
        case 'google.com':
          if (provider === 'google') {
            result = {code: 1, state: true}
          }
          break
        case 'password':
          if (provider === 'email') {
            result = {code: 1, state: true}
          }
          break
        default:
          break
      }
    })
    return result
  } catch (e) {
    return result
  }
}

export const setUser = async (uid, email, uidAnonymous) => {
  let dataUser = await getUser(uid)
  if (dataUser.exists) {
    let platform = dataUser.data().platform
    let listUidAnonymous = dataUser.data().uidAnonymous
    if (listUidAnonymous === undefined) {
      listUidAnonymous = []
    }
    if (uidAnonymous !== null) {
      listUidAnonymous.push(uidAnonymous)
    }

    if (platform?.length > 0) {
      if (platform.includes('app')) {
        platform = ['app', 'webapp']
      } else {
        platform = ['webapp']
      }
    } else {
      platform = ['webapp']
    }

    await firebase
      .firestore()
      .collection('users')
      .doc(uid)
      .update({
        uid: uid,
        email: email,
        is_professional: ![undefined, null].includes(
          dataUser?._data?.is_professional,
        )
          ? dataUser._data.is_professional
          : false,
        uid_anonymous: listUidAnonymous,
        platform: platform,
      })
  } else {
    let user = {
      uid: uid,
      email: email,
      is_professional: ![undefined, null].includes(
        dataUser?._data?.is_professional,
      )
        ? dataUser._data.is_professional
        : false,
      uid_anonymous:
        uid !== uidAnonymous && uidAnonymous !== null
          ? [].push(uidAnonymous)
          : [],
      migrate_data: false,
      alliances: [],
      id_health_insurance: null,
      platform: ['webapp'],
    }
    await firebase.firestore().collection('users').doc(uid).set(user)
  }
}

export const _getUserIdAnonymous = async () => {
  let exist = await _existUser()
  if (exist) {
    let currentUser = await firebase.auth().currentUser
    if (currentUser.isAnonymous) {
      return currentUser.uid
    } else {
      return ''
    }
  } else {
    return ''
  }
}

export const signOut = async () => {
  try {
    await firebase.auth().signOut()
  } catch (e) {
  }
}

export const registerUser = async (
  email,
  password,
  name,
  lastname,
  rut,
  document,
  policies,
  currentUser,
) => {
  try {
    let dataInfo = {
      email: currentUser?.profile?.email,
      first_name: name,
      last_name: lastname,
      uid: currentUser?.profile?.uid,
    }
    let isProfessional = process.env.REACT_APP_IS_PROFESSIONAL === 'TRUE'
    if (isProfessional) {
      dataInfo.is_professional = true
      dataInfo.rut = rut
      dataInfo.document = document
    }
    if (currentUser?.profile.photoURL) {
      dataInfo.photo_url = currentUser?.profile?.photoURL
    }
    let user = localStorage.getItem('login_temp_data')

    if (user) {
      user = JSON.parse(user)
      if ([undefined, false].includes(user?.profile?.first_name)) {
        user.profile.first_name = dataInfo.first_name
        user.profile.last_name = dataInfo?.last_name
      }

      localStorage.setItem('login_temp_data', JSON.stringify(user))
    }
    await setUserProfile(currentUser?.profile?.uid, dataInfo, policies)
    return true
  } catch (error) {
    return null
  }
}

export const resetPassword = (email) => {
  return firebase.auth().sendPasswordResetEmail(email)
}

export const changePassword = async (password, newPassword) => {
  try {
    const currUser = await firebase.auth().currentUser
    const credential = await firebase.auth.EmailAuthProvider.credential(
      currUser.email,
      password,
    )
    const authenticated = await currUser.reauthenticateWithCredential(
      credential,
    )
    if (authenticated) {
      await currUser.updatePassword(newPassword)
      return true
    }
  } catch (error) {
    if (error.code === 'auth/wrong-password') {
      return 'wrong-password'
    }

    return null
  }
}
