import {useEffect, useState} from 'react'
import {connect, useSelector, useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {getHash} from '../../Utils/tools'
import CommunesSearch from '../../Components/CommunesSearch'
import SearchTypeToggle from '../../Components/Search/SearchTypeToggle'
import {IconTutorial} from './icTutorial'
import {IconTutorialRefund} from './icTutorialRefund'
import {IconTutorialMobile} from './icTutorialMobile'
import {IconTutorialRefundMobile} from './icTutorialRefundMobile'
import {fetchCommunes} from '../../redux/modules/delivery'
import parse from 'html-react-parser'
import {logEvent} from '../../Utils/utils'
import Tutorial from '../../Components/Tutorial/Tutorial'

import firstStep from '../../assets/img/tutorial_sura1(2).png'
import secondStep from '../../assets/img/tutorial_sura2.png'
import thirdStep from '../../assets/img/tutorial_sura3.png'
import {setGoRouter} from '../../redux/modules/app'

function Content(props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const locationStorage = useSelector((state) => state.app.commune)
  const themeColor = useSelector((state) => state.app.themeColor)
  const isProHash = useSelector((state) => state.app.isPro)
  const textExtraInfoSearch = useSelector(
    (state) => state.app.text_extra_info_search,
  )
  const refundElement = useSelector((state) => state.app.refund_element)

  useEffect(() => {
    try {
      if (process.env.REACT_APP_CLIENT_NAME === 'UC Christus') {
        let router = []
        router.push({
          go: 'https://www.ucchristus.cl/yapp',
          target: '_blank',
          title: 'Buscador de medicamentos',
          description: 'Red de Salud UC CHRISTUS',
        })
        dispatch(props.setGoRouter(router))
      }
    } catch (ignore) {}

    if (![undefined, null, ''].includes(locationStorage)) {
      history.push(
        '/search/shopping_bag/' +
          Math.floor(Math.random() * Math.floor(100000000000000000000)),
      )
    }
  }, [locationStorage])

  useEffect(() => {
    if (getHash()) {
      dispatch(fetchCommunes())
    }
  }, [])

  const [direction, setDirection] = useState(window.innerWidth)
  useEffect(() => {
    if (direction !== window.innerWidth) {
      setDirection(window.innerWidth)
    }
  }, [direction])

  const listener = () => setDirection(window.innerWidth)
  window.addEventListener('resize', listener)

  return (
    <>
      {isProHash && (
        <div className="d-flex justify-content-between py-3 px-3">
          <div
            className="content-responsive breadcrumbs c-pointer"
            onClick={() => history.goBack()}>
            <a className="d-flex align-items-center">
              <i className="icon-chevron-right icon-reverse"></i>
            </a>
            <span className="title-primary--regular line-14">Volver</span>
          </div>
        </div>
      )}
      <div
        className={`flex-grow-1 pb-4 ${
          process.env.REACT_APP_CLIENT_NAME === 'UC Christus' ? 'bg-white' : ''
        }`}
        style={
          themeColor?.background_color && {
            background: `${themeColor?.background_color}`,
          }
        }>
        <div className="d-flex justify-content-center mt-2">
          <SearchTypeToggle
            medicaments={true}
            examsOnClick={() => {
              logEvent('Medical Exam Opened')
              history.push('/search/exam')
            }}
          />
        </div>
        <div className="content-responsive">
          <div className="">
            {process.env.REACT_APP_PROFILE === 'INSURANCE' ? (
              <div
                className={`d-flex justify-content-center align-items-center  ${
                  direction >= 415 ? 'flex-row mt-3 mb-5' : 'flex-column mt-3'
                }`}>
                <Tutorial
                  className={`pt-1 ${
                    direction >= 766
                      ? 'tutorial-item'
                      : direction > 415
                      ? 'tutorial-item-tablet'
                      : 'tutorial-item-mobile'
                  }`}
                  src={firstStep}
                  alt={'Primer paso, cotizar medicamentos'}
                  step={1}
                  caption={'Cotiza tus medicamentos en distintas farmacias'}
                />
                <Tutorial
                  className={`${
                    direction >= 766
                      ? 'tutorial-item'
                      : direction > 415
                      ? 'tutorial-item-tablet'
                      : 'tutorial-item-mobile'
                  }`}
                  src={secondStep}
                  alt={'Segundo paso, compra con reembolso en linea'}
                  step={2}
                  caption={'Compra con reembolso en línea'}
                />
                <Tutorial
                  className={`pt-1 ${
                    direction >= 766
                      ? 'tutorial-item'
                      : direction > 415
                      ? 'tutorial-item-tablet'
                      : 'tutorial-item-mobile'
                  }`}
                  src={thirdStep}
                  alt={'Tercer paso, recibir en casa'}
                  step={3}
                  caption={'Recíbelos en tu casa'}
                />
              </div>
            ) : (
              <div className="text-center ic-tutorial">
                {![null, undefined, false].includes(refundElement) ? (
                  <>
                    <IconTutorialRefund
                      className="ic-tutorial--item mt-3"
                      fill={
                        themeColor && themeColor.icon_tutorial
                          ? themeColor.icon_tutorial
                          : process.env.REACT_APP_THEME_ICON_TUTORIAL_COLOR
                      }
                    />
                    <IconTutorialRefundMobile
                      className="ic-tutorial--small-mobile"
                      fill={
                        themeColor && themeColor.icon_tutorial
                          ? themeColor.icon_tutorial
                          : process.env.REACT_APP_THEME_ICON_TUTORIAL_COLOR
                      }
                    />
                  </>
                ) : (
                  <>
                    <IconTutorial
                      className="ic-tutorial--item d-none d-md-block"
                      fill={
                        themeColor && themeColor.icon_tutorial
                          ? process.env.REACT_APP_THEME_ICON_TUTORIAL_COLOR
                          : process.env.REACT_APP_THEME_ICON_TUTORIAL_COLOR
                      }
                    />
                    <IconTutorialMobile
                      className="ic-tutorial--small-mobile"
                      fill={
                        themeColor && themeColor.icon_tutorial
                          ? themeColor.icon_tutorial
                          : process.env.REACT_APP_THEME_ICON_TUTORIAL_COLOR
                      }
                    />
                  </>
                )}
              </div>
            )}

            {![null, undefined, ''].includes(textExtraInfoSearch) && (
              <div className="mb-3">
                <p className="txt-4--regular mt-3">
                  {parse(textExtraInfoSearch)}
                </p>
              </div>
            )}
            <div className="">
              <div className="mb-2">
                <CommunesSearch
                  styleSecondary
                  nextAction={() =>
                    history.push(
                      '/search/shopping_bag/' +
                        Math.floor(
                          Math.random() * Math.floor(100000000000000000000),
                        ),
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setGoRouter: (router) => dispatch(setGoRouter(router)),
  }
}

export default connect(null, mapDispatchToProps)(Content)
