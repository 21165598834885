import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'

import NewTrackingStep from '../../Components/NewTrackingStep/NewTrackingStep'
import {trackingInfoByUid} from '../../Utils/api'
import {_formatPrice} from '../../Utils/tools'
import {formatDate} from '../../Utils/utils'

import loadingYapp from '../../assets/img/yapp_loading.gif'
import BgHeaderTracking from '../../assets/img/bg_card_tracking_header.svg'
import IcTrackingRechazo from '../../assets/img/ic_tracking_rechazo.svg'
import icTrackingCancelado from '../../assets/img/ic_tracking_cancelado.svg'
import icTrackingErrorPago from '../../assets/img/ic_tracking_error_pago.svg'
import {changeLogo, showLogo, setThemeColor} from '../../redux/modules/app'

import powerByYapp from '../../assets/img/power_by_header_new.svg'

function ExternalTracking(props) {
  const history = useHistory()
  const {purchaseOrder} = useParams()
  const requestType = 'purchaseOrder'

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [purchaseOrderInfo, setPurchaseOrderInfo] = useState(null)

  useEffect(async () => {
    let purchaseOrderData = null

    if (purchaseOrder) {
      purchaseOrderData = await trackingInfoByUid(requestType, purchaseOrder)
      showTrackingTheme(purchaseOrderData)
    }

    setPurchaseOrderInfo(purchaseOrderData)
    setLoading(false)
  }, [])

  const showTrackingTheme = (info) => {
    if (process.env.REACT_APP_DISABLED_THEME_CLIENT === 'TRUE') {
      dispatch(changeLogo(null))
      dispatch(showLogo(false))
      dispatch(
        setThemeColor({
          primary: '#6578FF',
          secondary: '#DF901E',
        }),
      )
    } else {
      if (
        [null, '', undefined].includes(info?.logo) &&
        ['NULL', undefined].includes(process.env.REACT_APP_LOGO)
      ) {
        dispatch(showLogo(false))
      } else {
        dispatch(showLogo(true))
        dispatch(changeLogo(info?.logo || process.env.REACT_APP_LOGO))
      }
    }
  }

  return (
    <div className="wrapper-location mt-2 position-relative">
      {loading ? (
        <img
          src={loadingYapp}
          alt="loading"
          className="loading-yapp--alternative m-0"
        />
      ) : (
        <>
          {process.env.REACT_APP_CLIENT_NAME === 'UC Christus' && (
            <div className="d-flex justify-content-end mb-4 pb-2">
              <img src={powerByYapp} alt="Yapp" className="power-by-yapp" />
            </div>
          )}
          <div className="flex-grow-1 px-3 pb-4">
            {purchaseOrderInfo ? (
              <div className="content-responsive mx-auto h-100">
                <div className="content-light-wrapper">
                  {!['Cancelado', 'Rechazado'].includes(
                    purchaseOrderInfo.status,
                  ) && (
                    <div
                      className="content-light-wrapper-header"
                      style={{backgroundImage: `url(${BgHeaderTracking})`}}>
                      <h3 className="txt-3--bold-li m-0">¡Sigue tu pedido!</h3>
                    </div>
                  )}
                  <div className="content-light-wrapper-body">
                    {!['Cancelado', 'Rechazado'].includes(
                      purchaseOrderInfo.status,
                    ) ? (
                      <div className="pt-4 pb-2">
                        <NewTrackingStep
                          status={purchaseOrderInfo.status}
                          isPrepaid={purchaseOrderInfo.is_prepaid}
                        />
                      </div>
                    ) : (
                      <div className="content-card-light mb-3">
                        {[
                          'PROBLEMA CON EL MEDIO DE PAGO',
                          'TC RECHAZADA',
                        ].includes(purchaseOrderInfo.rejected_status) && (
                          <div className="text-center pt-2 mx-auto maxw-214">
                            <img src={icTrackingErrorPago} alt="Error pago" />
                            <h3 className="txt-2--regular-db m-0 mb-2">
                              No pudimos procesar tu pago
                            </h3>
                            <p className="txt-5--regular-db m-0">
                              Para que se pueda realizar la entrega debes
                              verificar tu saldo o actualizar el medio de pago
                              en la app.
                            </p>
                            <p className="txt-5--bold-db m-0 mb-2">
                              Menú &gt; Tus medios de pago
                            </p>
                          </div>
                        )}
                        {purchaseOrderInfo.rejected_status === 'SIN STOCK' && (
                          <div className="text-center pt-2 mx-auto maxw-214">
                            <img
                              src={IcTrackingRechazo}
                              alt="Tu pedido fue rechazado por la farmacia<"
                            />
                            <h3 className="txt-2--regular-db m-0 mb-2">
                              Tu pedido fue rechazado por la farmacia
                            </h3>
                            <p className="txt-5--regular-db m-0">
                              Razón de rechazo:
                            </p>
                            <p className="txt-5--regular-er m-0 mb-2">
                              Falta de stock
                            </p>

                            <p className="txt-6--bold-db m-0 mb-2">
                              El cobro a tu tarjeta no se realizará.
                            </p>
                          </div>
                        )}
                        {[
                          'RECETA ILEGIBLE',
                          'RECETA NO CORRESPONDE',
                          'RECETA RETENIDA',
                          'RECETA VENCIDA',
                          'SIN RECETA',
                        ].includes(purchaseOrderInfo.rejected_status) && (
                          <div className="text-center pt-2 mx-auto maxw-214">
                            <img
                              src={IcTrackingRechazo}
                              alt="Tu pedido fue rechazado por la farmacia<"
                            />
                            <h3 className="txt-2--regular-db m-0 mb-2">
                              Tu pedido fue rechazado por la farmacia
                            </h3>
                            <p className="txt-5--regular-db m-0">
                              Razón de rechazo:
                            </p>
                            <p className="txt-5--regular-er m-0 mb-2">
                              Problemas con la receta médica
                            </p>

                            <p className="txt-6--bold-db m-0 mb-2">
                              El cobro a tu tarjeta no se realizará.
                            </p>
                          </div>
                        )}
                        {purchaseOrderInfo.status === 'Cancelado' && (
                          <div className="text-center pt-2 mx-auto maxw-214">
                            <img src={icTrackingCancelado} alt="" />
                            <h3 className="txt-2--regular-db m-0 mb-2">
                              Hemos cancelado tu pedido con éxito
                            </h3>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="content-card-light content-space-horizontal-bite">
                  <h3 className="txt-3--bold-db m-0 mb-3 ">
                    Información de tu pedido
                  </h3>
                  <div className="contet-wrapper-bite mb-2">
                    <div className="content-card-bite mr-md-2 mb-2 mb-md-0">
                      <i className="icon-wallet" />
                      <div className="ml-3">
                        <h4 className="txt-5--bold-db m-0 mb-1">
                          Orden de compra N°{' '}
                          {purchaseOrderInfo.purchase_order_id}
                        </h4>
                        {purchaseOrderInfo.delivery_date && (
                          <p className="txt-6--regular-db m-0">
                            Fecha de entrega:{' '}
                            {formatDate(purchaseOrderInfo.delivery_date, false)}
                          </p>
                        )}
                        <p className="txt-6--regular-db m-0">
                          {purchaseOrderInfo.product_quantity} comprados
                        </p>
                        <p className="txt-6--regular-db m-0">
                          Total del pedido:{' '}
                          <b className="txt-6--bold-db">
                            {_formatPrice(purchaseOrderInfo.product_total)}
                          </b>
                        </p>
                      </div>
                    </div>
                    <div className="content-card-bite ml-md-2">
                      <i className="icon-home-check" />
                      <div className="ml-3">
                        <h4 className="txt-5--bold-db m-0 mb-1">
                          Dirección de Despacho
                        </h4>
                        <p className="txt-6--regular-db m-0">
                          {purchaseOrderInfo.address}
                        </p>
                        <p className="txt-6--regular-db m-0">
                          Teléfono: {purchaseOrderInfo.phone}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="content-responsive mx-auto h-100">
                <div className="content-light-wrapper">
                  <div
                    className="content-light-wrapper-header"
                    style={{backgroundImage: `url(${BgHeaderTracking})`}}>
                    <h3 className="txt-3--bold-li m-0">¡Sigue tu pedido!</h3>
                  </div>
                  <div className="content-light-wrapper-body">
                    <div className="text-center pt-2 mx-auto maxw-214">
                      <img src={icTrackingCancelado} alt="" />
                      <h3 className="txt-2--regular-db m-0 mb-2">
                        Hemos tenido un problema al cargar la información de tu
                        pedido
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default ExternalTracking
