import React from 'react'
import './installButton.css'

function InstallButton(props) {

  let text = props.text

  return (
    <div className="d-flex align-items-center justify-content-center flex-column">
      <a 
      className="installButton mt-2"
      href="https://yapp.cl/instalacion-yapp-medicos/"
      target="_blank"
      rel="noreferrer">
        {text}
      </a>
    </div>
  )
}

export default InstallButton
