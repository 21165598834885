import firebase from 'firebase/app'
import {getIdToken} from './auth'
import {_formatRut, _separateRut} from './tools'
import {decrypt, normalizeRut} from './utils'

const _get = require('lodash/get')

const fetchApi = async (url, method, data, retry = false, extraOptions) => {
  if (!localStorage.getItem('JWT')) {
    if (firebase.auth().currentUser) {
      let token = await getIdToken()
      localStorage.setItem('JWT', token)
    }
  }
  if (!localStorage.getItem('JWT') && extraOptions?.authApi !== false) {
    window.location.replace('/error')
  }

  let sessionData = null

  try {
    sessionData = JSON.parse(localStorage.getItem('LOCAL_DATA'))
  } catch (error) {}
  let headers = {
    'client-id': extraOptions?.headers?.clientId
      ? extraOptions.headers.clientId
      : process.env.REACT_APP_CLIENT_ID,
  }
  if (extraOptions?.headers?.clientId) {
    delete extraOptions.headers.clientId
  }

  if (extraOptions?.authApi !== false)
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('JWT')

  if (![undefined, null, false].includes(extraOptions?.headers)) {
    headers = {...headers, ...extraOptions?.headers}
  }

  return fetch(url, {
    method: method,
    headers: headers,
    body: data && JSON.stringify(data),
  })
    .then((response) => {
      return response
    })
    .catch(async (err) => {
      console.log('Error on request')
      console.log(`Requesting to: ${url} with method ${method}`)
      console.log(err)
      if (err.message === 'Failed to fetch') {
        console.log('Failed to fetch')

        if (!retry) {
          await refreshToken()
          return fetchApi(url, method, data, true)
        }
      }
    })
}

export const refreshToken = async () => {
  try {
    if (firebase.auth().currentUser) {
      let token = await getIdToken()
      localStorage.setItem('JWT', token)
      return true
    } else {
      let localData = JSON.parse(localStorage.getItem('LOCAL_DATA'))
      let hash = _get(localData, 'hash')
      return fetch(
        process.env.REACT_APP_API_INTEGRATION + '/web_app/user/refresh_token',
        {
          method: 'POST',
          body: JSON.stringify({
            hash: hash,
            token: localStorage.getItem('JWT'),
          }),
        },
      ).then(async (response) => {
        if (!response.ok) {
          return false
        }

        localStorage.setItem('JWT', _get(await response.json(), 'data.token'))
      })
    }
  } catch (error) {
    return false
  }
}

export const getCommunesBlackList = async () => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + '/delivery/communes/black_list',
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const getConfigPage = (hash) => {
  return fetch(
    process.env.REACT_APP_API_INTEGRATION + '/request?hash=' + hash,
    {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('JWT'),
      },
    },
  ).then((response) => {
    let json = response.json()
    if (!response.ok) {
      json.then((res) => {
        if (res?.data?.error === 'LIMIT_EXCEEDED') {
          console.log('Peticiones excedidas')
        }
      })

      return false
    }

    return json
  })
}

export const getGenerateQuotation = async (productIds, location) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + '/quotation',
      'POST',
      {
        products: productIds,
        coords: {
          lat: location?.lat,
          lng: location?.lng,
        },
        commune_id: location?.id,
      },
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const getExamQuotation = async (examIds) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + '/exam/quotation',
      'POST',
      {
        exams: examIds,
      },
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const getVademecumDetail = async (
  productId,
  externalVademecum,
  resultSearch,
) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION +
        '/vademecum/detail?id=' +
        productId +
        (externalVademecum === true
          ? '&external_vademecum=' + externalVademecum
          : ''),
      'GET',
      null,
      false,
      {
        headers: {
          'result-id': resultSearch.result_id || null,
          'request-from': resultSearch.request_from || null,
          'is-active-principle': [undefined, null, false].includes(
            resultSearch.is_active_principle,
          )
            ? 0
            : 1,
          'active-principle-id': resultSearch.active_principle_id || null,
        },
      },
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const autocomplete = async (
  text,
  external_vademecum = 0,
  request_from = null,
  commune_id = null
) => {
  try {
    let url = process.env.REACT_APP_API_INTEGRATION +
        '/vademecum/autocomplete?'+
        'text=' + encodeURIComponent(text) +
        '&external_vademecum=' + external_vademecum
    url = (commune_id === null) ? url : url + '&commune_id=' + commune_id
    let response = await fetchApi(
      url,
      'GET',
      null,
      false,
      {headers: {'request-from': request_from}},
    )

    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const examsAutocomplete = async (text) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + '/exam/autocomplete?text=' + text,
      'GET',
      null,
      false,
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const drugInteraction = async (info) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + '/drug-interaction',
      'POST',
      info,
    )
    return response.ok && response.status === 200 ? response.text() : []
  } catch (error) {
    console.log('Error', error)
    return []
  }
}

export const getConfigSite = async () => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + '/webapp/config',
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const getDeliveryCommunes = async () => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + '/delivery/communes',
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const sendToPay = async (hash, returnUrl) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + '/payment',
      'POST',
      {
        hash: hash,
        return_url: returnUrl,
      },
    )
    return response.ok && response.status === 200 ? response.json() : false
  } catch (error) {
    return false
  }
}

export const getStatusPayment = async (token) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + '/payment?token=' + token,
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return {}
  }
}

export const createPurchaseOrder = async (hash) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + '/purchase_order',
      'POST',
      {hash},
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const createSubscription = async (subscription) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_YAPP + '/subscription',
      'POST',
      {subscription},
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const requestUnique = async (hash) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + '/request_unique?hash=' + hash,
      'GET',
      null,
      false,
      {
        authApi: false,
        headers: {
          'x-api-key': process.env.REACT_APP_API_INTEGRATION_KEY,
        },
      },
    )
    return response.ok && response.status === 200 ? response.json() : false
  } catch (error) {
    return false
  }
}

export const requestGenerateLink = async (
  hash,
  inputValue,
  professional = null,
  extraData = {},
) => {
  try {
    /** Params
     *  inputValue: [email, identity]
     */
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + '/request_generate_link',
      'POST',
      {hash, input: inputValue, professional, data: extraData},
      false,
      {
        authApi: false,
        headers: {
          'x-api-key': process.env.REACT_APP_API_INTEGRATION_KEY,
        },
      },
    )
    return response.ok && response.status === 200 ? response.json() : false
  } catch (error) {
    return false
  }
}

export const uploadFile = async (formData) => {
  try {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('JWT'))

    let path = process.env.REACT_APP_API_INTEGRATION + '/s3/file'
    const res = await fetch(path, {
      method: 'POST',
      headers: myHeaders,
      body: formData,
      redirect: 'follow',
    })
    const json = await res.json()
    return json
  } catch (err) {
    return err
  }
}

export const getNewReleases = async () => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + '/product/new-releases',
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : {}
  } catch (error) {
    return {}
  }
}

export const getProductsWithProgram = async (programId) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + '/product/product-programs',
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : {}
  } catch (error) {
    return {}
  }
}

export const getBioequivalents = async (productId) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION +
        '/product/bioequivalents-list?product_id=' +
        productId,
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const getComplementary = async (communeId) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION +
        '/product/complementary?commune_id=' +
        communeId,
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : {}
  } catch (error) {
    return []
  }
}

export const existProfessional = async (identity, email) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION +
        '/professional/exist?rut=' +
        identity +
        '&email=' +
        email,
      'GET',
      null,
      false,
      {
        authApi: false,
        headers: {
          'x-api-key': process.env.REACT_APP_API_INTEGRATION_KEY,
        },
      },
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const usedProfessional = async (identity) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION +
        '/professional/used?identity=' +
        identity,
      'GET',
      null,
      false,
      {
        authApi: false,
        headers: {
          'x-api-key': process.env.REACT_APP_API_INTEGRATION_KEY,
        },
      },
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const validateProfessional = async (identity) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION +
        '/professional/validate?skip_validation=true&identity=' +
        identity,
      'GET',
      null,
      false,
      {
        authApi: false,
        headers: {
          'x-api-key': process.env.REACT_APP_API_INTEGRATION_KEY,
        },
      },
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const getProfessionalDiscountCode = async (user_id, identity) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION +
        '/professional/discount_code?user_id=' +
        user_id +
        '&identity=' +
        identity,
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const insertLogProfessional = async (identity, email) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + '/professional/insertLog',
      'POST',
      {
        rut: identity,
        email: email,
      },
      false,
      {
        authApi: false,
        headers: {
          'x-api-key': process.env.REACT_APP_API_INTEGRATION_KEY,
        },
      },
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const getProfessionalContent = async (
  productId,
  externalVademecum,
  isProfessional = 0,
) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_YAPP +
        '/professional/content?productId=' +
        productId +
        '&externalVademecum=' +
        externalVademecum +
        '&isProfessional=' +
        isProfessional,
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const getLabs = async () => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + '/laboratory',
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const existUserClient = async (identity) => {
  try {
    let isInsurance = process.env.REACT_APP_PROFILE === 'INSURANCE'
    let isIsp = isInsurance ? '0' : ('0' + identity)

    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION +
        `/client_user?identity=${identity}&is_isp=` + isIsp,
      'GET',
      null,
      false,
      {
        authApi: false,
        headers: {
          'x-api-key': process.env.REACT_APP_API_INTEGRATION_KEY,
        },
      },
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const getS3File = async (filepath, bucket = null) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION +
        `/s3/file?bucket=${bucket}&filepath=${filepath}`,
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const sendPatientProgramsDocuments = async (
  template,
  sender,
  patientEmail,
  checkbox,
  labContact,
  currentProgram,
  topic,
  description,
) => {
  let files = []

  currentProgram?.file.map((file) => {
    if (![undefined, null, ''].includes(file.file_link)) {
      files.push({
        title: file.file_name,
        url: file.file_link,
      })
    }
  })

  let data = {
    receiver: patientEmail,
    bcc: checkbox
      ? JSON.parse(localStorage.getItem('LOCAL_DATA')).site.info.email
      : '',
    sender: sender,
    templateId: template,
    dynamic_template_data: {
      laboratoryEmail: labContact?.email,
      laboratoryPhone: labContact?.phone,
      laboratoryContact: labContact?.email,
      topic: topic,
      description: description,
      files: files,
    },
  }

  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + '/email/send',
      'POST',
      data,
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const sendMail = async (
  receiver,
  sender,
  bcc,
  templateId,
  templateData,
) => {
  let data = {
    receiver: receiver,
    sender: sender,
    bcc: bcc,
    templateId: templateId,
    dynamic_template_data: templateData,
  }
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + '/email/send',
      'POST',
      data,
    )

    return response.ok && response.status === 204 ? response : []
  } catch (error) {
    return []
  }
}

export const sendDoctorVerificationEmail = async (data) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + '/email_professional_event/send',
      'POST',
      data,
      false,
      {
        authApi: false,
      },
    )
    return response
  } catch (error) {
    return false
  }
}

export const sendEmail = async (data) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + '/email_report_event/send/',
      'POST',
      data,
      false,
      {
        authApi: false,
      },
    )
    return response
  } catch (error) {
    return false
  }
}

export const trackingOrder = async (hash) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + `/tracking?hash=${hash}`,
      'GET',
      null,
      false,
      {
        authApi: false,
        headers: {
          'x-api-key': process.env.REACT_APP_API_INTEGRATION_KEY,
        },
      },
    )
    return response.ok && response.status === 200 ? response.json() : null
  } catch (error) {
    return []
  }
}

export const trackingInfoByUid = async (requestType, requestUid) => {
  let resultData = null

  try {
    const response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION +
        `/tracking?${requestType}=${requestUid}`,
      'GET',
      null,
      false,
      {
        authApi: false,
        headers: {
          'x-api-key': process.env.REACT_APP_API_INTEGRATION_KEY,
        },
      },
    )

    let streamData = await response.json()
    if (streamData?.data?.length > 0) {
      resultData = streamData.data[0]
    }
  } catch (error) {}

  return resultData
}

export const getDeliveryPriceByPharmacyAndMunicipality = async (pharmacy_chain_id, municipality_id) => {
  let resultData = null

  try {
    const response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION +
        `/delivery/cost?pharmacy_chain_id=${pharmacy_chain_id}&municipality_id=${municipality_id}&by_client=true`,
      'GET',
      null,
      false,
      {
        authApi: false,
        headers: {
          'x-api-key': process.env.REACT_APP_API_INTEGRATION_KEY,
        },
      },
    )

    if (response?.status == 200) {
      let streamData = await response.json()
      if (streamData?.data?.length > 0) {
        resultData = streamData.data[0]
      }
    }

  } catch (error) {
    console.warn(`DeliveryPriceByPharmacyAndMunicipality`, error)
  }
  return resultData
}

export const getProductByActivePrinciple = async (
  activePrincipleID,
  resultSearch,
) => {
  try {
    let response = await fetchApi(
      `${process.env.REACT_APP_API_INTEGRATION}/product/active_principle?active_principle_id=${activePrincipleID}`,
      'GET',
      null,
      false,
      {
        headers: {
          'result-id': resultSearch.result_id || null,
          'request-from': resultSearch.request_from || null,
          'is-active-principle': [undefined, null, false].includes(
            resultSearch.is_active_principle,
          )
            ? 0
            : 1,
          'active-principle-id': resultSearch.active_principle_id || null,
        },
      },
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const getProductPricesById = async (productId) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION +
        `/product-price?userId=0&productId=${productId}&isProduct=true&resultPosition=0&platform=marshall`,
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const getProgramsByType = async (type) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + `/programs?type=${type}`,
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const getProductsByProgram = async (id) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + `/programs?id=${id}`,
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const getProductByExternalId = async (productId) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION +
        '/product/detail?external_id=' +
        productId,
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const getSubscriptionInfoByProductId = async (
  productId,
  communeId = 13114,
) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_YAPP +
        '/subscription/products?productId=' +
        productId +
        '&communeId=' +
        communeId,
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const validProfessional = async (identity, userId) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_YAPP +
        `/professional/validate?identity=${identity}&user_id=${userId}`,
      'GET',
    )

    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    console.log(error)
    return []
  }
}

export const getCreditCard = async (userId) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_YAPP + `/user/payment_method?user_id=${userId}`,
      'GET',
    )

    return response?.ok && response?.status === 200 ? response.json() : []
  } catch (error) {
    console.log(error)
    return []
  }
}

export const getCreditCardResult = async (subscriptionId) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_YAPP +
        `/payment/card/result?subscriptionId=${subscriptionId}`,
      'GET',
    )

    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    console.log(error)
    return []
  }
}

export const getBeneficiaries = async (identity, clientId = null) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION +
        `/insurance/beneficiaries?identity=${identity}`,
      'GET',
      null,
      false,
      {
        headers: {
          clientId: ![undefined, null].includes(clientId)
            ? clientId
            : process.env.REACT_APP_CLIENT_ID,
        },
      },
    )

    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return false
  }
}
export const getInsurances = async () => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + `/insurance`,
      'GET',
    )

    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return false
  }
}

export const getInsuranceLiquidation = async (
  clientId = null,
  userData,
  productList,
  identity,
) => {
  try {
    let value = JSON.parse(decrypt(userData))
    identity = identity.replaceAll('.', '')

    let data = {
      identity_titular: normalizeRut(identity, 12),
      identity: normalizeRut(value.identity, 12),
      correlative: value.correlative,
      policy_id: value.policy_id,
      products: productList,
    }

    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + `/insurance/liquidation`,
      'POST',
      data,
      false,
      {
        headers: {
          clientId: ![undefined, null].includes(clientId)
            ? clientId
            : process.env.REACT_APP_CLIENT_ID,
        },
      }
    )

    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    console.log(error)
    return false
  }
}

export const addCreditCard = async (userData) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_YAPP + `/payment/card`,
      'POST',
      userData,
    )

    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    console.log(error)
    return []
  }
}

export const getSubscriptionDiscountByCode = async (
  userId,
  identity,
  subscriptionProductId,
  municipality,
  deliveryCost,
  total,
  code,
) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_YAPP +
        `/shopping-cart/discount?` +
        `userId=${userId}&subscriptionProductId=${subscriptionProductId}&deliveryCost=${deliveryCost}&` +
        `total=${total}&municipality=${municipality}&code=${code}&identity=${identity}`,
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const GetSavedMoney = async (userId) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_YAPP + '/user/saved-money?userId=' + userId,
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const GetOrders = async () => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_YAPP + '/purchase-order',
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const GetSubscriptions = async () => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_YAPP + '/subscription',
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const GetRegularSubscriptions = async (userId) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_YAPP +
        `/subscription?personId=${userId}&type=regular`,
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const updateSubscription = async (data) => {
  try {
    const response = await fetchApi(
      process.env.REACT_APP_API_YAPP + `/subscription`,
      'PUT',
      {subscription: data},
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const getCartPreArmed = async (id) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION +
        '/quotation/cart-prearmed?id=' +
        id,
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    return []
  }
}

export const getClientSpeciality = async () => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + `/client/speciality`,
      'GET',
      null,
      false,
      {
        authApi: false,
        headers: {
          'x-api-key': process.env.REACT_APP_API_INTEGRATION_KEY,
        },
      },
    )

    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    console.log(error)
    return []
  }
}

export const getGenerateLink = async (products) => {
  let data = {
    email: 'contacto@yapp.cl',
    request_type: 'search',
    client_id: process.env.REACT_APP_CLIENT_ID_QUOTATION,
    data: {
      patient: null,
      professional: null,
      products: products,
    },
  }
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + `/link/generate`,
      'POST',
      data,
    )

    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    console.log(error)
    return []
  }
}

export const getHolidayList = async () => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_INTEGRATION + `/holiday`,
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    console.log(error)
    return []
  }
}

export const getPrescriptionFiles = async () => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_YAPP + `/prescription?type=files`,
      'GET',
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    console.log(error)
    return []
  }
}

export const setSessionIds = async (data) => {
  try {
    let response = await fetchApi(
      process.env.REACT_APP_API_YAPP + '/tracking/save-sessions',
      'POST',
      data,
    )
    return response.ok && response.status === 200 ? response.json() : false
  } catch (error) {
    return false
  }
}

export const getProductsByCategoryFromApi = async (categoryId = null, communeId = null) => {
  try {
    let response = await fetchApi(
      `${process.env.REACT_APP_API_INTEGRATION}/product/category?category_id=${categoryId}&commune_id=${communeId}`,
      'GET',
      null,
      false,
      {
        authApi: false,
        headers: {
          'x-api-key': process.env.REACT_APP_API_INTEGRATION_KEY,
        },
      },
    )
    return response.ok && response.status === 200 ? response.json() : []
  } catch (error) {
    console.log(error)
    return []
  }
}
