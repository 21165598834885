import IcInteraccionesMed from '../assets/img/Interactions.svg'
import IcComprarMedicamento from '../assets/img/ic_comprar_medicamento.svg'
import IcPrescribirMedicamento from '../assets/img/ic_prescribir_medicamento.svg'
import IcFichaMedicamento from '../assets/img/ficha_medicamento.svg'
import IcBuscarPrecios from '../assets/img/ic_buscar_precios.svg'
// import IcCotizar from '../../assets/img/cotizar_activado.svg'
import Iclanzamientos from '../assets/img/lanzamientos_activado.svg'
import IcContactar from '../assets/img/contactar_activado.svg'
import IcInteracciones from '../assets/img/interacciones_activado.svg'
import IcPricePharmacy from '../assets/img/ic_precio_farmacia.svg'
import IcProgramaPaciente from '../assets/img/ic_programa_paciente.svg'
import IcAgreement from '../assets/img/ic_agreement.svg'
import IcBuyMedicaments from '../assets/img/buy-medicaments.svg'

const urlPrescribir =
  'https://accounts.claveunica.gob.cl/accounts/login/?next=/openid/authorize%3Fclient_id%3D5aa1a66c9e844e18a4a41740ed4aa476%26redirect_uri%3Dhttps%3A//seremienlinea.minsal.cl/callback/recetas/indexPRE.php%26response_type%3Dcode%26scope%3Dopenid%2520run%2520name%26state%3Dd52dd8d7c2614fea66467ec4d8533768'

export const base_url = window.location.origin
export const searchUrl =
  window.location.origin +
  '/search/shopping_bag/' +
  Math.floor(Math.random() * Math.floor(100000000000000000000))

export const pharmacyChainUrl =
  'https://s3.us-east-2.amazonaws.com/s3.yapp/img/pharmacychains/logo/'
export const prescriptionUrl = 'https://s3.us-east-2.amazonaws.com/s3.yapp/'
export const programsUrl = 'https://s3.us-east-2.amazonaws.com/s3.yapp'
export const productsUrl =
  'https://s3.us-east-2.amazonaws.com/s3.yapp/img/products/'

export const acceptedFileTypes = ['jpg', 'jpeg', 'png', 'pdf']
//https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const MIME_types = {
  pdf: 'application/pdf',
  png: 'image/png',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
}

export const shareMessages = {
  shareProduct: {
    title: 'Yapp Chile',
    description:
      'Visita nuestro sitio web y cotiza los medicamentos que necesitas!',
  },
  shareWebApp: {
    title: 'Yapp Chile',
    description:
      'Visita nuestro sitio web y cotiza los medicamentos que necesitas!',
  },
}
export const hideSideBar = [
  '/',
  '/signin',
  '/home',
  '/page',
  '/pro/menu',
  '/pro/home',
  '/pro/success',
  '/pro/change_password',
  '/pro/forgot_password',
  '/pro/login',
  '/pro/register',
  '/pro/register_validate',
  '/error',
  '/no-access',
  '/legal',
]

export const hideAccountAvatar = [
  '/',
  '/signin',
  '/home',
  '/page',
  '/pro/home',
  '/pro/success',
  '/pro/change_password',
  '/pro/forgot_password',
  '/pro/login',
  '/pro/register',
  '/pro/register_validate',
  '/error',
  '/no-access',
  '/legal',
]

export const hideBtnBack = ['/', '/signin', '/search']

export const ENUM_MENU = {
  medication_sheet: 'medication-sheet',
  price_finder: 'price-finder',
  drug_interactions: 'drug-interactions',
  medication_buy: 'medication-buy',
  new_releases: 'new-releases',
  labs_contact: 'labs-contact',
  programs: 'programs',
  agreement: 'agreement',
  agreement_locked: 'agreement-locked',
}

export const ArrCardInteraction = [
  {
    id: 'drug-interactions',
    title: 'Interacciones entre medicamentos',
    description: 'Valida las interacciones antes de recetar',
    icon: IcInteraccionesMed,
    page: '/pro/drug_interactions',
  },
]

export const deliveryFrequencies = [
  {
    value: 14,
    label: '14 días',
  },
  {
    value: 21,
    label: '21 días',
  },
  {
    value: 28,
    label: '28 días',
  },
  {
    value: 30,
    label: '30 días',
  },
  {
    value: 35,
    label: '35 días',
  },
  {
    value: 42,
    label: '42 días',
  },
  {
    value: 50,
    label: '50 días',
  },
  {
    value: 56,
    label: '56 días',
  },
  {
    value: 60,
    label: '60 días',
  },
  {
    value: 90,
    label: '90 días',
  },
  {
    value: 120,
    label: '120 días',
  },
]

export const MenuProCard = [
  {
    id: ENUM_MENU.medication_sheet,
    isDisabled: true,
    isHidden: false,
    icon: IcFichaMedicamento,
    title: 'Fichas de medicamentos',
    description: 'Revisa toda la información que necesitas saber.',
    page: '/pro/medication-sheet',
    isSoon: false,
    eventName: 'Medication Sheet Opened',
    extraData: {},
  },
  {
    id: ENUM_MENU.price_finder,
    isDisabled: true,
    isHidden: false,
    icon: IcPricePharmacy,
    title: 'Buscador de precios',
    description: 'Busca rápidamente el costo de los medicamentos.',
    page: '/pro/price_finder',
    isSoon: false,
    eventName: 'Price Finder Opened',
    extraData: {},
  },
  {
    id: ENUM_MENU.drug_interactions,
    isDisabled: true,
    isHidden: false,
    icon: IcInteracciones,
    title: 'Interacciones medicamentosas',
    description: 'Valida las interacciones antes de recetar.',
    page: '/pro/drug_interactions',
    isSoon: false,
    eventName: 'Drug Interaction Opened',
    extraData: {
      Origin: 'Menu',
    },
  },
  {
    id: ENUM_MENU.medication_buy,
    isDisabled: true,
    isHidden: false,
    icon: IcComprarMedicamento,
    title: 'Comprar medicamentos',
    description: 'Compra al mejor precio, siempre.',
    page: '/search',
    isSoon: false,
    eventName: 'Medication Buy Opened',
    extraData: {},
  },
  {
    id: ENUM_MENU.new_releases,
    isDisabled: true,
    isHidden: false,
    icon: Iclanzamientos,
    title: 'Nuevos lanzamientos',
    description: 'Descubre los lanzamientos de medicamentos.',
    page: '/pro/new_drugs',
    isSoon: false,
    eventName: 'New Releases Opened',
    extraData: {},
  },
  {
    id: ENUM_MENU.labs_contact,
    isDisabled: true,
    isHidden: false,
    icon: IcContactar,
    title: 'Contactar laboratorios',
    description: 'Habla directamente con los laboratorios.',
    page: '/pro/laboratory',
    isSoon: false,
    eventName: 'Contact Laboratory Opened',
    extraData: {},
  },
  {
    id: ENUM_MENU.programs,
    isDisabled: true,
    isHidden: false,
    icon: IcProgramaPaciente,
    title: 'Información de programas',
    description: 'Infórmate sobre programas de pacientes y de acceso.',
    page: '/pro/programs',
    isSoon: false,
    eventName: 'Material Program Opened',
    extraData: {},
  },
  {
    id: ENUM_MENU.agreement,
    isDisabled: true,
    isHidden: true,
    icon: IcAgreement,
    title: 'Convenio Yapp',
    description: 'Descuentos en tratamientos para tus pacientes',
    page: '/pro/agreement',
    isSoon: false,
    eventName: 'Yapp Benefit Opened',
    extraData: {
      Enabled: true,
    },
  },
  {
    id: ENUM_MENU.agreement_locked,
    isDisabled: true,
    isHidden: false,
    icon: IcAgreement,
    title: '¿Quieres ser parte del convenio Yapp?',
    description:
      'Inscríbete aquí y obtén descuentos en tratamientos para tus pacientes',
    page: '',
    isSoon: false,
    eventName: 'Yapp Benefit Opened',
    typeForm:
      'https://yappchile.typeform.com/to/m2CniVAu?utm_source=pro.yapp.cl&utm_medium=button&utm_campaign=convenio_interes_docs',
    type: 'AAT',
    extraData: {
      Enabled: false,
    },
  },
]

export const Menus = [
  'quotation',
  'labs-contact',
  'new-releases',
  'medication-sheet',
  'drug-interactions',
  'price-finder',
  'medication-buy',
  'programs',
]
export const subscriptionTypes = {
  regular: 'regular',
  special: 'special',
  spot: 'spot',
}

export const pathArr = [
  '/signin',
  '/register',
  '/loading_checkout',
  '/design/pro_register',
]

export const pathArrPro = [
  '/signin',
  '/register',
  '/register_pro',
  '/login',
  '/register_validate',
  '/',
]

export const headerName = {
  '/': '',
  '/signin': '',
  '/home': '',
  '/page': '',
  '/pro/home': '',
  '/pro/success': '',
  '/pro/change_password': '',
  '/pro/forgot_password': '',
  '/pro/login': '',
  '/pro/register': '',
  '/pro/register_validate': '',
  '/error': '',
  '/share': '¡Comparte y gana en Yapp!',
  '/pilbox': 'Tu pastillero',
  '/search': 'Compra tus medicamentos',
  '/shopping_bag/quotation': 'Lista de farmacias',
}

export const rrss = {
  facebook: 'https://www.facebook.com/yappchile/',
  instagram: 'https://www.instagram.com/yapp_chile/?hl=en',
  linkedin: 'https://www.linkedin.com/company/yapp-cl/mycompany/',
}

export const arraySidenav = [
  {
    name: 'Explora',
    icon: 'icon-search',
    page: '/search',
    userType: 'PATIENT',
  },
  {
    name: 'Yapp Médicos',
    icon: 'icon-medical-home',
    page: '/pro/menu',
    userType: 'MEDICAL',
  },
  {
    name: 'Tu salud',
    icon: 'icon-healt',
    userType: 'PATIENT',
    subMenu: [
      {
        name: 'Tu pastillero',
        action: '/pilbox',
        url: null,
      },
    ],
  },
  {
    name: 'Tu cuenta',
    icon: 'icon-perfil',
    userType: 'PATIENT',
    subMenu: [
      {
        name: 'Tus compras',
        action: '/my_shopping',
        url: null,
      },
    ],
  },
  {
    name: 'Comprar medicamentos',
    icon: 'icon-store-check',
    page: '/search',
    userType: 'MEDICAL',
  },
]

export const productType = {
  G: 'Genéricos',
  M: 'Marca',
  BG: 'BioEq. Genérico',
  BM: 'BioEq. Marca',
  ETC: 'Por definir.',
}

export const stepNewTrackerPre = [
  {text: 'Pedido pagado', icon: 'icon-store-check', status: ''},
  {text: 'Confirmado por farmacia', icon: 'icon-pharmacy-check', status: ''},
  {text: 'Pedido en camino', icon: 'icon-delivery', status: ''},
  {text: 'Pedido entregado', icon: 'icon-box-check', status: ''},
]

export const stepNewTrackerPos = [
  {
    text: 'Esperando confirmación de la farmacia',
    icon: 'icon-store-check',
    description:
      '*El cobro de tu pedido se realizará una vez se confirme por la farmacia.',
    status: '',
  },
  {
    text: 'Confirmado por farmacia',
    icon: 'icon-pharmacy-check',
    status: '',
  },
  {text: 'Pedido pagado', icon: 'icon-pay', status: ''},
  {text: 'Pedido en camino', icon: 'icon-delivery', status: ''},
  {text: 'Pedido entregado', icon: 'icon-box-check', status: ''},
]

export const theme = {
  border_radius: process.env.REACT_APP_BORDER === 'LIGHT' ? '4px' : '22px',
  border_color:
    process.env.REACT_APP_BORDER === 'LIGHT' ? 'var(--color-stroke)' : null,
  border_radius_input: process.env.REACT_APP_BORDER === 'LIGHT' ? '4px' : '8px',
  size_icon_search: process.env.REACT_APP_BORDER === 'LIGHT' ? '38px' : '41px',
}

export const isMaxScreenResposive = ['PATIENT', 'MEDICAL'].includes(
  process.env.REACT_APP_PROFILE,
)
  ? false
  : true

export const toastConfig = {
  holidayDateError: {
    color: 'var(--color-error-ds)',
    icon: 'icon-alert',
    message: 'Lo sentimos, debes seleccionar un día hábil.'
  },
  weekendDateError: {
    color: 'var(--color-error-ds)',
    icon: 'icon-alert',
    message: 'Lo sentimos, no contamos con despacho los fines de semana.'
  },
  discountSuccess: {
    color: null,
    icon: 'icon-check',
    message: 'Código aplicado exitosamente'
  },
  refundError: {
    color: 'var(--color-error-ds)',
    icon: 'icon-alert',
    message: 'No se puede aplicar el reembolso'
  },
  refundSuccess: {
    color: null,
    icon: 'icon-check',
    message: 'Reembolso aplicado exitosamente'
  },
  newCardSuccess: {
    color: null,
    icon: 'icon-check',
    message: 'Método de pago agregado exitosamente'
  },
  prescriptionRefundError: {
    color: 'var(--color-error-ds)',
    icon: 'icon-alert',
    message: 'Debe agregar una receta para calcular el reembolso'
  },
  prescriptionSuccess: {
    color: null,
    icon: 'icon-check',
    message: 'Receta agregada exitosamente'
  },
  cartHasNonRefundableProductsError: {
    color: 'var(--color-error-ds)',
    icon: 'icon-alert',
    message: 'Estos medicamentos no son reembolsables a través de Yapp'
  }
}

export const productsWithSuraBenefits = [
  {
    name: "Acotol X 28 Comprimidos Recubiertos",
    activePrinciple: "Anticonceptivos",
    laboratory: "Abbott",
    image: "https://yapp.cl/wp-content/uploads/2022/12/acotol.png",
    discount: "30",
  },
  {
    name: "Dvida Gotas 200 Ui / 1 Ml X 10 Mililitros",
    activePrinciple: "Vitaminas",
    laboratory: "Grunenthal",
    image: "https://s3.us-east-2.amazonaws.com/s3.yapp/img/products/6544.jpg",
    discount: "25",
  },
  {
    name: "Lactafem 75 Mcg X 28 Comprimidos Recubiertos",
    activePrinciple: "Anticonceptivos",
    laboratory: "Andrómaco",
    image: "https://s3.us-east-2.amazonaws.com/s3.yapp/img/products/gr68082.jpg",
    discount: "25",
  },
  {
    name: "Femelle X 28 Comprimidos Recubiertos",
    activePrinciple: "Anticonceptivos",
    laboratory: "Abbott",
    image: "https://s3.us-east-2.amazonaws.com/s3.yapp/img/products/4760_v2.jpg",
    discount: "30",
  },
  {
    name: "Gianda CD X 28 Comprimidos Recubiertos",
    activePrinciple: "Anticonceptivos",
    laboratory: "Grunenthal",
    image: "https://s3.us-east-2.amazonaws.com/s3.yapp/img/products/gr79826.jpg",
    discount: "25",
  },
  {
    name: "Nuvaring X 1 Anillo Vaginal",
    activePrinciple: "Anticonceptivos",
    laboratory: "MSD",
    image: "https://s3.us-east-2.amazonaws.com/s3.yapp/img/products/3b9ed0171ddf94df0b57e99e94bcdddf.jpg",
    discount: "30",
  },
  {
    name: "Miafem CD X 28 Comprimidos Recubiertos",
    activePrinciple: "Anticonceptivos",
    laboratory: "Tecnofarma",
    image: "https://s3.us-east-2.amazonaws.com/s3.yapp/img/products/5213_v2.jpg",
    discount: "30",
  },
  {
    name: "Accu-Chek Combo Kit X 1 Kit",
    activePrinciple: "Diabetes",
    laboratory: "Roche",
    image: "https://s3.us-east-2.amazonaws.com/s3.yapp/img/products/107623.png",
    discount: "30",
  },
]