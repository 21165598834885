import React, {useState, useEffect} from 'react'

import {changeLogo, setFormData} from '../../redux/modules/app'
import {connect, useDispatch, useSelector} from 'react-redux'
import ButtonFalseInput from '../../Components/Buttons/ButtonFalseInput'
import ModalNoValidInsurance from '../../Components/Modals/ModalNoValidInsurance'
import {useHistory} from 'react-router-dom'
import {getBeneficiaries} from '../../Utils/api'
import LoadingSura from '../../Components/Loading/LoadingSura'
import LoadingScreen from 'react-loading-screen'
import Modal from 'react-modal'
import {setCopayUserData} from '../../redux/modules/user'
import {encrypt} from '../../Utils/utils'
import * as Sentry from '@sentry/react'
import {setContext} from '@sentry/react'
import {
  _formatRut,
  _validateMail,
  _validateRut,
  _getDv,
  _capitalize,
} from '../../Utils/tools'
import {logEvent} from '../../Utils/utils'
import {setDeliveryCode} from '../../redux/modules/delivery'
import TypeSidetab from '../../Components/TypeForm/TypeSidetab'

function Beneficiaries(props) {
  const dispatch = useDispatch()
  const [modalNoValidInsurance, setModalNoValidInsurance] = useState(false)
  const [benefit, setBenefit] = useState(null)
  const history = useHistory()
  const [showErrorLoginInsurance, setShowErrorLoginInsurance] = useState(false)
  const [errorLoginInsurance, setErrorLoginInsurance] = useState('')

  const [loading, setLoading] = useState(false)
  const deliveryCode = useSelector((state) => state?.delivery?.delivery_code)
  const freeShipping = useSelector((state) => state?.delivery?.free_shipping_code)

  useEffect(async () => {
    try {
      setLoading(true)
      let userData = sessionStorage.getItem('USER_DATA')
      userData = JSON.parse(userData)
      let response = await getBeneficiaries(userData.identity)
      try {
        if (response?.data?.code !== 0) {
          setContext('Error Sura', {
            ...response?.data,
            listBeneficiaries: JSON.stringify(
              response?.data?.listBeneficiaries,
            ),
          })
          Sentry.captureMessage('Error Get Beneficiaries')
        }
      } catch (ignore) {}

      if (
        response?.message === 'OK: Success' &&
        response?.data?.listBeneficiaries?.length > 0
      ) {
        let user = response.data.listBeneficiaries.filter((beneficiary) => 
          beneficiary.identity === _formatRut(userData.identity)
        )[0]

        logEvent('Session Started', {
          'Validated Insurance Policy': 'TRUE',
          'Insurance Type': 'Group'
        })

        if (user.active === 0) {
          setErrorLoginInsurance({
            message: `Tu póliza no se encuentra vigente. Puedes contactarte con tu compañía de seguro para mayor información.`,
            goBack: true,
          })
          setShowErrorLoginInsurance(true)
        } else {
          setBenefit(response.data.listBeneficiaries)

          if (freeShipping?.code) {
            dispatch(setDeliveryCode(freeShipping))
          }
        }
      } else {
        if (deliveryCode?.code) {
          logEvent('Session Started', {
            'Validated Insurance Policy': 'TRUE',
            'Insurance Type': 'General'
          })
          nextAction()
        } else {
          logEvent('Session Started', {
            'Validated Insurance Policy': 'FALSE',
            'Insurance Type': 'External'
          })
          setErrorLoginInsurance({
            message:
              ['Sura', 'Vida Security'].includes(process.env.REACT_APP_CLIENT_NAME)?
                 'No podemos validarte como asegurado ' + process.env.REACT_APP_CLIENT_NAME.toUpperCase() + ' en estos momentos. ¿Deseas continuar sin validación?'
                : 'Tu rut no se encuentra registrado. Puedes contactarte con tu compañía de seguro para mayor información.',
            goBack: !['Sura', 'Vida Security'].includes(process.env.REACT_APP_CLIENT_NAME),
          })
          setShowErrorLoginInsurance(true)
        }
      }
    } catch (ignore) {
    } finally {
      setLoading(false)
    }
  }, [])

  function nextAction(userData, goBack = false) {
    if (goBack) {
      history.push('/')
    } else {
      if (userData) {
        let encryptedData = encrypt(userData)

        let identity = userData?.identity
        let titular_identity = userData?.holder_identity

        props.setFormData({
          ...props.formData,
          full_name: {
            ...props.formData.full_name,
            value: _capitalize(userData?.name || ''),
          },
          rut: {
            ...props.formData.rut,
            value: ![undefined, null, '', ' '].includes(identity)
              ? _validateRut(identity)
                ? _formatRut(identity)
                : null
              : null,
          },
          titular_identity: {
            ...props.formData.titular_identity,
            value: ![undefined, null, '', ' '].includes(titular_identity)
              ? _validateRut(titular_identity)
                ? _formatRut(titular_identity)
                : null
              : null,
          },
          email: {
            ...props.formData.email,
            value: ![undefined, null, '', ' '].includes(userData?.email)
              ? _validateMail(userData?.email)
                ? userData?.email?.toLowerCase()
                : null
              : null,
          },
        })

        dispatch(setCopayUserData(encryptedData))
      }
      history.push('/search')
    }
  }

  return (
    <>
      {loading &&
        (process.env.REACT_APP_CLIENT_NAME !== 'Sura' ? (
          <LoadingScreen
            loading={true}
            bgColor="#f1f1f1"
            spinnerColor="var(--color-secondary)"
            textColor="var(--color-secondary)"
            text="Buscando información"></LoadingScreen>
        ) : (
          <LoadingSura />
        ))}
      {!showErrorLoginInsurance && benefit && (
        <>
          {process.env.REACT_APP_CLIENT_NAME !== 'UC Christus' && (
            <div className="w-100 content-responsive mx-auto d-flex justify-content-between py-3 px-3 px-md-0">
              <div
                className="breadcrumbs c-pointer"
                onClick={() => {
                  history.push('/')
                }}>
                <a className="d-flex align-items-center">
                  <i className="icon-chevron-right icon-reverse"></i>
                </a>
                <span className="title-primary--regular line-14">Volver</span>
              </div>
            </div>
          )}
          <div className="flex-grow-1 pb-md-2">
            <div className="content-responsive mx-auto mb-5">
              <div className="mx-auto px-3">
                <p className="txt-3--bold-db mb-4">
                  Selecciona para quien necesitas comprar medicamentos
                </p>
                {benefit.some(
                  (c) => c.relationship.toUpperCase() === 'TITULAR',
                ) && (
                  <>
                    <p className="txt-5--bold-db mb-3">Titular</p>
                    <ButtonFalseInput
                      text={
                        benefit.filter(
                          (c) => c.relationship.toUpperCase() === 'TITULAR',
                        )[0].name
                      }
                      onClick={() =>
                        nextAction({
                          ...benefit.filter(
                            (c) => c.relationship.toUpperCase() === 'TITULAR',
                          )[0],
                          holder_identity: benefit.filter(
                            (c) => c.relationship.toUpperCase() === 'TITULAR',
                          )[0].identity_titular,
                        })
                      }
                    />
                  </>
                )}
                {benefit.some(
                  (c) => c.relationship.toUpperCase() !== 'TITULAR',
                ) && (
                  <>
                    <p className="txt-5--bold-db mb-3 mt-4">Beneficiarios</p>
                    {benefit
                      .filter((c) => c.relationship.toUpperCase() !== 'TITULAR')
                      .map((c, i) => (
                        <ButtonFalseInput
                          key={i}
                          text={c.name}
                          onClick={() =>
                            nextAction({
                              ...c,
                              holder_identity: c.identity_titular,
                            })
                          }
                        />
                      ))}
                  </>
                )}
              </div>
            </div>
            <ModalNoValidInsurance
              modalNoValidInsurance={modalNoValidInsurance}
              setModalNoValidInsurance={setModalNoValidInsurance}
            />
          </div>
        </>
      )}

      {process.env.REACT_APP_CLIENT_NAME === 'Vida Security' && (
        <TypeSidetab 
          id='jjdc5aSF'
          buttonColor={'#282E55'}          
          buttonText={'Sugerencias'}
        />
      )}
      {process.env.REACT_APP_CLIENT_NAME === 'Sura' && (
        <TypeSidetab 
          id='wea0XraP'
          buttonColor={'#0033A0'}          
          buttonText={'Sugerencias!'}
        />
      )}

      <Modal
        isOpen={showErrorLoginInsurance}
        onAfterOpen={null}
        onRequestClose={() => setShowErrorLoginInsurance(false)}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="Modal1"
        ariaHideApp={false}>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <h2 className="title-primary--3xl-regular mb-3 text-center">
            {errorLoginInsurance.message}
          </h2>
          <div className="mx-auto">
            <button
              className="btn-default w-100 btn-next mt-3"
              onClick={() => {
                setShowErrorLoginInsurance(false)
                if (errorLoginInsurance.goBack)
                  nextAction(null, errorLoginInsurance.goBack)
                else
                  window.location.replace("https://web.yapp.cl/")
              }}>
              {errorLoginInsurance.goBack ? 'Entendido' : 'Continuar'}
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formData: state.app?.formData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeLogo: (logo) => dispatch(changeLogo(logo)),
    setFormData: (formData) => dispatch(setFormData(formData)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Beneficiaries)
