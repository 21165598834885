import Modal from 'react-modal'
import {useState, forwardRef, useImperativeHandle} from 'react'
import Remover from '../../assets/img/remover.svg'

const ModalLegal = forwardRef((props, ref) => {
  const [modalIsOpen, setIsOpen] = useState(false)
  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  useImperativeHandle(ref, () => {
    return {
      openModal: openModal,
    }
  })

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={null}
      onRequestClose={closeModal}
      className="modal-custom p-0 modal-legal"
      overlayClassName="modal-custom-bg"
      contentLabel="Modal1"
      ariaHideApp={false}>
      <div className="d-flex flex-column justify-content-center align-items-center pt-5 common-top-radius">
        <div onClick={closeModal}>
          <img src={Remover} className="icon-remover" alt="Cerrar" />
        </div>
        <iframe
          title="iframe"
          className="iframe-legal"
          src={props.src}></iframe>
      </div>
    </Modal>
  )
})

export default ModalLegal
