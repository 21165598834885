import { useState, useRef, useEffect } from "react"

function Carousel({ logos, screen, controls }) {
  const [sliderActive, setSliderActive] = useState(1)
  const [isDesktop, setIsDesktop] = useState()
  const [isTablet, setIsTablet] = useState()
  const [isMobile, setIsMobile] = useState()

  useEffect(() => {
    if (screen > 768) {
      setIsDesktop(true)
      setIsTablet(false)
      setIsMobile(false)
    } else if (screen > 380 && screen <= 768) {
      setIsTablet(true)
      setIsDesktop(false)
      setIsMobile(false)
    } else {
      setIsMobile(true)
      setIsDesktop(false)
      setIsTablet(false)
    }
  }, [screen])

  const scrl = useRef()

  return (
    <div className="mb-5">
      <div
        ref={scrl}
        className="carousel-pharmacy mt-4">
        {
          isDesktop ? (
            <>
              <div className={`${sliderActive === 1 ? 'd-flex justify-content-center align-items-center' : 'd-none'}`}>
                {logos.map((pharmacy, index) => {
                  if (index <= 5) {
                    return (
                      <div className="mx-2 maxw-135">
                        <img
                          className="lazyload carousel-img" 
                          alt={pharmacy.alt} 
                          title={pharmacy.title}
                          src={pharmacy.src}
                        />
                      </div>
                    )
                  }
                })}
              </div>

              <div className={`${sliderActive === 2 ? 'd-flex justify-content-center align-items-center' : 'd-none'}`}>
                {logos.map((pharmacy, index) => {
                  if (index > 5 && index <= 11) {
                    return (
                      <div className="mx-2 maxw-135">
                        <img
                          className="lazyload carousel-img" 
                          alt={pharmacy.alt} 
                          title={pharmacy.title}
                          src={pharmacy.src}
                        />
                      </div>
                    )
                  }
                })}
              </div>

              <div className={`${sliderActive === 3 ? 'd-flex justify-content-center align-items-center' : 'd-none'}`}>
                {logos.map((pharmacy, index) => {
                  if (index > 11) {
                    return (
                      <div className="mx-2 maxw-135">
                        <img
                          className="lazyload carousel-img" 
                          alt={pharmacy.alt} 
                          title={pharmacy.title}
                          src={pharmacy.src}
                        />
                      </div>
                    )
                  }
                })}
              </div>
            </>
          ) : isTablet ? (
            <>
              <div className={`${sliderActive === 1 ? 'd-flex justify-content-center align-items-center' : 'd-none'}`}>
                {logos.map((pharmacy, index) => {
                  if (index <= 3) {
                    return (
                      <div className="mx-2 maxw-135">
                        <img
                          className="lazyload carousel-img" 
                          alt={pharmacy.alt} 
                          title={pharmacy.title}
                          src={pharmacy.src}
                        />
                      </div>
                    )
                  }
                })}
              </div>

              <div className={`${sliderActive === 2 ? 'd-flex justify-content-center align-items-center' : 'd-none'}`}>
                {logos.map((pharmacy, index) => {
                  if (index > 3 && index <= 7) {
                    return (
                      <div className="mx-2 maxw-135">
                        <img
                          className="lazyload carousel-img" 
                          alt={pharmacy.alt} 
                          title={pharmacy.title}
                          src={pharmacy.src}
                        />
                      </div>
                    )
                  }
                })}
              </div>

              <div className={`${sliderActive === 3 ? 'd-flex justify-content-center align-items-center' : 'd-none'}`}>
                {logos.map((pharmacy, index) => {
                  if (index > 7 && index <= 11) {
                    return (
                      <div className="mx-2 maxw-135">
                        <img
                          className="lazyload carousel-img" 
                          alt={pharmacy.alt} 
                          title={pharmacy.title}
                          src={pharmacy.src}
                        />
                      </div>
                    )
                  }
                })}
              </div>

              <div className={`${sliderActive === 4 ? 'd-flex justify-content-center align-items-center' : 'd-none'}`}>
                {logos.map((pharmacy, index) => {
                  if (index > 11) {
                    return (
                      <div className="mx-2 maxw-135">
                        <img
                          className="lazyload carousel-img" 
                          alt={pharmacy.alt} 
                          title={pharmacy.title}
                          src={pharmacy.src}
                        />
                      </div>
                    )
                  }
                })}
              </div>
            </>
          ) : (
            <>
              <div className={`${sliderActive === 1 ? 'd-flex justify-content-center align-items-center' : 'd-none'}`}>
                {logos.map((pharmacy, index) => {
                  if (index <= 1) {
                    return (
                      <div className="mx-2 maxw-135">
                        <img
                          className="lazyload carousel-img" 
                          alt={pharmacy.alt} 
                          title={pharmacy.title}
                          src={pharmacy.src}
                        />
                      </div>
                    )
                  }
                })}
              </div>

              <div className={`${sliderActive === 2 ? 'd-flex justify-content-center align-items-center' : 'd-none'}`}>
                {logos.map((pharmacy, index) => {
                  if (index > 1 && index <= 3) {
                    return (
                      <div className="mx-2 maxw-135">
                        <img
                          className="lazyload carousel-img" 
                          alt={pharmacy.alt} 
                          title={pharmacy.title}
                          src={pharmacy.src}
                        />
                      </div>
                    )
                  }
                })}
              </div>

              <div className={`${sliderActive === 3 ? 'd-flex justify-content-center align-items-center' : 'd-none'}`}>
                {logos.map((pharmacy, index) => {
                  if (index > 3 && index <= 5) {
                    return (
                      <div className="mx-2 maxw-135">
                        <img
                          className="lazyload carousel-img" 
                          alt={pharmacy.alt} 
                          title={pharmacy.title}
                          src={pharmacy.src}
                        />
                      </div>
                    )
                  }
                })}
              </div>

              <div className={`${sliderActive === 4 ? 'd-flex justify-content-center align-items-center' : 'd-none'}`}>
                {logos.map((pharmacy, index) => {
                  if (index > 5 && index <= 7) {
                    return (
                      <div className="mx-2 maxw-135">
                        <img
                          className="lazyload carousel-img" 
                          alt={pharmacy.alt} 
                          title={pharmacy.title}
                          src={pharmacy.src}
                        />
                      </div>
                    )
                  }
                })}
              </div>

              <div className={`${sliderActive === 5 ? 'd-flex justify-content-center align-items-center' : 'd-none'}`}>
                {logos.map((pharmacy, index) => {
                  if (index > 7 && index <= 9) {
                    return (
                      <div className="mx-2 maxw-135">
                        <img
                          className="lazyload carousel-img" 
                          alt={pharmacy.alt} 
                          title={pharmacy.title}
                          src={pharmacy.src}
                        />
                      </div>
                    )
                  }
                })}
              </div>

              <div className={`${sliderActive === 6 ? 'd-flex justify-content-center align-items-center' : 'd-none'}`}>
                {logos.map((pharmacy, index) => {
                  if (index > 9 && index <= 11) {
                    return (
                      <div className="mx-2 maxw-135">
                        <img
                          className="lazyload carousel-img" 
                          alt={pharmacy.alt} 
                          title={pharmacy.title}
                          src={pharmacy.src}
                        />
                      </div>
                    )
                  }
                })}
              </div>

              <div className={`${sliderActive === 7 ? 'd-flex justify-content-center align-items-center' : 'd-none'}`}>
                {logos.map((pharmacy, index) => {
                  if (index > 11) {
                    return (
                      <div className="mx-2 maxw-135">
                        <img
                          className="lazyload carousel-img" 
                          alt={pharmacy.alt} 
                          title={pharmacy.title}
                          src={pharmacy.src}
                        />
                      </div>
                    )
                  }
                })}
              </div>
            </>
          )
        }
      </div>

      <div className="d-flex justify-content-center mt-3">
        {
          controls === 1 ? (
            <>
              <button onClick={() => setSliderActive(1)} className={sliderActive === 1 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
            </>
          ) : controls === 2 ? (
            <>
              <button onClick={() => setSliderActive(1)} className={sliderActive === 1 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
              <button onClick={() => setSliderActive(2)} className={sliderActive === 2 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
            </>
          ) : controls === 3 ? (
            <>
              <button onClick={() => setSliderActive(1)} className={sliderActive === 1 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
              <button onClick={() => setSliderActive(2)} className={sliderActive === 2 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
              <button onClick={() => setSliderActive(3)} className={sliderActive === 3 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
            </>
          ) : controls === 4 ? (
            <>
              <button onClick={() => setSliderActive(1)} className={sliderActive === 1 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
              <button onClick={() => setSliderActive(2)} className={sliderActive === 2 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
              <button onClick={() => setSliderActive(3)} className={sliderActive === 3 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
              <button onClick={() => setSliderActive(4)} className={sliderActive === 4 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
            </>
          ) : controls === 5 ? (
            <>
              <button onClick={() => setSliderActive(1)} className={sliderActive === 1 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
              <button onClick={() => setSliderActive(2)} className={sliderActive === 2 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
              <button onClick={() => setSliderActive(3)} className={sliderActive === 3 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
              <button onClick={() => setSliderActive(4)} className={sliderActive === 4 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
              <button onClick={() => setSliderActive(5)} className={sliderActive === 5 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
            </>
          ) : controls === 6 ? (
            <>
              <button onClick={() => setSliderActive(1)} className={sliderActive === 1 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
              <button onClick={() => setSliderActive(2)} className={sliderActive === 2 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
              <button onClick={() => setSliderActive(3)} className={sliderActive === 3 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
              <button onClick={() => setSliderActive(4)} className={sliderActive === 4 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
              <button onClick={() => setSliderActive(5)} className={sliderActive === 5 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
              <button onClick={() => setSliderActive(6)} className={sliderActive === 6 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
            </>
          ) : (
            <>
              <button onClick={() => setSliderActive(1)} className={sliderActive === 1 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
              <button onClick={() => setSliderActive(2)} className={sliderActive === 2 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
              <button onClick={() => setSliderActive(3)} className={sliderActive === 3 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
              <button onClick={() => setSliderActive(4)} className={sliderActive === 4 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
              <button onClick={() => setSliderActive(5)} className={sliderActive === 5 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
              <button onClick={() => setSliderActive(6)} className={sliderActive === 6 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
              <button onClick={() => setSliderActive(7)} className={sliderActive === 7 ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}></button>
            </>
          ) 
        }
      </div>
    </div>
  )
}

export default Carousel