import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { fetchUser } from '../../redux/modules/user'
import { loginCustomToken } from '../../Utils/auth'

function LoginToken() {
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    loginByToken()
  },[params?.fcmToken])

  const loginByToken = async () => {
    localStorage.setItem('fromYappApp', navigator.userAgent.includes('YappApp'))

    if (![null, undefined, ''].includes(params?.fcmToken)) {
      let login = await loginCustomToken(params.fcmToken)
      if (login?.status === 'success') {
        dispatch(fetchUser())
        history.push('/pro/menu')
      }
      else {
        history.push('/login')
      }
    }
  }

  return (
    <div style={{ paddingTop: '30px', textAlign: 'center' }}>
      Espera un momento mientras cargamos tu contenido...
    </div>
  )
}

export default LoginToken
