import {MenuProCard} from '../../Utils/constants'
import {
  _validateMail,
  _validPhone,
  _validateRut,
  _formatRut,
  getLocationTool,
} from '../../Utils/tools'
const _get = require('lodash/get')
export const SET_LOGO = 'SET_LOGO'
export const SHOW_LOGO = 'SHOW_LOGO'
export const SET_CONFIG = 'SET_CONFIG'
export const NOT_FOUND = 'NOT_FOUND'
export const SET_CLIENT_NAME = 'SET_CLIENT_NAME'
export const SET_LOCATION = 'SET_LOCATION'
export const SET_COMMUNE = 'SET_COMMUNE'
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS'
export const SET_THEME_COLOR = 'SET_THEME_COLOR'
export const SET_IS_PRO = 'SET_IS_PRO'
export const SET_RECEMED_MODAL = 'SET_RECEMED_MODAL'
export const SET_SUBSCRIPTION_PRO_PRODUCT = 'SET_SUBSCRIPTION_PRO_PRODUCT'
export const SET_ONE_PHARMACY = 'SET_ONE_PHARMACY'
export const SET_REFUND_ELEMENT = 'SET_REFUND_ELEMENT'
export const SET_CHARGED_AFTER_CONFIRM = 'SET_CHARGED_AFTER_CONFIRM '
export const SET_TEXT_EXTRA_INFO_SEARCH = 'SET_TEXT_EXTRA_INFO_SEARCH'
export const SET_TEXT_EXTRA_HOME = 'SET_TEXT_EXTRA_HOME'
export const SET_TEXT_EXTRA_PRESCRIPTION = 'SET_TEXT_EXTRA_PRESCRIPTION'
export const SET_TEXT_SUBSCRIPTION_SECTION = 'SET_TEXT_SUBSCRIPTION_SECTION'
export const SET_TEXT_SUBSCRIPTION_TITLE = 'SET_TEXT_SUBSCRIPTION_TITLE'
export const SET_TEXT_SUBSCRIPTION_CONTENT1 = 'SET_TEXT_SUBSCRIPTION_CONTENT1'
export const SET_TEXT_SUBSCRIPTION_CONTENT2 = 'SET_TEXT_SUBSCRIPTION_CONTENT2'
export const SET_TEXT_SUBSCRIPTION_CONTENT3 = 'SET_TEXT_SUBSCRIPTION_CONTENT3'
export const SET_TEXT_SUBSCRIPTION_CONTENT4 = 'SET_TEXT_SUBSCRIPTION_CONTENT4'
export const SET_TEXT_SUBSCRIPTION_PRICE1 = 'SET_TEXT_SUBSCRIPTION_PRICE1'
export const SET_TEXT_SUBSCRIPTION_PRICE2 = 'SET_TEXT_SUBSCRIPTION_PRICE2'
export const SET_TEXT_SUBSCRIPTION_PRICE3 = 'SET_TEXT_SUBSCRIPTION_PRICE3'
export const SET_TEXT_SUBSCRIPTION_PRICE4 = 'SET_TEXT_SUBSCRIPTION_PRICE4'
export const SET_TEXT_SUBSCRIPTION_DETAIL1 = 'SET_TEXT_SUBSCRIPTION_DETAIL1'
export const SET_TEXT_SUBSCRIPTION_DETAIL2 = 'SET_TEXT_SUBSCRIPTION_DETAIL2'
export const SET_TEXT_SUBSCRIPTION_DETAIL3 = 'SET_TEXT_SUBSCRIPTION_DETAIL3'
export const SET_TEXT_SUBSCRIPTION_DETAIL4 = 'SET_TEXT_SUBSCRIPTION_DETAIL4'
export const SET_TEXT_SUBSCRIPTION_CHECKOUT = 'SET_TEXT_SUBSCRIPTION_CHECKOUT'
export const SET_SIGN_IN = 'SET_SIGN_IN'
export const SET_HASH = 'SET_HASH'
export const CLEAR_APP_STATE = 'CLEAR_APP_STATE'
export const SHOW_AVATAR = 'SHOW_AVATAR'
export const SET_VIDA_CAMARA_LOADER = 'SET_VIDA_CAMARA_LOADER'
export const SET_SURA_LOADER = 'SET_SURA_LOADER'
export const SET_MENU = 'SET_MENU'
export const SET_PROVIDER = 'SET_PROVIDER'
export const SET_FORM_DATA = 'SET_FORM_DATA'
export const SET_MODAL_TYPEFORM = 'SET_MODAL_TYPEFORM'
export const SET_TYPEFORM_URL = 'SET_TYPEFORM_URL'
export const SET_OPEN_MENU = 'SET_OPEN_MENU'
export const SET_LAST_PAGE = 'SET_LAST_PAGE'
export const SET_HEADER_NAME = 'SET_HEADER_NAME'
export const SET_PRESCRIPTIONS = 'SET_PRESCRIPTIONS'
export const SET_APPLICATION_NUMBER = 'SET_APPLICATION_NUMBER'
export const SET_GO_ROUTER = 'SET_GO_ROUTER'
export const SET_HOLIDAY_LIST = 'SET_HOLIDAY_LIST'
export const SET_INSURANCE_LIST = 'SET_INSURANCE_LIST'
export const SET_ENABLED_INSURANCE = 'SET_ENABLED_INSURANCE'
export const SET_LOGIN_CHECKOUT_LOADING = 'SET_LOGIN_CHECKOUT_LOADING'

const fileType = {
  pdf: 'application/pdf',
  jpeg: 'image/jpeg',
  png: 'image/png',
  jpg: 'image/jpg',
}

const initialState = {
  initial_loading: false,
  login_checkout_loading: false,
  logo: 'https://web.yapp.cl/static/media/logo_yapp.f860d0c5.svg',
  show_logo: false,
  client_name: 'Yapp Chile',
  config: {},
  location: null,
  not_found: false,
  themeColor: {},
  commune: null,
  isPro: false,
  recemedModal: {
    title: 'Prescripción electrónica',
    logo: 'https://s3.us-east-2.amazonaws.com/s3.yapp/img/client/logo_recemed.png',
    content1:
      'Descubre esta nueva herramienta que te permitirá apoyar a tus pacientes a través de una prescripción inteligente e informada.',
    content2:
      'Podrás tomar las mejores decisiones antes de prescribir recetas simples, retenidas y magistrales, obteniendo información en línea y actualizada de todos los medicamentos de la industria, sus bioequivalentes, disponibilidad en farmacias y ¡mucho más!',
    content3:
      'Pasos: Hacer click en botón de “Ir a Recemed” > crear una cuenta > validarte como médico.',
    button: 'Ir a Recemed',
    link: 'https://sistema.recemed.cl/registro/medico',
  },
  subscriptionProProduct: {
    title: 'Convenio Yapp',
    summaryTitle: '¡Ayuda a tus pacientes a mantenerse adheridos!',
    summaryText:
      'Con Convenio Yapp iniciarán su tratamiento al mejor costo y además recibirán su medicamento a domicilio por la duración completa de su tratamiento.',
    discountSubText: 'Aplica sobre el precio más bajo disponible en Yapp',
    content: `Comparte este código con tus pacientes para que obtengan el descuento en este tratamiento, <b>canjeable desde</b> nuestra plataforma <b>web y app.</b>`,
    contentBold: ``,
    label: 'Tu código',
    btnText: 'Compartir código con pacientes',
  },
  one_pharmacy: false,
  modalTypeForm: false,
  typeFormUrl: null,
  text_extra_info_search: null,
  text_extra_prescription: null,
  text_subscription_section:
    'Programa tu tratamiento y ahorra con Convenio Yapp',
  text_subscription_title: 'Convenio Yapp',
  text_subscription_content1:
    '¡Recibe este medicamento todos los meses, disfruta del descuento por cada compra que realices y descansa!',
  text_subscription_content2:
    '¡Recibe este medicamento todos los meses, disfruta del descuento por cada compra que realices y descansa!',
  text_subscription_content3:
    '¡Recibe este medicamento todos los meses al mejor precio que Convenio Yapp tiene para ti y descansa!',
  text_subscription_content4:
    '¡Recibe este medicamento todos los meses al mejor precio que Convenio Yapp tiene para ti! Si tu médico te entregó un código de descuento, aplícalo al final de tu compra.',
  text_subscription_price1: 'Precio especial desde',
  text_subscription_price2: 'Precio especial desde',
  text_subscription_price3: 'Precio especial',
  text_subscription_price4: 'Precio especial',
  text_subscription_detail1: 'Plan de pago',
  text_subscription_detail2: 'Plan de pago',
  text_subscription_detail3: 'Precio por caja',
  text_subscription_detail4: 'Precio por caja',
  text_subscription_checkout: 'Programa tu Convenio Yapp',
  charged_after_confirm: 'Ir a pagar',
  sign_in: false,
  hash: null,
  showAvatar: false,
  vida_camara_loader: false,
  sura_loader: false,
  menu: [...MenuProCard],
  provider: 'email',
  formData: {
    location_name: {
      value: '',
      valid: true,
    },
    location_number: {
      value: '',
      valid: true,
    },
    address_additional: {
      value: '',
      valid: true,
    },
    commune: {
      value: '',
      valid: true,
    },
    delivery_time: {
      value: '',
      valid: true,
    },
    phone: {
      value: '',
      valid: true,
    },
    email: {
      value: '',
      valid: true,
    },
    full_name: {
      value: '',
      valid: true,
    },
    rut: {
      value: '',
      valid: true,
    },
    checkTerms: {
      value: false,
      valid: true,
    },
  },
  openMenu: false,
  lastPage: null,
  headerName: '',
  prescriptions: [],
  appCart: [],
  applicationNumber: null,
  goRouter: [],
  holidayList: [],
  insuranceList: [],
  enabledInsurance: false,
}
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_STATUS:
      return {
        ...state,
        initial_loading: action.status,
      }
    case SET_LOGIN_CHECKOUT_LOADING:
      return {
        ...state,
        login_checkout_loading: action.status
      }
    case SHOW_LOGO:
      return {
        ...state,
        show_logo: action.status,
      }
    case SET_CONFIG:
      return {
        ...state,
        config: action.config,
      }
    case SET_CLIENT_NAME:
      return {
        ...state,
        client_name: action.clientName,
      }
    case SET_LOGO:
      action.logo = ![undefined, null].includes(action.logo)
        ? action.logo
        : initialState.logo
      return {
        ...state,
        logo: action.logo,
      }
    case SET_LOCATION:
      return {
        ...state,
        location: action.location,
      }
    case SET_THEME_COLOR:
      return {
        ...state,
        themeColor: action.themeColor,
      }
    case SET_IS_PRO:
      return {
        ...state,
        isPro: action.isPro,
      }
    case SET_RECEMED_MODAL:
      return {
        ...state,
        recemedModal: action.recemedModal,
      }
    case SET_SUBSCRIPTION_PRO_PRODUCT:
      return {
        ...state,
        subscriptionProProduct: action.subscriptionProProduct,
      }
    case SET_COMMUNE:
      return {
        ...state,
        commune: action.commune,
      }
    case SET_ONE_PHARMACY:
      return {
        ...state,
        one_pharmacy: action.one_pharmacy,
      }
    case SET_REFUND_ELEMENT:
      return {
        ...state,
        refund_element: action.refund_element,
      }
    case SET_TEXT_EXTRA_INFO_SEARCH:
      return {
        ...state,
        text_extra_info_search: action.text_extra_info_search,
      }
    case SET_TEXT_EXTRA_HOME:
      return {
        ...state,
        text_extra_home: action.text_extra_home,
      }
    case SET_CHARGED_AFTER_CONFIRM:
      return {
        ...state,
        charged_after_confirm: action.charged_after_confirm,
      }
    case SET_TEXT_EXTRA_PRESCRIPTION:
      return {
        ...state,
        text_extra_prescription: action.text_extra_prescription,
      }
    case SET_TEXT_SUBSCRIPTION_SECTION:
      return {
        ...state,
        text_subscription_section: action.text_subscription_section,
      }
    case SET_TEXT_SUBSCRIPTION_TITLE:
      return {
        ...state,
        text_subscription_title: action.text_subscription_title,
      }
    case SET_TEXT_SUBSCRIPTION_CONTENT1:
      return {
        ...state,
        text_subscription_content1: action.text_subscription_content1,
      }
    case SET_TEXT_SUBSCRIPTION_CONTENT2:
      return {
        ...state,
        text_subscription_content2: action.text_subscription_content2,
      }
    case SET_TEXT_SUBSCRIPTION_CONTENT3:
      return {
        ...state,
        text_subscription_content3: action.text_subscription_content3,
      }
    case SET_TEXT_SUBSCRIPTION_CONTENT4:
      return {
        ...state,
        text_subscription_content4: action.text_subscription_content4,
      }
    case SET_TEXT_SUBSCRIPTION_PRICE1:
      return {
        ...state,
        text_subscription_price1: action.text_subscription_price1,
      }
    case SET_TEXT_SUBSCRIPTION_PRICE2:
      return {
        ...state,
        text_subscription_price2: action.text_subscription_price2,
      }
    case SET_TEXT_SUBSCRIPTION_PRICE3:
      return {
        ...state,
        text_subscription_price3: action.text_subscription_price3,
      }
    case SET_TEXT_SUBSCRIPTION_PRICE4:
      return {
        ...state,
        text_subscription_price4: action.text_subscription_price4,
      }
    case SET_TEXT_SUBSCRIPTION_DETAIL1:
      return {
        ...state,
        text_subscription_detail1: action.text_subscription_detail1,
      }
    case SET_TEXT_SUBSCRIPTION_DETAIL2:
      return {
        ...state,
        text_subscription_detail2: action.text_subscription_detail2,
      }
    case SET_TEXT_SUBSCRIPTION_DETAIL3:
      return {
        ...state,
        text_subscription_detail3: action.text_subscription_detail3,
      }
    case SET_TEXT_SUBSCRIPTION_DETAIL4:
      return {
        ...state,
        text_subscription_detail4: action.text_subscription_detail4,
      }
    case SET_TEXT_SUBSCRIPTION_CHECKOUT:
      return {
        ...state,
        text_subscription_checkout: action.text_subscription_checkout,
      }
    case SET_MODAL_TYPEFORM:
      return {
        ...state,
        modalTypeForm: action.value,
      }
    case SET_TYPEFORM_URL:
      return {
        ...state,
        typeFormUrl: action.value,
      }
    case SET_OPEN_MENU:
      return {
        ...state,
        openMenu: action.value,
      }
    case SET_SIGN_IN: {
      return {
        ...state,
        sign_in: action.signIn,
      }
    }
    case SET_HASH: {
      return {
        ...state,
        hash: action.hash,
      }
    }
    case NOT_FOUND:
      return {
        ...state,
        not_found: action.not_found,
      }
    case SET_VIDA_CAMARA_LOADER:
      return {
        ...state,
        vida_camara_loader: action.vida_camara_loader,
      }
    case SET_SURA_LOADER:
      return {
        ...state,
        sura_loader: action.sura_loader,
      }
    case SET_MENU:
      return {
        ...state,
        menu: [...action.menu],
      }
    case SET_PROVIDER:
      return {
        ...state,
        provider: action.value,
      }
    case SET_FORM_DATA:
      return {
        ...state,
        formData: action.value,
      }
    case SET_HEADER_NAME:
      return {
        ...state,
        headerName: action.value,
      }
    case SET_PRESCRIPTIONS:
      return {
        ...state,
        prescriptions: action.value,
      }
    case SET_APPLICATION_NUMBER:
      return {
        ...state,
        applicationNumber: action.value,
      }
    case CLEAR_APP_STATE:
      return {
        initial_loading: false,
        logo: 'https://web.yapp.cl/static/media/logo_yapp.f860d0c5.svg',
        show_logo: false,
        SET_CONFIG: false,
        location: null,
        not_found: false,
        themeColor: {},
        commune: null,
        client_name: 'Yapp Chile',
        isPro: false,
        recemedModal: {
          title: 'Prescripción electrónica',
          logo: 'https://s3.us-east-2.amazonaws.com/s3.yapp/img/client/logo_recemed.png',
          content1:
            'Descubre esta nueva herramienta que te permitirá apoyar a tus pacientes a través de una prescripción inteligente e informada.',
          content2:
            'Podrás tomar las mejores decisiones antes de prescribir recetas simples, retenidas y magistrales, obteniendo información en línea y actualizada de todos los medicamentos de la industria, sus bioequivalentes, disponibilidad en farmacias y ¡mucho más!',
          content3:
            'Pasos: Hacer click en botón de “Ir a Recemed” > crear una cuenta > validarte como médico.',
          button: 'Ir a Recemed',
          link: 'https://sistema.recemed.cl/registro/medico',
        },
        subscriptionProProduct: {
          title: 'Convenio Yapp',
          summaryTitle: '¡Ayuda a tus pacientes a mantenerse adheridos!',
          summaryText:
            'Con Convenio Yapp iniciarán su tratamiento al mejor costo y además recibirán su medicamento a domicilio por la duración completa de su tratamiento.',
          discountSubText: 'Aplica sobre el precio más bajo disponible en Yapp',
          content: `Comparte este código con tus pacientes para que obtengan el descuento en este tratamiento, <b>canjeable desde</b> nuestra plataforma <b>web y app.</b>`,
          contentBold: ``,
          label: 'Tu código',
          btnText: 'Compartir código con pacientes',
        },
        one_pharmacy: false,
        refund_element: false,
        text_extra_info_search: null,
        text_extra_prescription: null,
        text_subscription_section:
          'Programa tu tratamiento y ahorra con Convenio Yapp',
        text_subscription_title: 'Convenio Yapp',
        text_subscription_content1:
          '¡Recibe este medicamento todos los meses, disfruta del descuento por cada compra que realices y descansa!',
        text_subscription_content2:
          '¡Recibe este medicamento todos los meses, disfruta del descuento por cada compra que realices y descansa!',
        text_subscription_content3:
          '¡Recibe este medicamento todos los meses al mejor precio que Convenio Yapp tiene para ti y descansa!',
        text_subscription_content4:
          '¡Recibe este medicamento todos los meses al mejor precio que Convenio Yapp tiene para ti! Si tu médico te entregó un código de descuento, aplícalo al final de tu compra.',
        text_subscription_price1: 'Precio especial desde',
        text_subscription_price2: 'Precio especial desde',
        text_subscription_price3: 'Precio especial',
        text_subscription_price4: 'Precio especial',
        text_subscription_detail1: 'Plan de pago',
        text_subscription_detail2: 'Plan de pago',
        text_subscription_detail3: 'Precio por caja',
        text_subscription_detail4: 'Precio por caja',
        text_subscription_checkout: 'Programa tu Convenio Yapp',
        charged_after_confirm: 'Ir a pagar',
        sign_in: false,
        vida_camara_loader: false,
        sura_loader: false,
        menu: [...MenuProCard],
        provider: 'email',
        formData: {
          location_name: {
            value: '',
            valid: true,
          },
          location_number: {
            value: '',
            valid: true,
          },
          address_additional: {
            value: '',
            valid: true,
          },
          commune: {
            value: '',
            valid: true,
          },
          delivery_time: {
            value: '',
            valid: true,
          },
          phone: {
            value: '',
            valid: true,
          },
          email: {
            value: '',
            valid: true,
          },
          full_name: {
            value: '',
            valid: true,
          },
          rut: {
            value: '',
            valid: true,
          },
          titular_identity: {
            value: '',
            valid: true,
          },
          checkTerms: {
            value: false,
            valid: true,
          },
        },
        goRouter: [],
        enabledInsurance: false,
      }
    case SHOW_AVATAR:
      return {
        ...state,
        show_avatar: action.state,
      }
    case SET_LAST_PAGE:
      return {
        ...state,
        lastPage: action.value,
      }
    case SET_GO_ROUTER:
      return {
        ...state,
        goRouter: action.value,
      }
    case SET_HOLIDAY_LIST:
      return {
        ...state,
        holidayList: action.value,
      }
    case SET_INSURANCE_LIST:
      return {
        ...state,
        insuranceList: action.value,
      }
    case SET_ENABLED_INSURANCE:
      return {
        ...state,
        enabledInsurance: action.value,
      }
    default:
      return state
  }
}
export function changeInitialLoading(status) {
  return {
    type: SET_LOADING_STATUS,
    status,
  }
}
export function changeLoginCheckoutLoading(status) {
  return {
    type: SET_LOGIN_CHECKOUT_LOADING,
    status,
  }
}
export function changeLogo(logo) {
  return {
    type: SET_LOGO,
    logo,
  }
}
export function setConfig(config) {
  return {
    type: SET_CONFIG,
    config,
  }
}
export function showLogo(status) {
  return {
    type: SHOW_LOGO,
    status,
  }
}
export function setClientName(clientName) {
  return {
    type: SET_CLIENT_NAME,
    clientName,
  }
}
export function setLocation(location) {
  return {
    type: SET_LOCATION,
    location,
  }
}
export function setThemeColor(themeColor) {
  return {
    type: SET_THEME_COLOR,
    themeColor,
  }
}
export function setOnePharmacy(one_pharmacy) {
  return {
    type: SET_ONE_PHARMACY,
    one_pharmacy,
  }
}
export function setRefundElement(refund_element) {
  return {
    type: SET_REFUND_ELEMENT,
    refund_element,
  }
}
export function setTextExtraInfoSearch(text_extra_info_search) {
  return {
    type: SET_TEXT_EXTRA_INFO_SEARCH,
    text_extra_info_search,
  }
}
export function setTextExtraPrescription(text_extra_prescription) {
  return {
    type: SET_TEXT_EXTRA_PRESCRIPTION,
    text_extra_prescription,
  }
}
export function setTextSubscriptionSection(text_subscription_section) {
  return {
    type: SET_TEXT_SUBSCRIPTION_SECTION,
    text_subscription_section,
  }
}
export function setTextSubscriptionTitle(text_subscription_title) {
  return {
    type: SET_TEXT_SUBSCRIPTION_TITLE,
    text_subscription_title,
  }
}
export function setTextSubscriptionContent1(text_subscription_content1) {
  return {
    type: SET_TEXT_SUBSCRIPTION_CONTENT1,
    text_subscription_content1,
  }
}
export function setTextSubscriptionContent2(text_subscription_content2) {
  return {
    type: SET_TEXT_SUBSCRIPTION_CONTENT2,
    text_subscription_content2,
  }
}
export function setTextSubscriptionContent3(text_subscription_content3) {
  return {
    type: SET_TEXT_SUBSCRIPTION_CONTENT3,
    text_subscription_content3,
  }
}
export function setTextSubscriptionContent4(text_subscription_content4) {
  return {
    type: SET_TEXT_SUBSCRIPTION_CONTENT4,
    text_subscription_content4,
  }
}
export function setTextSubscriptionPrice1(text_subscription_price1) {
  return {
    type: SET_TEXT_SUBSCRIPTION_PRICE1,
    text_subscription_price1,
  }
}
export function setTextSubscriptionPrice2(text_subscription_price2) {
  return {
    type: SET_TEXT_SUBSCRIPTION_PRICE2,
    text_subscription_price2,
  }
}
export function setTextSubscriptionPrice3(text_subscription_price3) {
  return {
    type: SET_TEXT_SUBSCRIPTION_PRICE3,
    text_subscription_price3,
  }
}
export function setTextSubscriptionPrice4(text_subscription_price4) {
  return {
    type: SET_TEXT_SUBSCRIPTION_PRICE4,
    text_subscription_price4,
  }
}
export function setTextSubscriptionDetail1(text_subscription_detail1) {
  return {
    type: SET_TEXT_SUBSCRIPTION_DETAIL1,
    text_subscription_detail1,
  }
}
export function setTextSubscriptionDetail2(text_subscription_detail2) {
  return {
    type: SET_TEXT_SUBSCRIPTION_DETAIL2,
    text_subscription_detail2,
  }
}
export function setTextSubscriptionDetail3(text_subscription_detail3) {
  return {
    type: SET_TEXT_SUBSCRIPTION_DETAIL3,
    text_subscription_detail3,
  }
}
export function setTextSubscriptionDetail4(text_subscription_detail4) {
  return {
    type: SET_TEXT_SUBSCRIPTION_DETAIL4,
    text_subscription_detail4,
  }
}
export function setTextSubscriptionCheckout(text_subscription_checkout) {
  return {
    type: SET_TEXT_SUBSCRIPTION_CHECKOUT,
    text_subscription_checkout,
  }
}
export function setTextExtraHome(text_extra_home) {
  return {
    type: SET_TEXT_EXTRA_HOME,
    text_extra_home,
  }
}
export function setChargedAfterConfirm(charged_after_confirm) {
  return {
    type: SET_CHARGED_AFTER_CONFIRM,
    charged_after_confirm,
  }
}
export function setIsPro(isPro) {
  return {
    type: SET_IS_PRO,
    isPro,
  }
}
export function setRecemedModal(recemedModal) {
  return {
    type: SET_RECEMED_MODAL,
    recemedModal,
  }
}
export function setSubscriptionProProduct(subscriptionProProduct) {
  return {
    type: SET_SUBSCRIPTION_PRO_PRODUCT,
    subscriptionProProduct,
  }
}
export function changeNotFound(not_found) {
  return {
    type: NOT_FOUND,
    not_found,
  }
}
export function setCommune(commune) {
  return {
    type: SET_COMMUNE,
    commune,
  }
}
export function setSignIn(value) {
  return {
    type: SET_SIGN_IN,
    signIn: value,
  }
}
export function setHash(hash) {
  return {
    type: SET_HASH,
    hash,
  }
}
export function setVidaCamaraLoader(vida_camara_loader) {
  return {
    type: SET_VIDA_CAMARA_LOADER,
    vida_camara_loader,
  }
}
export function setSuraLoader(sura_loader) {
  return {
    type: SET_SURA_LOADER,
    sura_loader,
  }
}
export function clearAppState() {
  return {
    type: CLEAR_APP_STATE,
  }
}
export function setShowAvatar(state) {
  return {
    type: SHOW_AVATAR,
    state,
  }
}
export function setMenu(value) {
  return {
    type: SET_MENU,
    menu: value,
  }
}
export function setProvider(value) {
  return {
    type: SET_PROVIDER,
    value,
  }
}
export function setFormData(value) {
  return {
    type: SET_FORM_DATA,
    value,
  }
}
export function setFormDataAccount() {
  return async (dispatch) => {
    try {
      let currentUser = localStorage.getItem('login_temp_data')
      if (currentUser) {
        currentUser = JSON.parse(currentUser)
        if (currentUser?.signInMethod !== 'anonymously') {
          let form = {
            ...initialState.formData,
          }
          if (currentUser?.profile?.name) {
            form.full_name = {
              ...initialState.formData.full_name,
              value: ![undefined, null, ''].includes(
                initialState.formData.full_name.value,
              )
                ? initialState.formData.full_name.value
                : currentUser?.profile?.name,
            }
          }
          if (currentUser?.profile?.email) {
            form.email = {
              ...initialState.formData.email,
              value: ![undefined, null, ''].includes(
                initialState.formData.email.value,
              )
                ? initialState.formData.email.value
                : currentUser?.profile?.email,
            }
          }
          dispatch(setFormData(form))
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
}
export function changeConfig(res) {
  return async (dispatch) => {
    try {
      const themeColor =
        res?.data?.config[0]?.config?.custom_styles?.theme_color
      dispatch(showLogo(process.env.REACT_APP_SHOW_LOGO === 'TRUE'))
      dispatch(changeLogo(process.env.REACT_APP_LOGO))
      dispatch(
        setTextSubscriptionSection(
          _get(res?.data, 'config[0].config.text_subscription_section'),
          '',
        ),
      )
      dispatch(
        setTextSubscriptionTitle(
          _get(res?.data, 'config[0].config.text_subscription_title'),
          '',
        ),
      )
      dispatch(
        setTextSubscriptionContent1(
          _get(res?.data, 'config[0].config.text_subscription_content1'),
          '',
        ),
      )
      dispatch(
        setTextSubscriptionContent2(
          _get(res?.data, 'config[0].config.text_subscription_content2'),
          '',
        ),
      )
      dispatch(
        setTextSubscriptionContent3(
          _get(res?.data, 'config[0].config.text_subscription_content3'),
          '',
        ),
      )
      dispatch(
        setTextSubscriptionContent4(
          _get(res?.data, 'config[0].config.text_subscription_content4'),
          '',
        ),
      )
      dispatch(
        setTextSubscriptionPrice1(
          _get(res?.data, 'config[0].config.text_subscription_price1'),
          '',
        ),
      )
      dispatch(
        setTextSubscriptionPrice2(
          _get(res?.data, 'config[0].config.text_subscription_price2'),
          '',
        ),
      )
      dispatch(
        setTextSubscriptionPrice3(
          _get(res?.data, 'config[0].config.text_subscription_price3'),
          '',
        ),
      )
      dispatch(
        setTextSubscriptionPrice4(
          _get(res?.data, 'config[0].config.text_subscription_price4'),
          '',
        ),
      )
      dispatch(
        setTextSubscriptionDetail1(
          _get(res?.data, 'config[0].config.text_subscription_detail1'),
          '',
        ),
      )
      dispatch(
        setTextSubscriptionDetail2(
          _get(res?.data, 'config[0].config.text_subscription_detail2'),
          '',
        ),
      )
      dispatch(
        setTextSubscriptionDetail3(
          _get(res?.data, 'config[0].config.text_subscription_detail3'),
          '',
        ),
      )
      dispatch(
        setTextSubscriptionDetail4(
          _get(res?.data, 'config[0].config.text_subscription_detail4'),
          '',
        ),
      )
      dispatch(
        setTextSubscriptionCheckout(
          _get(res?.data, 'config[0].config.text_subscription_checkout'),
          '',
        ),
      )
      dispatch(
        setSubscriptionProProduct(
          _get(res?.data, 'config[0].config.subscription_pro_product'),
          {},
        ),
      )
      dispatch(
        setRecemedModal(_get(res?.data, 'config[0].config.recemed_modal'), {}),
      )
      dispatch(setConfig(_get(res?.data, 'config[0].config', {})))
      dispatch(changeInitialLoading(false))
      dispatch(setShowAvatar(true))
      dispatch(setClientName(_get(res?.data, 'config[0].client_name')))
      dispatch(
        setOnePharmacy(_get(res?.data, 'config[0].config.one_pharmacy'), false),
      )
      dispatch(
        setRefundElement(
          _get(res?.data, 'config[0].config.refund_element'),
          false,
        ),
      )
      dispatch(
        setTextExtraInfoSearch(
          _get(res?.data, 'config[0].config.text_extra_info_search'),
          false,
        ),
      )
      dispatch(
        setTextExtraPrescription(
          _get(res?.data, 'config[0].config.text_extra_prescription'),
          false,
        ),
      )
      dispatch(
        setTextExtraHome(
          _get(res?.data, 'config[0].config.text_extra_home'),
          false,
        ),
      )
      dispatch(
        setChargedAfterConfirm(
          _get(res?.data, 'config[0].config.charged_after_confirm'),
          false,
        ),
      )
      dispatch(
        setVidaCamaraLoader(
          _get(res?.data, 'config[0].config.vida_camara_loader'),
          false,
        ),
      )
      dispatch(
        setSuraLoader(process.env.REACT_APP_SURA_LOADER === 'TRUE'),
      )
      if (res?.data?.info) {
        let info = res?.data?.info
        if (info?.patient) {
          try {
            let form = {
              ...initialState.formData,
            }
            if (info?.patient?.name) {
              form.full_name = {
                ...initialState.formData.full_name,
                value: ![undefined, null, ''].includes(
                  initialState.formData.full_name.value,
                )
                  ? initialState.formData.full_name.value
                  : info?.patient?.name,
              }
            }
            if (_validateMail(info?.patient?.email)) {
              form.email = {
                ...initialState.formData.email,
                value: ![undefined, null, ''].includes(
                  initialState.formData.email.value,
                )
                  ? initialState.formData.email.value
                  : info?.patient?.email,
              }
            }
            if (info?.patient?.phone) {
              let patientPhone = info.patient.phone.replace('+56', '')
              if (_validPhone(patientPhone)) {
                form.phone = {
                  ...initialState.formData.phone,
                  value: ![undefined, null, ''].includes(
                    initialState.formData.phone.value,
                  )
                    ? initialState.formData.phone.value
                    : patientPhone,
                }
              }
            }
            if (_validateRut(info?.patient?.identity)) {
              form.rut = {
                ...initialState.formData.rut,
                value: ![undefined, null, ''].includes(
                  initialState.formData.rut.value,
                )
                  ? initialState.formData.rut.value
                  : _formatRut(info?.patient?.identity),
              }
            }
            if (
              ![undefined, null, ''].includes(
                info?.patient?.address?.description,
              )
            ) {
              let location = await getLocationTool(
                info?.patient?.address?.description,
              )
              if (location) {
                if (location?.label) {
                  form.location_name = {
                    ...initialState.formData.location_name,
                    value: ![undefined, null, ''].includes(
                      initialState.formData.location_name.value,
                    )
                      ? initialState.formData.location_name.value
                      : location?.label,
                  }
                }
                if (location?.nro) {
                  form.location_number = {
                    ...initialState.formData.location_number,
                    value: ![undefined, null, ''].includes(
                      initialState.formData.location_number.value,
                    )
                      ? initialState.formData.location_number.value
                      : location?.nro,
                  }
                }
                if (location?.locality) {
                  form.commune = {
                    ...initialState.formData.commune,
                    value: ![undefined, null, ''].includes(
                      initialState.formData.commune.value,
                    )
                      ? initialState.formData.commune.value
                      : location?.locality,
                  }
                }
                if (location?.label) {
                  let address = {
                    ...location,
                    commune_id: null,
                  }
                  dispatch(setLocation(address))
                }
              }
            }
            dispatch(setFormData(form))
          } catch (error) {
            console.log(error)
          }
        }
        if (info?.products) {
          let prescription_global = info?.prescription_url
          let prescriptions = []

          for (const key in info?.products) {
            let product = info?.products[key]
            let url = product?.prescription_url || prescription_global
            if (url) {
              let name = url.split('/')

              prescriptions.push({
                product_id: product.id,
                url: url,
                file_name: name.pop(),
                external: true,
              })
            }
          }

          if (prescriptions.length > 0) {
            dispatch(setPrescriptions(prescriptions))
          }
        }
      }
      if (themeColor) {
        dispatch(setThemeColor(themeColor))
      }
    } catch (error) {
      console.log(error)
    }
  }
}
export const setModalTypeFormsState = (value) => {
  return {type: SET_MODAL_TYPEFORM, value}
}
export const setTypeFormUrl = (value) => {
  return {type: SET_TYPEFORM_URL, value}
}
export const setOpenMenu = (value) => {
  return {type: SET_OPEN_MENU, value}
}
export const setLastPage = (value) => {
  return {type: SET_LAST_PAGE, value}
}
export const setHeaderName = (value) => {
  return {type: SET_HEADER_NAME, value}
}
export const setPrescriptions = (value) => {
  return {type: SET_PRESCRIPTIONS, value}
}
export const setApplicationNumber = (value) => {
  return {type: SET_APPLICATION_NUMBER, value}
}
export const setGoRouter = (value) => {
  return {type: SET_GO_ROUTER, value}
}
export const setHolidayList = (value) => {
  return {type: SET_HOLIDAY_LIST, value}
}
export const setInsuranceList = (value) => {
  return {type: SET_INSURANCE_LIST, value}
}
export const setEnabledInsurance = (value) => {
  return {type: SET_ENABLED_INSURANCE, value}
}
