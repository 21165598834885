import {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import Logo from '../assets/img/logo_yapp.svg'
import {connect} from 'react-redux'
import Zendesk from 'react-zendesk'
import {logEvent} from '../Utils/utils'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FooterUC from './Theme/FooterUC'

function Footer(props) {
  const history = useHistory()
  const isPro = useSelector((state) => state.user.pro)
  const isProHash = useSelector((state) => state.app.isPro)
  const hash = useSelector((state) => state.app.hash)
  const faq = process.env.REACT_APP_FAQ
  const proPath = history.location.pathname
  const isProPath = proPath.includes(
    '/pro/home/' + (hash || process.env.REACT_APP_UNIQUE_HASH),
  )
  const zendeskKey = [undefined, null, 'FALSE'].includes(
    process.env.REACT_APP_ZENDESK_KEY,
  )
    ? null
    : process.env.REACT_APP_ZENDESK_KEY
  const [clientContact, setClientContact] = useState(false)

  useEffect(() => {
    setClientContact(props.contactClient && props.showLogo)
  }, [props.contactClient, props.showLogo])

  useEffect(() => {
    const root = document.documentElement

    if (zendeskKey && props.show_sendesk && props.showLogo) {
      root.querySelector('#launcher')?.classNameList?.remove('none')
      root.querySelector('#launcher')?.classNameList?.add('d-block')
    } else {
      root.querySelector('#launcher')?.classNameList?.remove('d-block')
      root.querySelector('#launcher')?.classNameList?.add('d-none')
    }
  }, [props.show_sendesk, props.showLogo])

  function goLegal() {
    history.push('/legal')
  }

  function openContactMail(isProfessional) {
    logEvent('Yapp Contacted', {'Is Medical': isProfessional})
    document.location = isProfessional
      ? 'mailto:soportemedicos@yapp.cl'
      : 'mailto:soporte@yapp.cl'
  }

  return process.env.REACT_APP_CLIENT_NAME === 'UC Christus' ? (
    <>
      <FooterUC />
    </>
  ) : (
    <footer
      className={`footer-wrapper ${
        process.env.REACT_APP_HIDE_PROMOTION === 'TRUE'
          ? 'pt-3 pt-lg-4 pb-2 pb-lg-4'
          : ''
      }`}>
      {zendeskKey && props.show_sendesk && props.showLogo && (
        <Zendesk defer zendeskKey={zendeskKey} />
      )}
      <div className="footer-content">
        <button
          id="gtm-webapp-go-legal"
          className={`btn-default--small btn-stroke btn-more-info font--14 ${
            process.env.REACT_APP_HIDE_PROMOTION === 'TRUE'
              ? 'mb-2 mb-lg-4'
              : ''
          }`}
          onClick={() => goLegal()}>
          {' '}
          {['MEDICAL', 'WIDGET'].includes(process.env.REACT_APP_PROFILE)
            ? 'Más información'
            : 'Lo que necesitas saber'}
        </button>
        <img
          src={Logo}
          alt="Yapp"
          className={`logo-footer ${
            process.env.REACT_APP_HIDE_PROMOTION === 'TRUE'
              ? 'mb-2 mb-lg-4'
              : ''
          } `}
        />
        {clientContact && (
          <div className="mb-4">
            <h4 className="txt-footer">¿Tienes alguna duda con tu seguro?</h4>
            <h4 className="txt-footer color-theme">
              Contáctanos a{' '}
              <a
                rel="noreferrer"
                className="link-client"
                href={props.contactClient}
                target="_blank">
                WhatsApp
              </a>
            </h4>
          </div>
        )}
        <div>
          <h4 className="txt-footer">
            ¿Tienes alguna duda con tu compra?{' '}
          </h4>
          <h4 className="txt-footer">
            Ingresa a {' '}
            <a className="color-lilac" onClick={() => window.open(faq)}>Preguntas Frecuentes</a>
          </h4>
        </div>
        {!props.hideSocialLinks && (
          <div className="content-rss">
            <a
              href="https://www.facebook.com/yappchile/"
              target="_blank"
              rel="noreferrer">
              <i className="icon-facebook"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/yapp-cl/?originalSubdomain=cl"
              rel="noreferrer"
              target="_blank">
              <i className="icon-linkedin"></i>
            </a>
            <a
              href="https://twitter.com/ChileYapp"
              target="_blank"
              rel="noreferrer">
              <i className="icon-twitter"></i>
            </a>
            <a
              href="https://www.youtube.com/channel/UCq90MB-UxSU_vA9blQ2Aglg"
              rel="noreferrer"
              target="_blank">
              <i className="icon-youtube"></i>
            </a>
            <a
              href="https://www.instagram.com/yapp_chile/"
              target="_blank"
              rel="noreferrer">
              <i className="icon-instagram"></i>
            </a>
          </div>
        )}
      </div>
    </footer>
  )
}

const mapStateToProps = (state) => {
  return {
    hideSocialLinks: state.app.config?.hide_social_links,
    contactClient: state.app.config?.contact_client,
    show_sendesk: state.app.config?.show_sendesk,
    showLogo: state.app.show_logo,
  }
}

export default connect(mapStateToProps)(Footer)
