import React, {useState} from 'react'
import {useLocation} from 'react-router-dom'
import powerByYapp from '../../assets/img/power_by_header_new.svg'
import {useHistory} from 'react-router-dom'
import {connect} from 'react-redux'
import {hideBtnBack} from '../../Utils/constants'

if (process.env.REACT_APP_CLIENT_NAME === 'UC Christus') {
  import('./StylesUC.css').then((ignore) => {})
}

function HeaderUC(props) {
  const location = useLocation()
  const history = useHistory()

  const [menuMobileActive, setMenuMobileActive] = useState(false)
  const [subMenuActive, setSubMenuActive] = useState(0)

  const isSubMenuActive = (index) => {
    if (subMenuActive !== index) {
      setSubMenuActive(index)
    } else if (subMenuActive === index) {
      setSubMenuActive(0)
    }
  }

  const onNavigate = (c) => {
    if (c?.target) {
      window.open(c.go, c.target)
    } else {
      history.push(c.go)
    }
  }

  return (
    <>
      <div className="phone-mobil">
        <i className="fa fa-ambulance"></i>
        <a
          href="https://www.ucchristus.cl/especialidades-y-servicios/servicios/unidad-de-rescate"
          rel="noreferrer"
          target="_blank">
          Rescate ambulancia
        </a>
      </div>
      <div className="wrapper wrapper-home">
        <header className="header-home">
          <div className="head-top">
            <div className="wrap">
              <div className="logo">
                <a
                  href="https://www.ucchristus.cl/yapp"
                  rel="noreferrer"
                  target="_blank">
                  <img
                    alt=""
                    src="https://www.ucchristus.cl/assets/images/logo_UC.svg"
                  />
                </a>
              </div>
              <div className="botones-head desk">
                <div className="d-none-lfi">
                  <a
                    rel="noreferrer"
                    className="mi-pagina"
                    href="https://agenda.clinicasancarlos.cl/"
                    target="_blank">
                    PORTAL PACIENTE
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://agenda.ucchristus.cl/"
                    className="reserva res-dek res-blog">
                    <span>RESERVA DE HORAS</span>
                  </a>
                </div>
                <div className="d-lfi">
                  <div className="mi-pagina">MI PAGINA DE SALUD</div>
                  <div className="reserva res-dek res-blog">
                    <span>
                      RESERVA DE HORAS
                      <em className="fa fa-chevron-down fa-especialidades-y-servicios"></em>
                    </span>
                  </div>
                  <div className="row">
                    <div className="box-botones res-btn-blog">
                      <a
                        rel="noreferrer"
                        href="https://agenda.ucchristus.cl"
                        target="_blank"
                        className="reserva">
                        <span>Centros Médicos</span>
                      </a>
                      <a
                        rel="noreferrer"
                        href="https://agenda.clinicasancarlos.cl"
                        target="_blank"
                        className="reserva">
                        <span>Clínica San Carlos</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="datos-head">
                <div className="redes-head" id="form-ancla">
                  <a
                    rel="noreferrer"
                    href="https://www.facebook.com/UCCHRISTUS"
                    target="_blank">
                    <img
                      alt=""
                      src="https://www.ucchristus.cl/assets/images/rs1.png"
                    />
                  </a>
                  <a
                    rel="noreferrer"
                    href="https://www.instagram.com/ucchristus/?hl=es-la"
                    target="_blank">
                    <img
                      alt=""
                      src="https://www.ucchristus.cl/assets/images/rs4.png"
                    />
                  </a>
                  <a
                    rel="noreferrer"
                    href="https://twitter.com/ucchristus"
                    target="_blank">
                    <img
                      alt=""
                      src="https://www.ucchristus.cl/assets/images/rs2.png"
                    />
                  </a>
                  <a
                    rel="noreferrer"
                    href="https://cl.linkedin.com/company/red-de-salud-uc-christus"
                    target="_blank">
                    <img
                      alt=""
                      src="https://www.ucchristus.cl/assets/images/rs5.png"
                    />
                  </a>
                  <a
                    rel="noreferrer"
                    href="https://www.youtube.com/user/UCCHRISTUS"
                    target="_blank">
                    <img
                      alt=""
                      src="https://www.ucchristus.cl/assets/images/rs3.png"
                    />
                  </a>
                  <a
                    rel="noreferrer"
                    href="https://open.spotify.com/user/7x5ir8p8a0v5i0qnu1loj3xqi"
                    target="_blank">
                    <img
                      alt=""
                      src="https://www.ucchristus.cl/assets/images/rs6.png"
                    />
                  </a>
                </div>
                <div className="fonos-head">
                  <div className="item-fono">
                    Reserva de Horas
                    <a href="tel:226767000">
                      <span>22 676 7000</span>
                    </a>
                  </div>
                  <div className="item-fono red">
                    Rescate ambulancia
                    <a href="tel:800 265 265">
                      <span>800 265 265</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="botones-mobile">
                <a
                  href="https://www.ucchristus.cl/busca-tu-profesional"
                  className="ic-medico">
                  <img
                    alt=""
                    title="Busca a nuestros profesionales"
                    src="https://www.ucchristus.cl/assets/images/ic-medico2.png"
                  />
                </a>
                <div
                  className="btn-menu-uc"
                  onClick={() => setMenuMobileActive(true)}
                  style={{display: menuMobileActive ? 'none' : 'block'}}>
                  <i className="fa fa-bars"></i>
                </div>
                <div
                  className="cerrar-menu"
                  onClick={() => setMenuMobileActive(false)}
                  style={{display: menuMobileActive ? 'inline' : ''}}>
                  <i className="fa fa-times"></i>
                </div>
              </div>
              <div className="clear"></div>
            </div>
          </div>
          <div className="barra-menu">
            <div className="wrap">
              <div className="logo-fixed">
                <a href="https://www.ucchristus.cl/">
                  <img
                    alt=""
                    src="https://www.ucchristus.cl/assets/images/logo2.png"
                  />
                </a>
              </div>
              <nav>
                <ul className="">
                  <li className="menu-informacion-al-paciente" nivel="1">
                    <a
                      href="https://www.ucchristus.cl/informacion-al-paciente"
                      target="_blank"
                      rel="noreferrer">
                      Información al Paciente
                      <i className="fa fa-chevron-down fa-informacion-al-paciente"></i>
                    </a>

                    <ul className="sub-menu menu-informacion-al-paciente">
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/informacion-al-paciente/quienes-somos"
                          target="_blank"
                          rel="noreferrer">
                          Quiénes Somos<i className="fa fa-chevron-right"></i>
                        </a>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/informacion-al-paciente/christus-health"
                          target="_blank"
                          rel="noreferrer">
                          CHRISTUS Health<i className="fa fa-chevron-right"></i>
                        </a>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/informacion-al-paciente/paciente-de-regiones"
                          target="_blank"
                          rel="noreferrer">
                          Paciente de Regiones
                          <i className="fa fa-chevron-right"></i>
                        </a>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/informacion-al-paciente/preparacion-para-examenes"
                          rel="noreferrer"
                          target="_blank">
                          Preparación para exámenes
                          <i className="fa fa-chevron-right"></i>
                        </a>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/informacion-al-paciente/ley-de-derechos-y-deberes"
                          target="_blank"
                          rel="noreferrer">
                          Ley de Derechos y Deberes
                          <i className="fa fa-chevron-right"></i>
                        </a>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/informacion-al-paciente/aranceles"
                          target="_blank"
                          rel="noreferrer">
                          Aranceles<i className="fa fa-chevron-right"></i>
                        </a>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/informacion-al-paciente/isapres-y-fonasa"
                          target="_blank"
                          rel="noreferrer">
                          Isapres y Fonasa
                          <i className="fa fa-chevron-right"></i>
                        </a>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/informacion-al-paciente/indicadores-clinicos"
                          target="_blank"
                          rel="noreferrer">
                          Indicadores Clínicos
                          <i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-indicadores-clinicos">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/informacion-al-paciente/indicadores-clinicos/foco-calidad"
                                target="_blank"
                                rel="noreferrer">
                                Foco Calidad
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/informacion-al-paciente/indicadores-clinicos/actividad-y-volumen"
                                target="_blank"
                                rel="noreferrer">
                                Actividad y Volumen
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/informacion-al-paciente/indicadores-clinicos/seguridad-del-paciente"
                                target="_blank"
                                rel="noreferrer">
                                Seguridad del Paciente
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/informacion-al-paciente/indicadores-clinicos/satisfaccion"
                                target="_blank"
                                rel="noreferrer">
                                Satisfacción
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/informacion-al-paciente/mision-y-responsabilidad-social"
                          target="_blank"
                          rel="noreferrer">
                          Misión y Responsabilidad Social
                          <i className="fa fa-chevron-right"></i>
                        </a>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/informacion-al-paciente/compliance"
                          target="_blank"
                          rel="noreferrer">
                          Compliance<i className="fa fa-chevron-right"></i>
                        </a>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/informacion-al-paciente/red-de-mensajes"
                          target="_blank"
                          rel="noreferrer">
                          Red de Mensajes<i className="fa fa-chevron-right"></i>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-especialidades-y-servicios" nivel="1">
                    <a
                      href="https://www.ucchristus.cl/especialidades-y-servicios"
                      target="_blank"
                      rel="noreferrer">
                      Especialidades y Servicios
                      <i className="fa fa-chevron-down fa-especialidades-y-servicios"></i>
                    </a>

                    <ul className="sub-menu menu-especialidades-y-servicios">
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades"
                          target="_blank"
                          rel="noreferrer">
                          Especialidades<i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-especialidades">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/anestesiologia"
                                target="_blank"
                                rel="noreferrer">
                                Anestesiología
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/broncopulmonar"
                                target="_blank"
                                rel="noreferrer">
                                Broncopulmonar
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/broncopulmonar-pediatrico"
                                target="_blank"
                                rel="noreferrer">
                                Broncopulmonar Pediátrico
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/cancer-uc"
                                target="_blank"
                                rel="noreferrer">
                                Centro Médico Cáncer UC
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/cardiologia"
                                target="_blank"
                                rel="noreferrer">
                                Cardiología
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/cardiologia-pediatrica"
                                target="_blank"
                                rel="noreferrer">
                                Cardiología Pediátrica
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/cirugia-cardiaca"
                                target="_blank"
                                rel="noreferrer">
                                Cirugía Cardíaca
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/cirugia-de-cabeza-y-cuello"
                                target="_blank"
                                rel="noreferrer">
                                Cirugía de Cabeza y Cuello
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/cirugia-de-torax"
                                target="_blank"
                                rel="noreferrer">
                                Cirugía de Tórax
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/cirugia-digestiva"
                                target="_blank"
                                rel="noreferrer">
                                Cirugía Digestiva
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/cirugia-maxilo-facial"
                                target="_blank"
                                rel="noreferrer">
                                Cirugía Maxilo Facial
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/cirugia-oncologica-y-maxilofacial"
                                target="_blank"
                                rel="noreferrer">
                                Cirugía Oncológica y Maxilofacial
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/cirugia-pediatrica"
                                target="_blank"
                                rel="noreferrer">
                                Cirugía Pediátrica
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/cirugia-plastica-y-reparadora"
                                target="_blank"
                                rel="noreferrer">
                                Cirugía Plástica y Reparadora
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/cirugia-vascular-y-endovascular"
                                target="_blank"
                                rel="noreferrer">
                                Cirugía Vascular y Endovascular
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/cirugia-coloproctologica"
                                target="_blank"
                                rel="noreferrer">
                                Cirugía Coloproctológica
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/cosmetologia"
                                target="_blank"
                                rel="noreferrer">
                                Cosmetología Médica
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/dermatologia"
                                target="_blank"
                                rel="noreferrer">
                                Dermatología
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/endocrinologia"
                                target="_blank"
                                rel="noreferrer">
                                Endocrinología
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/fonoaudiologia"
                                target="_blank"
                                rel="noreferrer">
                                Fonoaudiología
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/gastroenterologia"
                                target="_blank"
                                rel="noreferrer">
                                Gastroenterología
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/genetica-clinica"
                                target="_blank"
                                rel="noreferrer">
                                Genética Clínica
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/geriatria"
                                target="_blank"
                                rel="noreferrer">
                                Geriatría
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/ginecologia-y-obstetricia"
                                target="_blank"
                                rel="noreferrer">
                                Ginecología y Obstetricia
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/hematologia"
                                target="_blank"
                                rel="noreferrer">
                                Hematología - Oncología
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/infectologia"
                                target="_blank"
                                rel="noreferrer">
                                Infectología
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/infectologia-inmunologia-pediatrica"
                                target="_blank"
                                rel="noreferrer">
                                Infectología Pediátrica
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/inmunologia"
                                target="_blank"
                                rel="noreferrer">
                                Inmunología
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/kinesiologia"
                                target="_blank"
                                rel="noreferrer">
                                Kinesiología
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/medicina-familiar"
                                target="_blank"
                                rel="noreferrer">
                                Medicina Familiar
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/fisiatria"
                                target="_blank"
                                rel="noreferrer">
                                Fisiatría
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/medicina-interna"
                                target="_blank"
                                rel="noreferrer">
                                Medicina Interna
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/nefrologia"
                                target="_blank"
                                rel="noreferrer">
                                Nefrología
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/nefrologia-pediatrica"
                                target="_blank"
                                rel="noreferrer">
                                Nefrología Pediátrica
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/neonatologia"
                                target="_blank"
                                rel="noreferrer">
                                Neonatología
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/neurocirugia"
                                target="_blank"
                                rel="noreferrer">
                                Neurocirugía
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/neurologia"
                                target="_blank"
                                rel="noreferrer">
                                Neurología
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/neurologia-pediatrica"
                                target="_blank"
                                rel="noreferrer">
                                Neurología Pediátrica
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/nutricion-diabetes-y-metabolismo"
                                target="_blank"
                                rel="noreferrer">
                                Nutrición, Diabetes y Metabolismo
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/odontologia"
                                target="_blank"
                                rel="noreferrer">
                                Odontología
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/oftalmologia"
                                target="_blank"
                                rel="noreferrer">
                                Oftalmología
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/otorrinolaringologia"
                                target="_blank"
                                rel="noreferrer">
                                Otorrinolaringología
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/pediatria"
                                target="_blank"
                                rel="noreferrer">
                                Pediatría
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/psiquiatria"
                                target="_blank"
                                rel="noreferrer">
                                Psiquiatría
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/psiquiatria-infanto-juvenil"
                                target="_blank"
                                rel="noreferrer">
                                Psiquiatría Infanto-Juvenil
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/reumatologia"
                                target="_blank"
                                rel="noreferrer">
                                Reumatología
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/terapia-ocupacional"
                                target="_blank"
                                rel="noreferrer">
                                Terapia Ocupacional
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/traumatologia-y-ortopedia"
                                target="_blank"
                                rel="noreferrer">
                                Traumatología y Ortopedia
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades/urologia"
                                target="_blank"
                                rel="noreferrer">
                                Urología
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/especialidades-y-servicios/servicios"
                          target="_blank"
                          rel="noreferrer">
                          Servicios<i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-servicios">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/servicios/anatomia"
                                target="_blank"
                                rel="noreferrer">
                                Anatomía Patológica
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/servicios/banco-de-sangre"
                                target="_blank"
                                rel="noreferrer">
                                Banco de Sangre
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/servicios/cancer-uc"
                                target="_blank"
                                rel="noreferrer">
                                Cáncer UC
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/servicios/emergencia-uc-christus"
                                target="_blank"
                                rel="noreferrer">
                                Emergencia UC CHRISTUS
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/servicios/maternidad"
                                target="_blank"
                                rel="noreferrer">
                                Maternidad UC
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/servicios/medicina-deportiva"
                                target="_blank"
                                rel="noreferrer">
                                Medicina Deportiva
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/servicios/medicina-fisica-y-rehabilitacion"
                                target="_blank"
                                rel="noreferrer">
                                Medicina Física y Rehabilitación
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/servicios/telemedicina"
                                target="_blank"
                                rel="noreferrer">
                                Telemedicina
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/servicios/telemedicina-internacional"
                                target="_blank"
                                rel="noreferrer">
                                Telemedicina Internacional
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/servicios/unidad-de-rescate"
                                target="_blank"
                                rel="noreferrer">
                                Unidad de Rescate
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/servicios/unidad-de-enlace"
                                target="_blank"
                                rel="noreferrer">
                                Unidad de Enlace
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/servicios/urgencia"
                                target="_blank"
                                rel="noreferrer">
                                Urgencia
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/servicios/vacunatorios"
                                target="_blank"
                                rel="noreferrer">
                                Vacunatorios
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/especialidades-y-servicios/centros"
                          target="_blank"
                          rel="noreferrer">
                          Centros<i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-centros">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/centros/centro-de-alergias"
                                target="_blank"
                                rel="noreferrer">
                                Centro de Alergias
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/centros/centro-de-esclerosis-multiple"
                                target="_blank"
                                rel="noreferrer">
                                Centro de Esclerosis Múltiple
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/centros/centro-de-hipertension-pulmonar"
                                target="_blank"
                                rel="noreferrer">
                                Centro de Hipertensión Pulmonar
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/centros/centro-de-manejo-del-dolor"
                                target="_blank"
                                rel="noreferrer">
                                Centro Interdisciplinario de Manejo de Dolor
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/centros/centro-de-medicina-del-viajero"
                                target="_blank"
                                rel="noreferrer">
                                Centro de Medicina del Viajero
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/centros/centro-de-terapia-endovascular"
                                target="_blank"
                                rel="noreferrer">
                                Centro de Terapia Endovascular
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/centros/centro-del-crecimiento"
                                target="_blank"
                                rel="noreferrer">
                                Centro del Crecimiento
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/centros/centro-del-sueno"
                                target="_blank"
                                rel="noreferrer">
                                Centro del Sueño
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/centros/centro-del-tratamiento-de-la-obesidad"
                                target="_blank"
                                rel="noreferrer">
                                Centro del Tratamiento de la Obesidad
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/especialidades-y-servicios/programas"
                          target="_blank"
                          rel="noreferrer">
                          Programas<i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-programas">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-analgesia-domiciliaria"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Analgesia Domiciliaria
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-angioplastia-coronaria"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Angioplastía Coronaria
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-atencion-al-paciente-con-vih"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Atención al Paciente con VIH
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-beneficios-formando-familia"
                                target="_blank"
                                rel="noreferrer">
                                Programa Beneficios Formando Familia
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programadecaidas"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Caídas
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-cancer-de-prostata-avanzado"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Cáncer de Próstata Avanzado
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-cancer-de-prostata-oligometastasico"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Cáncer de Próstata Oligometastásico
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-circulo-dorado"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Círculo Dorado
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/cirugia-urologica-asistida-por-robot"
                                rel="noreferrer"
                                target="_blank">
                                Cirugía Urológica Asistida por Robot
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/cirugia-robotica-de-rodilla"
                                target="_blank"
                                rel="noreferrer">
                                Cirugía Robótica de Rodilla
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-confianza-al-nacer"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Confianza al Nacer
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-cuidados-paliativos-perinatales"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Cuidados Paliativos Perinatales
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-dbt"
                                target="_blank"
                                rel="noreferrer">
                                Programa DBT: Terapia Dialéctica Conductual
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-deteccion-precoz-de-cancer-de-pulmon"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Detección Precoz de Cáncer de Pulmón
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-deteccion-y-diagnostico-en-cancer-de-prostata"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Detección y Diagnóstico en Cáncer de
                                Próstata
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-dolor-cronico-no-oncologico"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Dolor Crónico no Oncológico
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-dolor-neuropatico"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Dolor Neuropático
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-elige-estar-bien"
                                target="_blank"
                                rel="noreferrer">
                                Programa Elige Estar bien
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-embarazo-alto-riesgo"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Embarazo de Alto Riesgo
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-enfermedades-suprarenales"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Enfermedades Suprarrenales
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-epilepsia"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Epilepsia
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/espacio_balance"
                                target="_blank"
                                rel="noreferrer">
                                Espacio Balance
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-fibromialgia"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Fibromialgia
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-lactancia-materna"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Lactancia Materna
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-liberate-del-tabaco"
                                target="_blank"
                                rel="noreferrer">
                                Programa Libérate del Tabaco
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-medicina-deportiva"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Medicina Deportiva
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-medicina-paliativa-y-cuidados-continuos"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Medicina Paliativa y Cuidados
                                Continuos
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-medicina-preventiva"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Medicina Preventiva
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-medicina-genomica"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Medicina Genómica
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-memoria"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Memoria
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-mi-hijo-no-duerme-bien"
                                target="_blank"
                                rel="noreferrer">
                                Programa Mi hijo no Duerme Bien
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-mindfulness-bienestar"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Mindfulness y Bienestar
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-no-mas-fracturas"
                                target="_blank"
                                rel="noreferrer">
                                Programa No Más Fracturas
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-orientacion-vocacional"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Orientación Vocacional
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/parentalidad-positiva"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Parentalidad Positiva (Triple-P)
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-queloides"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Queloides
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-tavi"
                                target="_blank"
                                rel="noreferrer">
                                Programa TAVI
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-temblor-y-parkinson"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Temblor y Parkinson
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-tratamiento-de-calculos-urinarios"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Tratamiento Integral de Cálculos
                                Urinarios
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-trasplante"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Trasplante
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-trasplante-hematopoyetico"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Trasplante Hematopoyético
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-tumores-hipofisiarios"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Tumores Hipofisiarios
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-seguimiento-post-covid-19"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Seguimiento Post COVID-19
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-ventilacion-mecanica-domiciliaria"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Ventilación Mecánica Domiciliaria
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-paralisis-facial"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Parálisis Facial
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-prep-vih"
                                target="_blank"
                                rel="noreferrer">
                                Programa PrEP VIH
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/programas/programa-de-enfermedad-pulmonar-intersticial-difusa"
                                target="_blank"
                                rel="noreferrer">
                                Programa de Enfermedad Pulmonar Intersticial
                                Difusa
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/especialidades-y-servicios/unidades"
                          target="_blank"
                          rel="noreferrer">
                          Unidades<i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-unidades">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-de-adicciones"
                                target="_blank"
                                rel="noreferrer">
                                Unidad de Adicciones
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-de-andrologia-y-disfunciones-sexuales-masculinas"
                                target="_blank"
                                rel="noreferrer">
                                Unidad de Andrología y Disfunciones Sexuales
                                Masculinas
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-de-climaterio"
                                target="_blank"
                                rel="noreferrer">
                                Unidad de Climaterio
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-de-dialisis"
                                target="_blank"
                                rel="noreferrer">
                                Unidad de Diálisis
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-de-endoscopia-ginecologica"
                                target="_blank"
                                rel="noreferrer">
                                Unidad de Endoscopia Ginecológica
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-de-litiasis"
                                target="_blank"
                                rel="noreferrer">
                                Unidad de Litiasis
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-de-manejo-del-dolor"
                                target="_blank"
                                rel="noreferrer">
                                Unidad de Manejo del Dolor
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-de-medicina-reproductiva"
                                target="_blank"
                                rel="noreferrer">
                                Unidad de Medicina Reproductiva
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-de-melanoma-y-cancer-de-piel"
                                target="_blank"
                                rel="noreferrer">
                                Unidad de Melanoma y Cáncer de Piel
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-de-pediatria-hospitalaria"
                                target="_blank"
                                rel="noreferrer">
                                Unidad de Pediatría Hospitalaria
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-de-piso-pelvico"
                                target="_blank"
                                rel="noreferrer">
                                Unidad de Piso Pélvico
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-de-psicoterapia-adultos-upa"
                                target="_blank"
                                rel="noreferrer">
                                Unidad de Psicoterapia Adultos (UPA)
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-de-psiquiatria-del-nino-y-el-adolescente"
                                target="_blank"
                                rel="noreferrer">
                                Unidad de Psiquiatría del Niño y el Adolescente
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-de-terapias-de-pareja-y-sexualidad"
                                target="_blank"
                                rel="noreferrer">
                                Unidad de Terapias de Pareja y Sexualidad
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-de-trastorno-del-animo"
                                target="_blank"
                                rel="noreferrer">
                                Unidad de Trastornos del Ánimo
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-de-trastornos-de-la-alimentacion"
                                target="_blank"
                                rel="noreferrer">
                                Unidad de Trastornos de la Alimentación
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-de-trauma-y-disociacion"
                                target="_blank"
                                rel="noreferrer">
                                Unidad de Trauma y Disociación
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-de-ultrasonido-en-ginecologia-y-obstetricia"
                                target="_blank"
                                rel="noreferrer">
                                Unidad de Ultrasonido en Ginecología y
                                Obstetricia
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-de-uroginecologia"
                                target="_blank"
                                rel="noreferrer">
                                Unidad de Uroginecología
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-de-urologia-oncologica"
                                target="_blank"
                                rel="noreferrer">
                                Unidad de Urología Oncológica
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-de-urologia-pediatrica"
                                target="_blank"
                                rel="noreferrer">
                                Unidad de Urología Pediátrica
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-de-psiquiatria-de-enlace-y-medicina-psicosomatica"
                                target="_blank"
                                rel="noreferrer">
                                Unidad de Psiquiatría de Enlace y Medicina
                                Psicosomática
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-examenes-y-procedimientos" nivel="1">
                    <a
                      href="https://www.ucchristus.cl/examenes-y-procedimientos"
                      target="_blank"
                      rel="noreferrer">
                      Exámenes y Procedimientos
                      <i className="fa fa-chevron-down fa-examenes-y-procedimientos"></i>
                    </a>

                    <ul className="sub-menu menu-examenes-y-procedimientos">
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/examenes-y-procedimientos/laboratorio-clinico"
                          target="_blank"
                          rel="noreferrer">
                          Laboratorio Clínico
                          <i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-laboratorio-clinico">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/examenes-y-procedimientos/laboratorio-clinico/sobre-el-laboratorio"
                                target="_blank"
                                rel="noreferrer">
                                Sobre el laboratorio
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/examenes-y-procedimientos/laboratorio-clinico/nuestro-equipo-medico"
                                target="_blank"
                                rel="noreferrer">
                                Nuestro Equipo
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/examenes-y-procedimientos/laboratorio-clinico/toma-de-muestras"
                                target="_blank"
                                rel="noreferrer">
                                Toma de Muestras
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/examenes-y-procedimientos/laboratorio-clinico/laboratorios-de-especialidades"
                                target="_blank"
                                rel="noreferrer">
                                Laboratorios de especialidades
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/examenes-y-procedimientos/laboratorio-clinico/informacion-sobre-examenes"
                                target="_blank"
                                rel="noreferrer">
                                Información sobre Exámenes
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/examenes-y-procedimientos/laboratorio-clinico/resultados-de-examenes"
                                target="_blank"
                                rel="noreferrer">
                                Resultados de Exámenes
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/examenes-y-procedimientos/diagnostico-por-imagenes"
                          target="_blank"
                          rel="noreferrer">
                          Diagnóstico por Imágenes
                          <i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-diagnostico-por-imagenes">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/examenes-y-procedimientos/diagnostico-por-imagenes/tipos-de-examenes-de-radiologia"
                                target="_blank"
                                rel="noreferrer">
                                Tipos de Exámenes de Radiología
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/examenes-y-procedimientos/diagnostico-por-imagenes/resultado-de-examenes"
                                target="_blank"
                                rel="noreferrer">
                                Resultados de Exámenes
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/examenes-y-procedimientos/diagnostico-por-imagenes/ubicacion-y-horarios"
                                target="_blank"
                                rel="noreferrer">
                                Ubicación y Horarios
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/examenes-y-procedimientos/diagnostico-por-imagenes/encuestas-previas"
                                target="_blank"
                                rel="noreferrer">
                                Encuestas Previas
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/examenes-y-procedimientos/procedimientosclinicos"
                          target="_blank"
                          rel="noreferrer">
                          Procedimientos Clínicos
                          <i className="fa fa-chevron-right"></i>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-red-de-atencion" nivel="1">
                    <a
                      href="https://www.ucchristus.cl/red-de-atencion"
                      target="_blank"
                      rel="noreferrer">
                      Red de Atención
                      <i className="fa fa-chevron-down fa-red-de-atencion"></i>
                    </a>

                    <ul className="sub-menu menu-red-de-atencion">
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/red-de-atencion/hospital-clinico"
                          target="_blank"
                          rel="noreferrer">
                          Hospital Clínico
                          <i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-hospital-clinico">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/hospital-clinico/servicios"
                                target="_blank"
                                rel="noreferrer">
                                Servicios
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/hospital-clinico/ubicacion-y-horarios"
                                target="_blank"
                                rel="noreferrer">
                                Ubicación y Horarios
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/hospital-clinico/pastoral-de-la-salud"
                                target="_blank"
                                rel="noreferrer">
                                Pastoral de la Salud
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/hospital-clinico/reglamento-interno"
                                target="_blank"
                                rel="noreferrer">
                                Reglamento Interno
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/red-de-atencion/clinica-uc"
                          target="_blank"
                          rel="noreferrer">
                          Clínica UC<i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-clinica-uc">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/clinica-uc/servicios"
                                target="_blank"
                                rel="noreferrer">
                                Servicios
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/clinica-uc/ubicacion-y-horarios"
                                target="_blank"
                                rel="noreferrer">
                                Ubicación y Horarios
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/clinica-uc/reglamento-interno"
                                target="_blank"
                                rel="noreferrer">
                                Reglamento Interno
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/red-de-atencion/clinica-san-carlos-de-apoquindo"
                          target="_blank"
                          rel="noreferrer">
                          Clínica San Carlos de Apoquindo
                          <i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-clinica-san-carlos-de-apoquindo">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/clinica-san-carlos-de-apoquindo/servicios"
                                target="_blank"
                                rel="noreferrer">
                                Servicios
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/clinica-san-carlos-de-apoquindo/ubicacion-y-horarios"
                                target="_blank"
                                rel="noreferrer">
                                Ubicación y Horarios
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/red-de-atencion/unidad-de-toma-de-muestras"
                          target="_blank"
                          rel="noreferrer">
                          Unidad de Toma de Muestras
                          <i className="fa fa-chevron-right"></i>
                        </a>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/red-de-atencion/centro-medico-virtual"
                          target="_blank"
                          rel="noreferrer">
                          Centro Médico Virtual
                          <i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-centro-medico-virtual">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/centro-medico-virtual/telemedicina"
                                target="_blank"
                                rel="noreferrer">
                                Telemedicina
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/centro-medico-virtual/programas-virtuales"
                                target="_blank"
                                rel="noreferrer">
                                Programas Virtuales por Especialidades
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/centro-medico-virtual/telemedicina-internacional"
                                target="_blank"
                                rel="noreferrer">
                                Telemedicina Internacional
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/red-de-atencion/centro-de-especialidades-medicas"
                          target="_blank"
                          rel="noreferrer">
                          Centro de Especialidades Médicas
                          <i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-centro-de-especialidades-medicas">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/centro-de-especialidades-medicas/servicios"
                                target="_blank"
                                rel="noreferrer">
                                Servicios
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/centro-de-especialidades-medicas/ubicacion-y-horarios"
                                target="_blank"
                                rel="noreferrer">
                                Ubicación y Horarios
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/centro-de-especialidades-medicas/material-descargable"
                                target="_blank"
                                rel="noreferrer">
                                Reglamento Interno
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/red-de-atencion/centro-medico-san-joaquin"
                          target="_blank"
                          rel="noreferrer">
                          Centro Médico San Joaquín
                          <i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-centro-medico-san-joaquin">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/centro-medico-san-joaquin/servicios"
                                target="_blank"
                                rel="noreferrer">
                                Servicios
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/centro-medico-san-joaquin/ubicacion-y-horarios"
                                target="_blank"
                                rel="noreferrer">
                                Ubicación y Horarios
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/centro-medico-san-joaquin/material-descargable"
                                target="_blank"
                                rel="noreferrer">
                                Reglamento Interno
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/red-de-atencion/centro-medico-lira"
                          target="_blank"
                          rel="noreferrer">
                          Centro Médico Lira
                          <i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-centro-medico-lira">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/centro-medico-lira/servicios"
                                target="_blank"
                                rel="noreferrer">
                                Servicios
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/centro-medico-lira/ubicacion-y-horarios"
                                target="_blank"
                                rel="noreferrer">
                                Ubicación y Horarios
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/centro-medico-lira/material-descargable"
                                target="_blank"
                                rel="noreferrer">
                                Reglamento Interno
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/red-de-atencion/edificio-medico-alcantara"
                          target="_blank"
                          rel="noreferrer">
                          Edificio Médico Alcántara
                          <i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-edificio-medico-alcantara">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/edificio-medico-alcantara/servicios"
                                target="_blank"
                                rel="noreferrer">
                                Servicios
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/edificio-medico-alcantara/ubicacion-y-horarios"
                                target="_blank"
                                rel="noreferrer">
                                Ubicación y Horarios
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/red-de-atencion/centro-medico-irarrazaval"
                          target="_blank"
                          rel="noreferrer">
                          Centro Médico Irarrázaval
                          <i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-centro-medico-irarrazaval">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/centro-medico-irarrazaval/servicios"
                                target="_blank"
                                rel="noreferrer">
                                Servicios
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/centro-medico-irarrazaval/ubicacion-y-horarios"
                                target="_blank"
                                rel="noreferrer">
                                Ubicación y Horarios
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/centro-medico-irarrazaval/material-descargable"
                                target="_blank"
                                rel="noreferrer">
                                Reglamento Interno
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/red-de-atencion/centro-medico-san-jorge"
                          target="_blank"
                          rel="noreferrer">
                          Centro Médico San Jorge
                          <i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-centro-medico-san-jorge">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/centro-medico-san-jorge/servicios"
                                target="_blank"
                                rel="noreferrer">
                                Servicios
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/centro-medico-san-jorge/ubicacion-y-horarios"
                                target="_blank"
                                rel="noreferrer">
                                Ubicación y Horarios
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/red-de-atencion/centro-de-diagnostico-bilbao"
                          target="_blank"
                          rel="noreferrer">
                          Centro de Diagnóstico Bilbao
                          <i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-centro-de-diagnostico-bilbao">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/centro-de-diagnostico-bilbao/servicios"
                                target="_blank"
                                rel="noreferrer">
                                Servicios
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/centro-de-diagnostico-bilbao/ubicación-y-horarios"
                                target="_blank"
                                rel="noreferrer">
                                Ubicación y Horarios
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/red-de-atencion/cesfam-juan-pablo-ii"
                          target="_blank"
                          rel="noreferrer">
                          CESFAM Juan Pablo II
                          <i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-cesfam-juan-pablo-ii">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/cesfam-juan-pablo-ii/el-equipo"
                                target="_blank"
                                rel="noreferrer">
                                El Equipo
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/cesfam-juan-pablo-ii/servicios-y-programas"
                                target="_blank"
                                rel="noreferrer">
                                Servicios y Programas
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/cesfam-juan-pablo-ii/ubicacion-y-horarios"
                                target="_blank"
                                rel="noreferrer">
                                Ubicación y Horarios
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/red-de-atencion/cesfam-madre-teresa-de-calcuta"
                          target="_blank"
                          rel="noreferrer">
                          CESFAM Madre Teresa de Calcuta
                          <i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-cesfam-madre-teresa-de-calcuta">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/cesfam-madre-teresa-de-calcuta/servicios-y-programas"
                                target="_blank"
                                rel="noreferrer">
                                Servicios y Programas
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/cesfam-madre-teresa-de-calcuta/ubicacion-y-horarios"
                                target="_blank"
                                rel="noreferrer">
                                Ubicación y Horarios
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/red-de-atencion/cesfam-san-alberto-hurtado"
                          target="_blank"
                          rel="noreferrer">
                          CESFAM San Alberto Hurtado
                          <i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-cesfam-san-alberto-hurtado">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/cesfam-san-alberto-hurtado/servicios-y-programas"
                                target="_blank"
                                rel="noreferrer">
                                Servicios y Programas
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/cesfam-san-alberto-hurtado/ubicacion-y-horarios"
                                target="_blank"
                                rel="noreferrer">
                                Ubicación y Horarios
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/red-de-atencion/centro-médico-rancagua"
                          target="_blank"
                          rel="noreferrer">
                          Centro Médico Rancagua
                          <i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-centro-medico-rancagua">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/centro-médico-rancagua/servicios"
                                target="_blank"
                                rel="noreferrer">
                                Servicios
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/red-de-atencion/centro-médico-rancagua/ubicación-y-horarios"
                                target="_blank"
                                rel="noreferrer">
                                Ubicación y Horarios
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-blog-salud-uc" nivel="1">
                    <a
                      href="https://www.ucchristus.cl/blog-salud-uc"
                      target="_blank"
                      rel="noreferrer">
                      Blog Salud UC
                      <i className="fa fa-chevron-down fa-blog-salud-uc"></i>
                    </a>

                    <ul className="sub-menu menu-blog-salud-uc">
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud"
                          target="_blank"
                          rel="noreferrer">
                          ABC de la Salud<i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-abc-de-la-salud">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/a"
                                target="_blank"
                                rel="noreferrer">
                                A
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/b"
                                target="_blank"
                                rel="noreferrer">
                                B
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/c"
                                target="_blank"
                                rel="noreferrer">
                                C
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/d"
                                target="_blank"
                                rel="noreferrer">
                                D
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/e"
                                target="_blank"
                                rel="noreferrer">
                                E
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/f"
                                target="_blank"
                                rel="noreferrer">
                                F
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/g"
                                target="_blank"
                                rel="noreferrer">
                                G
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/h"
                                target="_blank"
                                rel="noreferrer">
                                H
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/i"
                                target="_blank"
                                rel="noreferrer">
                                I
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/j"
                                target="_blank"
                                rel="noreferrer">
                                J
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/k"
                                target="_blank"
                                rel="noreferrer">
                                K
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/l"
                                target="_blank"
                                rel="noreferrer">
                                L
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/m"
                                target="_blank"
                                rel="noreferrer">
                                M
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/n"
                                target="_blank"
                                rel="noreferrer">
                                N
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/ñ"
                                target="_blank"
                                rel="noreferrer">
                                Ñ
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/o"
                                target="_blank"
                                rel="noreferrer">
                                O
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/p"
                                target="_blank"
                                rel="noreferrer">
                                P
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/q"
                                target="_blank"
                                rel="noreferrer">
                                Q
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/r"
                                target="_blank"
                                rel="noreferrer">
                                R
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/s"
                                target="_blank"
                                rel="noreferrer">
                                S
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/t"
                                target="_blank"
                                rel="noreferrer">
                                T
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/u"
                                target="_blank"
                                rel="noreferrer">
                                U
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/v"
                                target="_blank"
                                rel="noreferrer">
                                V
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/w"
                                target="_blank"
                                rel="noreferrer">
                                W
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/x"
                                target="_blank"
                                rel="noreferrer">
                                X
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/y"
                                target="_blank"
                                rel="noreferrer">
                                Y
                              </a>
                            </li>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud/z"
                                target="_blank"
                                rel="noreferrer">
                                Z
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/blog-salud-uc/uc-christus-conecta"
                          target="_blank"
                          rel="noreferrer">
                          UC Christus Conecta
                          <i className="fa fa-chevron-right"></i>
                        </a>

                        <div className="mega-menu menu-uc-christus-conecta">
                          <ul>
                            <li nivel="3">
                              <a
                                href="https://www.ucchristus.cl/blog-salud-uc/uc-christus-conecta/home-charlas-y-talleres"
                                target="_blank"
                                rel="noreferrer">
                                Charlas y Talleres
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/blog-salud-uc/articulos"
                          target="_blank"
                          rel="noreferrer">
                          Artículos<i className="fa fa-chevron-right"></i>
                        </a>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/blog-salud-uc/maternidad"
                          target="_blank"
                          rel="noreferrer">
                          Maternidad<i className="fa fa-chevron-right"></i>
                        </a>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/blog-salud-uc/familia"
                          target="_blank"
                          rel="noreferrer">
                          Familia<i className="fa fa-chevron-right"></i>
                        </a>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/blog-salud-uc/vida-sana"
                          target="_blank"
                          rel="noreferrer">
                          Vida Sana<i className="fa fa-chevron-right"></i>
                        </a>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/blog-salud-uc/medicina-uc"
                          target="_blank"
                          rel="noreferrer">
                          Medicina UC<i className="fa fa-chevron-right"></i>
                        </a>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/blog-salud-uc/noticias"
                          target="_blank"
                          rel="noreferrer">
                          Noticias<i className="fa fa-chevron-right"></i>
                        </a>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/blog-salud-uc/testimonios"
                          target="_blank"
                          rel="noreferrer">
                          Testimonios<i className="fa fa-chevron-right"></i>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-convenios" nivel="1">
                    <a
                      href="https://www.ucchristus.cl/convenios-uc-christus"
                      target="_blank"
                      rel="noreferrer">
                      Convenios
                      <i className="fa fa-chevron-down fa-convenios"></i>
                    </a>

                    <ul className="sub-menu menu-convenios">
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/convenios-uc-christus/laboratorio-clinico"
                          target="_blank"
                          rel="noreferrer">
                          Laboratorio Clínico
                          <i className="fa fa-chevron-right"></i>
                        </a>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/convenios-uc-christus/anatomia-patologica"
                          target="_blank"
                          rel="noreferrer">
                          Anatomía Patológica
                          <i className="fa fa-chevron-right"></i>
                        </a>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/convenios-uc-christus/empresas-e-instituciones"
                          target="_blank"
                          rel="noreferrer">
                          Empresas e Instituciones
                          <i className="fa fa-chevron-right"></i>
                        </a>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/convenios-uc-christus/convenios-ex-alumnos-uc"
                          target="_blank"
                          rel="noreferrer">
                          Ex-Alumnos UC<i className="fa fa-chevron-right"></i>
                        </a>
                      </li>
                      <li nivel="2">
                        <a
                          href="https://www.ucchristus.cl/convenios-uc-christus/administrador-de-convenios"
                          rel="noreferrer"
                          target="_blank">
                          Administrador de Convenios
                          <i className="fa fa-chevron-right"></i>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-planes-de-salud" nivel="1">
                    <a
                      href="https://www.ucchristus.cl/planes-de-salud"
                      target="_blank"
                      rel="noreferrer">
                      Planes de Salud
                      <i className="fa fa-chevron-down fa-planes-de-salud"></i>
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="btns-barra-menu">
                <div className="btn-atencion">
                  <a href={{javascript: void 0}} className="atencion">
                    ATENCIÓN EN LÍNEA <i className="fa fa-chevron-down"></i>
                  </a>
                  <div className="menu-atencion">
                    <a href="https://www.ucchristus.cl/presupuesto" target="">
                      <div className="item-atencion">
                        <div className="img-at">
                          <img
                            src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/default-album/icono_atencion.png?sfvrsn=ee995522_0"
                            alt="icono_atencion"
                            title="icono_atencion"
                          />
                        </div>
                        <p>PIDE TU PRESUPUESTO</p>
                      </div>
                    </a>
                    <a
                      href="https://www.ucchristus.cl/sistemas/resultados-laboratorio"
                      target="">
                      <div className="item-atencion">
                        <div className="img-at">
                          <img
                            src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/default-album/at3_acceso_rapido.png?sfvrsn=d819cce0_0"
                            alt="at3_acceso_rapido"
                            title="at3_acceso_rapido"
                          />
                        </div>
                        <p>RESULTADOS LABORATORIO</p>
                      </div>
                    </a>
                    <a
                      href="https://www.ucchristus.cl/sistemas/resultados-imagenes"
                      target="">
                      <div className="item-atencion">
                        <div className="img-at">
                          <img
                            src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/default-album/at4_acceso_rapido.png?sfvrsn=3f0f3a82_0"
                            alt="at4_acceso_rapido"
                            title="at4_acceso_rapido"
                          />
                        </div>
                        <p>RESULTADOS IMÁGENES</p>
                      </div>
                    </a>
                    <a
                      href="https://www.ucchristus.cl/sistemas/paga-tu-cuenta"
                      target="">
                      <div className="item-atencion">
                        <div className="img-at">
                          <img
                            src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/default-album/at5_acceso_rapido.png?sfvrsn=3640fb76_0"
                            alt="at5_acceso_rapido"
                            title="at5_acceso_rapido"
                          />
                        </div>
                        <p>PAGA TU CUENTA</p>
                      </div>
                    </a>
                    <a
                      href="https://www.ucchristus.cl/respaldos-planes-de-salud/planes-de-salud"
                      target="">
                      <div className="item-atencion">
                        <div className="img-at">
                          <img
                            src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/default-album/at6_acceso_rapidob4b171563aa24e2aa592f99ac4a279bd.png?sfvrsn=77133fc1_0"
                            alt="at6_acceso_rapido"
                            title="at6_acceso_rapido"
                          />
                        </div>
                        <p>CONTRATA TU PLAN</p>
                      </div>
                    </a>
                  </div>
                </div>
                <a
                  href="https://www.ucchristus.cl/busca-tu-profesional/"
                  className="ic-medico">
                  <img
                    alt=""
                    title="Busca a nuestros profesionales"
                    src="https://www.ucchristus.cl/assets/images/ic-medico.png"
                  />
                </a>
                <div className="lupa"></div>
                <div className="botones-head">
                  <a
                    className="mi-pagina reserva mob btn-portal"
                    href="https://agenda.clinicasancarlos.cl/"
                    target="_blank"
                    rel="noreferrer">
                    PORTAL PACIENTE
                  </a>
                  <a
                    href="https://agenda.ucchristus.cl"
                    className="reserva mob btn-reserva">
                    RESERVA DE HORAS
                  </a>
                </div>
              </div>
              <div className="clear"></div>
            </div>
          </div>
        </header>
        <div className={`menu-mobile ${menuMobileActive ? 'visible' : ''}`}>
          <nav>
            <ul>
              <li className="menu-informacion-al-paciente" nivel="1">
                <a href="#" onClick={() => isSubMenuActive(1)}>
                  INFORMACIÓN AL PACIENTE
                  <i className="fa fa-chevron-down fa-informacion-al-paciente"></i>
                </a>

                <ul
                  className={`sub-menu menu-informacion-al-paciente ${
                    subMenuActive === 1 ? 'visible' : ''
                  }`}>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/informacion-al-paciente/quienes-somos"
                      target="_blank"
                      rel="noreferrer">
                      QUIÉNES SOMOS<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/informacion-al-paciente/christus-health"
                      target="_blank"
                      rel="noreferrer">
                      CHRISTUS HEALTH<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/informacion-al-paciente/paciente-de-regiones"
                      target="_blank"
                      rel="noreferrer">
                      PACIENTE DE REGIONES
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/informacion-al-paciente/preparacion-para-examenes"
                      target="_blank"
                      rel="noreferrer">
                      PREPARACIÓN PARA EXÁMENES
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/informacion-al-paciente/ley-de-derechos-y-deberes"
                      target="_blank"
                      rel="noreferrer">
                      LEY DE DERECHOS Y DEBERES
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/informacion-al-paciente/aranceles"
                      target="_blank"
                      rel="noreferrer">
                      ARANCELES<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/informacion-al-paciente/isapres-y-fonasa"
                      target="_blank"
                      rel="noreferrer">
                      ISAPRES Y FONASA<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/informacion-al-paciente/indicadores-clinicos"
                      target="_blank"
                      rel="noreferrer">
                      INDICADORES CLÍNICOS
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/informacion-al-paciente/mision-y-responsabilidad-social"
                      target="_blank"
                      rel="noreferrer">
                      MISIÓN Y RESPONSABILIDAD SOCIAL
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/informacion-al-paciente/compliance"
                      target="_blank"
                      rel="noreferrer">
                      COMPLIANCE<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/informacion-al-paciente/red-de-mensajes"
                      target="_blank"
                      rel="noreferrer">
                      RED DE MENSAJES<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="menu-especialidades-y-servicios" nivel="1">
                <a href="#" onClick={() => isSubMenuActive(2)}>
                  ESPECIALIDADES Y SERVICIOS
                  <i className="fa fa-chevron-down fa-especialidades-y-servicios"></i>
                </a>

                <ul
                  className={`sub-menu menu-especialidades-y-servicios ${
                    subMenuActive === 2 ? 'visible' : ''
                  }`}>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades"
                      target="_blank"
                      rel="noreferrer">
                      ESPECIALIDADES<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/especialidades-y-servicios/servicios"
                      target="_blank"
                      rel="noreferrer">
                      SERVICIOS<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/especialidades-y-servicios/centros"
                      target="_blank"
                      rel="noreferrer">
                      CENTROS<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/especialidades-y-servicios/programas"
                      target="_blank"
                      rel="noreferrer">
                      PROGRAMAS<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/especialidades-y-servicios/unidades"
                      target="_blank"
                      rel="noreferrer">
                      UNIDADES<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="menu-examenes-y-procedimientos" nivel="1">
                <a href="#" onClick={() => isSubMenuActive(3)}>
                  EXÁMENES Y PROCEDIMIENTOS
                  <i className="fa fa-chevron-down fa-examenes-y-procedimientos"></i>
                </a>

                <ul
                  className={`sub-menu menu-examenes-y-procedimientos ${
                    subMenuActive === 3 ? 'visible' : ''
                  }`}>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/examenes-y-procedimientos/laboratorio-clinico"
                      target="_blank"
                      rel="noreferrer">
                      LABORATORIO CLÍNICO<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/examenes-y-procedimientos/diagnostico-por-imagenes"
                      target="_blank"
                      rel="noreferrer">
                      DIAGNÓSTICO POR IMÁGENES
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/examenes-y-procedimientos/procedimientosclinicos"
                      target="_blank"
                      rel="noreferrer">
                      PROCEDIMIENTOS CLÍNICOS
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="menu-red-de-atencion" nivel="1">
                <a href="#" onClick={() => isSubMenuActive(4)}>
                  RED DE ATENCIÓN
                  <i className="fa fa-chevron-down fa-red-de-atencion"></i>
                </a>

                <ul
                  className={`sub-menu menu-red-de-atencion ${
                    subMenuActive === 4 ? 'visible' : ''
                  }`}>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/red-de-atencion/hospital-clinico"
                      target="_blank"
                      rel="noreferrer">
                      HOSPITAL CLÍNICO<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/red-de-atencion/clinica-uc"
                      target="_blank"
                      rel="noreferrer">
                      CLÍNICA UC<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/red-de-atencion/clinica-san-carlos-de-apoquindo"
                      target="_blank"
                      rel="noreferrer">
                      CLÍNICA SAN CARLOS DE APOQUINDO
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/red-de-atencion/unidad-de-toma-de-muestras"
                      target="_blank"
                      rel="noreferrer">
                      UNIDAD DE TOMA DE MUESTRAS
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/red-de-atencion/centro-medico-virtual"
                      target="_blank"
                      rel="noreferrer">
                      CENTRO MÉDICO VIRTUAL
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/red-de-atencion/centro-de-especialidades-medicas"
                      target="_blank"
                      rel="noreferrer">
                      CENTRO DE ESPECIALIDADES MÉDICAS
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/red-de-atencion/centro-medico-san-joaquin"
                      target="_blank"
                      rel="noreferrer">
                      CENTRO MÉDICO SAN JOAQUÍN
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/red-de-atencion/centro-medico-lira"
                      target="_blank"
                      rel="noreferrer">
                      CENTRO MÉDICO LIRA<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/red-de-atencion/edificio-medico-alcantara"
                      target="_blank"
                      rel="noreferrer">
                      EDIFICIO MÉDICO ALCÁNTARA
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/red-de-atencion/centro-medico-irarrazaval"
                      target="_blank"
                      rel="noreferrer">
                      CENTRO MÉDICO IRARRÁZAVAL
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/red-de-atencion/centro-medico-san-jorge"
                      target="_blank"
                      rel="noreferrer">
                      CENTRO MÉDICO SAN JORGE
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/red-de-atencion/centro-de-diagnostico-bilbao"
                      target="_blank"
                      rel="noreferrer">
                      CENTRO DE DIAGNÓSTICO BILBAO
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/red-de-atencion/cesfam-juan-pablo-ii"
                      target="_blank"
                      rel="noreferrer">
                      CESFAM JUAN PABLO II
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/red-de-atencion/cesfam-madre-teresa-de-calcuta"
                      target="_blank"
                      rel="noreferrer">
                      CESFAM MADRE TERESA DE CALCUTA
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/red-de-atencion/cesfam-san-alberto-hurtado"
                      target="_blank"
                      rel="noreferrer">
                      CESFAM SAN ALBERTO HURTADO
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/red-de-atencion/centro-médico-rancagua"
                      target="_blank"
                      rel="noreferrer">
                      CENTRO MÉDICO RANCAGUA
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="menu-blog-salud-uc" nivel="1">
                <a href="#" onClick={() => isSubMenuActive(5)}>
                  BLOG SALUD UC
                  <i className="fa fa-chevron-down fa-blog-salud-uc"></i>
                </a>

                <ul
                  className={`sub-menu menu-blog-salud-uc ${
                    subMenuActive === 5 ? 'visible' : ''
                  }`}>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/blog-salud-uc/abc-de-la-salud"
                      target="_blank"
                      rel="noreferrer">
                      ABC DE LA SALUD<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/blog-salud-uc/uc-christus-conecta"
                      target="_blank"
                      rel="noreferrer">
                      UC CHRISTUS CONECTA<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/blog-salud-uc/articulos"
                      target="_blank"
                      rel="noreferrer">
                      ARTÍCULOS<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/blog-salud-uc/maternidad"
                      target="_blank"
                      rel="noreferrer">
                      MATERNIDAD<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/blog-salud-uc/familia"
                      target="_blank"
                      rel="noreferrer">
                      FAMILIA<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/blog-salud-uc/vida-sana"
                      target="_blank"
                      rel="noreferrer">
                      VIDA SANA<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/blog-salud-uc/medicina-uc"
                      target="_blank"
                      rel="noreferrer">
                      MEDICINA UC<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/blog-salud-uc/noticias"
                      target="_blank"
                      rel="noreferrer">
                      NOTICIAS<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/blog-salud-uc/testimonios"
                      target="_blank"
                      rel="noreferrer">
                      TESTIMONIOS<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="menu-convenios" nivel="1">
                <a href="#" onClick={() => isSubMenuActive(6)}>
                  CONVENIOS<i className="fa fa-chevron-down fa-convenios"></i>
                </a>

                <ul
                  className={`sub-menu menu-convenios ${
                    subMenuActive === 6 ? 'visible' : ''
                  }`}>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/convenios-uc-christus/laboratorio-clinico"
                      target="_blank"
                      rel="noreferrer">
                      LABORATORIO CLÍNICO
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/convenios-uc-christus/anatomia-patologica"
                      target="_blank"
                      rel="noreferrer">
                      ANATOMÍA PATOLÓGICA<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/convenios-uc-christus/empresas-e-instituciones"
                      target="_blank"
                      rel="noreferrer">
                      EMPRESAS E INSTITUCIONES
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/convenios-uc-christus/convenios-ex-alumnos-uc"
                      target="_blank"
                      rel="noreferrer">
                      EX-ALUMNOS UC<i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                  <li nivel="2">
                    <a
                      href="https://www.ucchristus.cl/convenios-uc-christus/administrador-de-convenios"
                      target="_blank"
                      rel="noreferrer">
                      ADMINISTRADOR DE CONVENIOS
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
          <div className="mi-pagina d-none">MI PAGINA DE SALUD</div>
          <div className="fonos-head">
            <div className="item-fono">
              Reserva de Horas
              <a href="tel:226767000">
                <span>22 676 7000</span>
              </a>
            </div>
            <div className="item-fono red">
              <b>Rescate ambulancia</b>
              <a href="https://www.ucchristus.cl/especialidades-y-servicios/servicios/unidad-de-rescate">
                <span>800 265 265</span>
              </a>
            </div>
          </div>
          <div className="redes-mobile">
            <a href="https://www.facebook.com/UCCHRISTUS">
              <img
                alt=""
                src="https://www.ucchristus.cl/assets/images/rs1.png"
              />
            </a>
            <a href="https://www.instagram.com/ucchristus/?hl=es-la">
              <img
                alt=""
                src="https://www.ucchristus.cl/assets/images/rs4.png"
              />
            </a>
            <a href="https://twitter.com/ucchristus">
              <img
                alt=""
                src="https://www.ucchristus.cl/assets/images/rs2.png"
              />
            </a>
            <a href="https://cl.linkedin.com/company/red-de-salud-uc-christus">
              <img
                alt=""
                src="https://www.ucchristus.cl/assets/images/rs5.png"
              />
            </a>
            <a href="https://www.youtube.com/user/UCCHRISTUS">
              <img
                alt=""
                src="https://www.ucchristus.cl/assets/images/rs3.png"
              />
            </a>
            <a href="https://open.spotify.com/user/7x5ir8p8a0v5i0qnu1loj3xqi">
              <img
                alt=""
                src="https://www.ucchristus.cl/assets/images/rs6.png"
              />
            </a>
          </div>
        </div>

        {props?.goRouter?.length > 0 ? (
          <div className="background-white py-3">
            <div className="d-flex justify-content-between content-responsive">
              <div className=" breadcrumbs align-self-end">
                {!hideBtnBack.includes(location.pathname) && (
                  <div
                    className="d-flex align-items-center d-block d-md-none"
                    onClick={() => history.push(props.goRouter[0].go)}>
                    <i className="icon-chevron-right icon-reverse"></i>{' '}
                    <span
                      className="txt-4--regular-pr"
                      style={{color: 'var(--theme-color)'}}
                      ƒ>
                      Volver
                    </span>
                  </div>
                )}
                {props.goRouter.map((c, i) => (
                  <div
                    key={i}
                    className={`c-pointer`}
                    onClick={() => onNavigate(c)}>
                    <span className="title-primary--regular line-14 color-lilac">
                      {c.description}
                    </span>
                    {props?.goRouter?.length > 1 &&
                      props?.goRouter?.length > i + 1 && (
                        <svg
                          className="mx-2"
                          width="9"
                          height="11"
                          viewBox="0 0 9 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M1 1L7.5 5L1 9.5"
                            stroke="#7A9DD6"
                            strokeWidth="1.5"
                          />
                        </svg>
                      )}
                  </div>
                ))}
              </div>
              <div className="d-flex flex-column align-items-end align-self-end mt-3">
                <img src={powerByYapp} className="power-by-yapp"></img>
              </div>
            </div>
            {props?.goRouter?.length > 0 && (
              <div className="content-responsive txt-2--regular mt-2">
                {props.goRouter.slice(-1)[0].title}
              </div>
            )}
          </div>
        ) : (
          <div className="background-white p-3"></div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    goRouter: state.app.goRouter,
  }
}

export default connect(mapStateToProps)(HeaderUC)
