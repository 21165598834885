import React, { useEffect, useRef, useState } from 'react'
import { logEvent } from '../../Utils/utils'
import './Video.css'

function Video(props) {
  const { url, handlePlay } = props
  const videoRef = useRef()
  return (
    <>
      <div className="player">
        <video
          id='video'
          ref={videoRef}
          onPlay={() => handlePlay(true)}
          onPause={() => handlePlay(false)}
          onEnded={() => handlePlay(false)}
          width="400"
          controls
          className="player__video view">
          <source src={url} type="video/mp4" />
          Your browser does not support HTML video.
        </video>
      </div>
    </>
  )
}

export default Video
