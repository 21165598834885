import {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {Button} from 'react-bootstrap'
import Modal from 'react-modal'
import {clearUserState} from '../redux/modules/user'
import {clearAppState} from '../redux/modules/app'
import {
  _formatRut,
  _validateMail,
  _validateRut,
  getJWTdata,
} from '../Utils/tools'
import {
  requestUnique,
  requestGenerateLink,
  existUserClient,
  getConfigPage,
  getBeneficiaries,
} from '../Utils/api'

import {
  changeInitialLoading,
  setIsPro,
  changeConfig,
} from '../redux/modules/app'
import {setFreeShipping, setDeliveryCode} from '../redux/modules/delivery'

import {
  setBasicInfo,
  setDocumentsPending,
  setUserExtraInfo,
} from '../redux/modules/user'
import icErrorServer from '../assets/img/error_server.svg'
import imgVidaCamaraHeart from '../assets/img/vida_camara_heart.png'
import DiscountCard from '../Components/Card/DiscountCard'
import Input from '../Components/Forms/Input'
import ButtonYapp from '../Components/Buttons/ButtonYapp'
import LoadingSura from '../Components/Loading/LoadingSura'
import {logEvent} from '../Utils/utils'
import {signOut} from '../Utils/auth'
import TypeSidetab from '../Components/TypeForm/TypeSidetab'
import check from '../assets/img/checkblue.png'
import Carousel from '../Components/Carousel/Carousel'
import logoAdich from '../assets/img/logo_adich.png'
import logoAsfar from '../assets/img/logo_asfar.png'
import logoCofar from '../assets/img/logo_cofar.png'
import logoEco from '../assets/img/logo_eco.png'
import logoElQuimico from '../assets/img/logo_el_quimico.png'
import logoFarplus from '../assets/img/logo_farplus.png'
import logoLaReina from '../assets/img/logo_lareina.png'
import logoLigaEpilepsia from '../assets/img/logo_liga_epilepsia.png'
import logoMeddica from '../assets/img/logo_meddica.png'
import logoProfar from '../assets/img/logo_profar.png'
import logoBiofar from '../assets/img/biofar.png'
import logoAhumada from '../assets/img/farmacia-ahumada.png'
import logoSalcobrand from '../assets/img/salcobrand.png'
import logoRedfarma from '../assets/img/redfarma.png'
import logoCruzVerde from '../assets/img/cruz-verde.png'
import logoFarmaloop from '../assets/img/logoFARMALOOP.png'
import logoFarmex from '../assets/img/logoFARMEX.png'
import logoRiesco from '../assets/img/logoRIESCO.png'
import logoCopihues from '../assets/img/loscopihues.jpg'
import logoHospitalTrabajador from '../assets/img/logo-hospital-trabajador.png'
import checkBg from '../assets/img/Shape.png'
import SectionBenefitCards from '../Components/BenefitCards/SectionBenefitCards'

import pig from '../assets/img/pig.png'
import useScreenSize from '../Hooks/useScreenSize'
import { productsWithSuraBenefits } from '../Utils/constants'
const _ = require('lodash')

function Home() {
  const {hash} = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const themeColor = useSelector((state) => state.app.themeColor)
  const textExtraHome = useSelector((state) => state.app.text_extra_home)
  const vidaCamaraLoader = process.env.REACT_APP_VIDA_CAMARA_LOADER === 'TRUE'
  const skipLogin = process.env.REACT_APP_SKIP_LOGIN === 'TRUE'
  const suraLoader = process.env.REACT_APP_SURA_LOADER === 'TRUE'
  const hideBannerMisal = process.env.REACT_APP_HIDE_BANNER_MINSAL === 'TRUE'
  const hidePromotionMsg = process.env.REACT_APP_HIDE_PROMOTION === 'TRUE'
  const location = useSelector((state) => state.app.location)
  const [appState, setAppState] = useState({
    email: '',
    identity: '',
    policies: false,
    valid: false,
  })
  const [sending, setSending] = useState(false)
  const [validType, setValidationType] = useState(null)
  const [subtitle, setSubtitle] = useState(null)
  const [showRegister, setShowRegister] = useState(null)
  const clientName = process.env.REACT_APP_CLIENT_NAME || 'Yapp Chile'
  const [modalErrorServerIsOpen, setModalErrorServerIsOpen] = useState(false)
  const [modalErrorValidation, setErrorValidation] = useState(false)
  const [modalNotRegistered, setModalNotRegistered] = useState(false)
  const [clientLoading, setClientLoading] = useState(false)
  const userInfo = useSelector((state) => state.user.userInfo)
  const {width} = useScreenSize()
  const [isDesktop, setIsDesktop] = useState()
  const [isTablet, setIsTablet] = useState()
  const [isMobile, setIsMobile] = useState()

  useEffect(() => {
    if (width > 768) {
      setIsDesktop(true)
      setIsTablet(false)
      setIsMobile(false)
    } else if (width > 380 && width <= 768) {
      setIsTablet(true)
      setIsDesktop(false)
      setIsMobile(false)
    } else {
      setIsMobile(true)
      setIsDesktop(false)
      setIsTablet(false)
    }
  }, [width])

  useEffect(async () => {
    if (
      [undefined, null, 'NULL'].includes(
        process.env?.REACT_APP_EXTERNAL_VALID_TYPE,
      )
    ) {
      if (userInfo?.signInMethod) {
        return history.replace('/search')
      } else {
        return
      }
    }
    if (
      process.env.REACT_APP_SKIP_LOGIN === 'TRUE' &&
      process.env.REACT_APP_ON_LOGIN === 'TRUE'
    ) {
      if (userInfo?.signInMethod) {
        return history.replace('/search')
      } else {
        dispatch(clearUserState())
        setValidationType(process.env.REACT_APP_EXTERNAL_VALID_TYPE)
      }
    } else {
      setValidationType(process.env.REACT_APP_EXTERNAL_VALID_TYPE)
      let uniqueHash = hash ? hash : process.env.REACT_APP_UNIQUE_HASH
      dispatch(changeInitialLoading(true))
      setClientLoading(true)
      try {
        try {
          localStorage.clear()
          sessionStorage.clear()
        } catch (error) {
          console.log(error)
        }

        let result = await requestUnique(uniqueHash)
        if (!result) {
          return history.replace('/no-access')
        }
        await dispatch(changeConfig(result))
        localStorage.setItem(
          'LOCAL_DATA',
          JSON.stringify({
            hash: uniqueHash,
            origin: process.env.REACT_APP_CLIENT_NAME.toLowerCase(),
            site: result?.data,
            client_config: {
              delivery_time: _.get(
                result,
                'data.config[0].config.delivery_time',
              ),
            },
          }),
        )

        setSubtitle(_.get(result, 'data.config[0].config.subtitle', false))
        setShowRegister(
          _.get(result, 'data.config[0].config.show_register', false),
        )

        let isProfessional = result?.data?.config[0].config?.professional

        if (isProfessional) {
          dispatch(setIsPro(result?.data?.config[0].config?.professional))
        } else {
          dispatch(setIsPro(false))
        }
      } catch (ignore) {
        dispatch(changeInitialLoading(false))
        setClientLoading(false)
      }
      dispatch(changeInitialLoading(false))
      setClientLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  function validEmail(e) {
    setAppState({email: e, valid: _validateMail(e)})
  }

  function validIdentity(e) {
    setAppState({
      identity: _formatRut(e),
      valid: _validateRut(e),
    })
  }

  async function nextAction(infoUser) {
    if (
      [undefined, null, 'NULL'].includes(
        process.env?.REACT_APP_EXTERNAL_VALID_TYPE,
      )
    ) {
      dispatch(changeInitialLoading(false))
      return history.push('/signin')
    }

    if (
      process.env.REACT_APP_SKIP_LOGIN === 'TRUE' &&
      process.env.REACT_APP_ON_LOGIN === 'TRUE'
    ) {
      setSending(true)
      dispatch(changeInitialLoading(true))
      logEvent('Session Started')
      let client = await existUserClient(appState.identity)
      let clientData = _.get(client, 'data[0]', null)
      if (!clientData?.identity) {
        setSending(false)
        dispatch(changeInitialLoading(false))
        return setErrorValidation(true)
      } else {
        dispatch(changeInitialLoading(false))
        return history.push('/signin')
      }
    } else {
      setSending(true)
      dispatch(changeInitialLoading(true))
      if (process.env.REACT_APP_PROFILE !== 'INSURANCE') {
        logEvent('Session Started')
      }
      if (validType === 'identity') {
        try {
          let validation = [undefined, null].includes(infoUser)
            ? await existUserClient(appState.identity)
            : infoUser

          let userData = _.get(validation, 'data[0]', null)

          if (userData?.identity) {
            dispatch(
              setDeliveryCode({
                code: userData?.delivery_code,
                value: userData?.delivery_value,
              }),
            )
            dispatch(
              setFreeShipping({
                code: userData?.free_shipping,
                value: userData?.free_shipping_value,
              }),
            )

            sessionStorage.setItem('USER_DATA', JSON.stringify(userData))
          } else {
            if (showRegister) {
              setModalNotRegistered(true)
              setSending(false)
              dispatch(changeInitialLoading(false))
              return
            }
            dispatch(changeInitialLoading(false))
            return setErrorValidation(true)
          }
        } catch (error) {
          console.log('Error:', error)
          return
        }
      }
      try {
        if (
          validType === 'identity' &&
          process.env.REACT_APP_PROFILE === 'INSURANCE'
        ) {
          await signOut()
          dispatch(clearUserState())
          dispatch(clearAppState())
          dispatch(changeInitialLoading(false))
          dispatch(setBasicInfo(appState.email, appState.identity))
          return history.push('/signin')
        }
        let uniqueHash = hash ? hash : process.env.REACT_APP_UNIQUE_HASH
        let result = await requestGenerateLink(uniqueHash, {
          email: appState.email,
          identity: appState.identity,
        })

        if (result?.data?.url) {
          dispatch(setBasicInfo(appState.email, appState.identity))

          let hash = _.last(result.data.url.split('/'))
          getConfigPage(hash)
            .then(async (res) => {
              if (!res) {
                return history.push('/error')
              }

              localStorage.setItem('JWT', res?.data?.token)
              localStorage.setItem(
                'LOCAL_DATA',
                JSON.stringify({
                  hash: hash,
                  origin: process.env.REACT_APP_CLIENT_NAME.toLowerCase(),
                  site: res?.data,
                  client_config: {
                    delivery_time: _.get(
                      res?.data,
                      'config[0].config.delivery_time',
                    ),
                  },
                }),
              )

              try {
                let jwtData = getJWTdata()

                window.gtag('set', 'user_id', jwtData?.user_id)
                window.gtag(
                  'set',
                  'user_type',
                  _.get(res, 'data.user_type', 'user'),
                )
              } catch (error) {
                console.log('Error set user gtag.')
              }

              dispatch(setUserExtraInfo(_.get(res?.data, 'info', {})))
              let route = res?.data?.route
              if (route === '/search') {
                if (location?.label && location?.nro) {
                  route =
                    '/search/shopping_bag/' +
                    Math.floor(
                      Math.random() * Math.floor(100000000000000000000),
                    )
                  return history.replace(route, {product: null, from: 'page'})
                }
              }

              let productId = localStorage.getItem('product')
              if (productId) {
                history.push(`/product/detail/${productId}`, {
                  product_id: productId,
                  external_vademecum: true,
                })
                localStorage.removeItem('product')
              } else {
                history.replace(route, {site: res?.data})
              }
            })
            .catch((ignore) => {})
            .finally(() => {
              dispatch(changeInitialLoading(false))
            })

          // window.location.replace(url)
        } else {
          setModalErrorServerIsOpen(true)
          dispatch(changeInitialLoading(false))
        }
      } catch (error) {
        console.log(error)
        setModalErrorServerIsOpen(true)
        dispatch(changeInitialLoading(false))
      }
    }
  }

  function onKeyDown(e) {
    if (e.key === 'Enter') {
      nextAction()
      e.preventDefault()
    }
  }

  function clickContinue() {
    let infoUser = {}
    infoUser.data = [
      {
        identity: appState.identity,
        email: null,
        name: null,
        address: null,
        address_additional: null,
        phone: null,
        latitude: null,
        longitude: null,
      },
    ]

    setModalNotRegistered(false)
    dispatch(setDocumentsPending(true))
    nextAction(infoUser)
  }

  function goTo(page) {
    if (page === 'policies') {
      return window.open('https://yapp.cl/privacidad')
    }
    if (page === 'terms') {
      return window.open('https://yapp.cl/terminos')
    }
    if (page === 'signin') {
      history.push('/signin', {hasOrigin: true})
    }
  }

  const pharmaciesLogos = [
    {
      alt: "Liga epilepsia logo",
      title: "logo liga epilepsia",
      src: logoLigaEpilepsia
    },
    {
      alt: "Profar logo",
      title: "Profar logo",
      src: logoProfar
    },
    {
      alt: "Cofar logo",
      title: "Cofar logo",
      src: logoCofar
    },
    {
      alt: "Eco farmacias logo",
      title: "Eco farmacias logo",
      src: logoEco
    },
    {
      alt: "Asociacion diabeticos logo",
      title: "Asociacion diabeticos logo",
      src: logoAdich
    },
    {
      alt: "La reina logo",
      title: "La reina logo",
      src: logoLaReina
    },
    {
      alt: "Meddica logo",
      title: "Meddica logo",
      src: logoMeddica
    },
    {
      alt: "Farplus logo",
      title: "Farplus logo",
      src: logoFarplus
    },
    {
      alt: "El quimico logo",
      title: "El quimico logo",
      src: logoElQuimico
    },
    {
      alt: "Asfar logo",
      title: "Asfar logo",
      src: logoAsfar
    },
    {
      alt: "Farmaloop logo",
      title: "Farmaloop logo",
      src: logoFarmaloop
    },
    {
      alt: "Farmex logo",
      title: "Farmex logo",
      src: logoFarmex
    },
    {
      alt: "Farmacias Riesco logo",
      title: "Farmacias Riesco logo",
      src: logoRiesco
    },
    {
      alt: "Los Copihues logo",
      title: "Los Copihues logo",
      src: logoCopihues
    },
  ]

  return (
    <>
      {![null, undefined, false].includes(clientLoading) && suraLoader && (
        <LoadingSura />
      )}
      {![null, undefined, false].includes(clientLoading) && vidaCamaraLoader && (
        <div className="vida-camara-loader">
          <img
            src={imgVidaCamaraHeart}
            className="vida-camara-loader-img"
            alt="Corazón de carga"
          />
        </div>
      )}
      <div
        className={`flex-grow-1 pt-md-2 ${
          skipLogin ? 'd-flex align-items-center justify-content-center' : ''
        }`}
        style={
          themeColor?.background_color && {
            background: `${themeColor?.background_color}`,
          }
        }>
        <div className="content-new-home mb-4">
          <div className="text-center mx-auto mt-5">
            { process.env.REACT_APP_CLIENT_NAME === 'Sura'
              ? <>
                  <p className={"subtitle-home-sura px-3"}>
                    Te ayudamos a encontrar los mejores precios en farmacias{' '}
                  </p>
                  <br />
                  <h2 className={"title-home-sura mb-2"}>
                    Aprovecha un 30% de descuento en categorías de productos no reembolsables{' '}
                  </h2>
                  <br />
                </>
              : <>
                  <h2 className={"title-home-vs mb-2"}>
                    ¡Cotiza, compara y ahorra!{' '}
                  </h2>
                  <p className={"subtitle-home-sura px-3"}>
                    ¡Te ayudaremos a encontrar los mejores precios en farmacias,
                    siempre!
                  </p>
                </>
            }
          </div>
          {hidePromotionMsg && (
            <div className={textExtraHome ? 'mb-1 ' : 'mb-4'}>
              <DiscountCard color={'--theme-color'} />
            </div>
          )}
          <div className={`d-flex justify-content-center ${isMobile ? 'maxw-135' : 'maxw-200'} mx-auto mb-4`}>
            <img className='w-100' src={pig} alt='chanchito ahorro'/>
          </div>
          {![null, undefined, ''].includes(textExtraHome) && (
            <div className="maxw-300 mx-auto mb-4">
              <p className="txt-6--regular-g1">{textExtraHome}</p>
            </div>
          )}
          <div className="justify-content-center mb-5 mx-3">
            {skipLogin !== 'TRUE' ? (
              <form className="maxw-300 mx-auto">
                {validType === 'email' && (
                  <>
                    <div>
                      <Input
                        id="gtm-webapp-home-email"
                        type="email"
                        doChange={validEmail}
                        onKeyDown={onKeyDown}
                        label="Ingresa tu correo"
                        placeholder="Ingresa tu correo"
                      />
                    </div>
                  </>
                )}
                {validType === 'identity' && (
                  <>
                    <div>
                      <Input
                        id="gtm-webapp-home-rut"
                        type="text"
                        label={
                          process.env.REACT_APP_CLIENT_NAME === 'Sura'
                            ? 'Ingresa rut del titular'
                            : 'Ingresa tu Rut'
                        }
                        placeholder={
                          process.env.REACT_APP_CLIENT_NAME === 'Sura'
                            ? 'Ingresa rut del titular'
                            : 'Ingresa tu Rut'
                        }
                        value={appState.identity}
                        doChange={(e) => validIdentity(e)}
                        onKeyDown={onKeyDown}
                      />
                    </div>
                  </>
                )}
                {!appState.valid &&
                  (appState.email?.length > 0 ||
                    appState.identity?.length > 0) && (
                    <p className="text-danger txt-paragraph--small mt-2 ml-2">
                      {appState.email?.length > 0
                        ? '* Correo inválido'
                        : appState.identity?.length > 0
                        ? '* Rut inválido'
                        : '* Error en la validación.'}
                    </p>
                  )}

                <ButtonYapp
                  className="mb-4 mx-auto px-5 mt-4"
                  id="gtm-webapp-home-btn-send"
                  title="Ir a cotizar"
                  onClick={() => nextAction()}
                  big
                  color={'--theme-color'}
                  disabled={
                    (!appState.valid ||
                      appState.email?.length + appState.identity?.length <= 0 ||
                      sending) &&
                    ![undefined, null, 'NULL'].includes(
                      process.env?.REACT_APP_EXTERNAL_VALID_TYPE,
                    )
                    /* !policiesState || */
                  }
                />
              </form>
            ) : (
              <>
                <ButtonYapp
                  className="mb-4 mx-auto px-5 mt-4"
                  id="gtm-webapp-home-btn-send"
                  title="Comenzar"
                  onClick={() => goTo('signin')}
                  big
                  color={'--theme-color'}
                />
              </>
            )}
          </div>
          <SectionBenefitCards products={productsWithSuraBenefits} />
          <div className={`text-center ${isMobile ? 'maxw-250' : 'maxw-400'} mx-auto mt-5`}>
            <h2 className="title-home-sura mb-2 mb-2">
              Disfruta de estos beneficios
            </h2>
            <hr className='hr-separation'></hr>
          </div>
          <div className="maxw-900 mx-auto mt-4 px-3">
            {
              process.env.REACT_APP_CLIENT_NAME === 'Vida Security' ? (
                <>
                  <div className='mb-3'>
                    <div className='d-inline-block check-item'>
                      <img className='mr-2' src={check} alt='' />
                    </div>
                    <p className="txt-xl-subtitle mb-3 d-inline">
                      Reembolso en línea
                    </p>
                  </div>
                  <div className='mb-3'>
                    <div className='d-inline-block check-item'>
                      <img className='mr-2' src={check} alt='' />
                    </div>
                    <p className="txt-xl-subtitle mb-3 d-inline">
                      Compara precios en diferentes farmacias
                    </p>
                  </div>
                  <div className=''>
                    <div className='d-inline-block check-item'>
                      <img className='mr-2' src={check} alt='' />
                    </div>
                    <p className="txt-xl-subtitle mb-3 d-inline">
                      Envío 50% gratis
                    </p>
                  </div>
                </>
              ) : (
                <div className={`d-flex ${!isMobile ? 'justify-content-between' : 'flex-column align-items-center'}  mt-5 mb-5`}>
                  <div className='info-card mb-3'>
                    <h3 className='title-home-lg-sura mb-4'>Como asegurado Seguros Colectivos SURA:</h3>
                    <p className="text-home-light mb-2">
                      <img className='mr-2 pb-1' src={checkBg} alt='' />
                      Comparar precio online
                    </p>
                    <p className="text-home-light mb-2">
                      <img className='mr-2 pb-1' src={checkBg} alt='' />
                      Elegir la farmacia con mejor precio
                    </p>
                    <p className="text-home-light mb-3">
                      <img className='mr-2 pb-1' src={checkBg} alt='' />
                      Reembolsar 100% en línea
                    </p>
                    <p className="text-home-light">
                      <img className='mr-2 pb-1' src={checkBg} alt='' />
                      Envío gratuito de compra con receta médica
                    </p>
                  </div>
                  <div className='info-card mb-3'>
                    <h3 className='title-home-lg-sura mb-4'>Si tienes cualquier otro seguro en Seguros SURA:</h3>
                    <p className="text-home-light mb-2">
                      <img className='mr-2 pb-1' src={checkBg} alt='' />
                      Comparar precio online
                    </p>
                    <p className="text-home-light mb-2">
                      <img className='mr-2 pb-1' src={checkBg} alt='' />
                      Elegir la farmacia con mejor precio
                    </p>
                    <p className="text-home-light">
                      <img className='mr-2 pb-1' src={checkBg} alt='' />
                      Envío gratuito de compra con receta médica
                    </p>
                  </div>
                </div>
              )
            }
            
          </div>
          <div className='pt-3 pb-1'>
            <div className={`text-center ${isMobile ? 'maxw-250 mb-5' : 'maxw-400'} mx-auto mt-5`}>
              <h2 className="title-home-sura mt-4 mb-2">
                Farmacias para comprar
              </h2>
              <hr className='hr-separation'></hr>
            </div>

            <Carousel 
              logos={pharmaciesLogos}
              screen={width}
              controls={
                isDesktop ? 3 : isTablet ? 4 : 7
              }
            />
          </div>

          <div className='pb-5'>
            <div className={`text-center ${isMobile ? 'maxw-250 mb-5' : 'maxw-400'} mx-auto mt-5`}>
              <h2 className="title-home-sura mt-4 mb-2">
                Farmacias para cotizar
              </h2>
              <hr className='hr-separation'></hr>
            </div>

            <Carousel 
              logos={[{
                src: logoSalcobrand,
                alt: 'Logo Salcobrand',
                title: 'Salcobrand'
              },
              {
                src: logoCruzVerde,
                alt: 'Logo Cruz Verde',
                title: 'Cruz Verde'
              },
              {
                src: logoAhumada,
                alt: 'Logo Ahumada',
                title: 'Ahumada'
              },
              {
                src: 'https://s3.us-east-2.amazonaws.com/s3.yapp/img/pharmacychains/logo/logoFarm_Valdivia.jpg',
                alt: 'Logo Redfarma',
                title: 'Redfarma'
              },
              {
                src: 'https://s3.us-east-2.amazonaws.com/s3.yapp/img/pharmacychains/logo/FarmaciaComunitariaProvidencia.png',
                alt: 'Logo Biofarma',
                title: 'Biofarma'
              },
              {
                src: 'https://s3.us-east-2.amazonaws.com/s3.yapp/img/pharmacychains/logo/vitabotica2+(1).jpg',
                alt: 'Logo Biofarma',
                title: 'Biofarma'
              },
              {
                src: 'https://s3.us-east-2.amazonaws.com/s3.yapp/img/pharmacychains/logo/logoFDrJPR.png',
                alt: 'Logo Biofarma',
                title: 'Biofarma'
              },
              {
                src: logoHospitalTrabajador,
                alt: 'Logo Biofarma',
                title: 'Biofarma'
              }
              ]}
              screen={width}
              controls={
                isDesktop ? 2 : isTablet ? 2 : 4
              }
            />
          </div>
        </div>

        {process.env.REACT_APP_CLIENT_NAME === 'Vida Security' && (
          <TypeSidetab 
            id='jjdc5aSF'
            buttonColor={'#282E55'}          
            buttonText={'Sugerencias'}
          />
        )}
        {process.env.REACT_APP_CLIENT_NAME === 'Sura' && (
          <TypeSidetab
            id='wea0XraP'
            buttonColor={'#0033A0'}          
            buttonText={'Sugerencias!'}
          />
        )}

        <Modal
          isOpen={modalErrorServerIsOpen}
          onAfterOpen={null}
          onRequestClose={() => setModalErrorServerIsOpen(false)}
          className="modal-custom"
          overlayClassName="modal-custom-bg"
          contentLabel="Modal1"
          ariaHideApp={false}>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img
              src={icErrorServer}
              alt="Error"
              className="mb-3"
              id="gtm-webapp-error-server-one"
            />
            <h4 className="title-primary--3xl-bold">Lo sentimos</h4>
            <p className="title-primary--xl text-center mb-30">
              No podemos conetar con los servidores de Yapp en este momento...
            </p>
          </div>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <Button
              className="btn-default--small btn-next mb-3"
              variant="primary"
              onClick={() => {
                setModalErrorServerIsOpen(false)
                setSending(false)
              }}>
              Volver a intentar
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={modalErrorValidation}
          onAfterOpen={null}
          onRequestClose={() => setErrorValidation(false)}
          className="modal-custom"
          overlayClassName="modal-custom-bg"
          contentLabel="Modal1"
          ariaHideApp={false}>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <h2 className="title-primary--3xl-regular mb-3 text-center">
              {process.env.REACT_APP_CLIENT_NAME === 'Vida Security'
                ? 'Tu póliza no tiene reembolso en línea. ¿Deseas continuar?'
                : 'No hemos podido validarte como usuario.'}
            </h2>
            <div className="mx-auto">
              <button
                className="btn-default w-100 btn-next mt-3"
                onClick={() => {
                  setErrorValidation(false)
                  setSending(false)
                  logEvent('Session Started', {
                    'Validated Insurance Policy': 'FALSE',
                    'Insurance Type': 'External'
                  })
                  {if(process.env.REACT_APP_CLIENT_NAME === 'Vida Security'){
                    window.location.replace("https://web.yapp.cl/")
                  }}
                }}>
                {process.env.REACT_APP_CLIENT_NAME === 'Vida Security'
                ? 'Continuar'
                : 'Reintentar'}
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={modalNotRegistered}
          onAfterOpen={null}
          onRequestClose={() => setModalNotRegistered(false)}
          className="modal-custom"
          overlayClassName="modal-custom-bg"
          contentLabel="Modal1"
          ariaHideApp={false}
          id="gtm-webapp-home-no-registered-modal">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="title-primary--xxl txt-center">
              ¡Ops! No estás registrado
            </p>
            <p className="txt-paragraph-lh mt-3">
              {'Antes de finalizar tu compra, te pediremos que adjuntes la foto de tu carnet de identidad y algún documento que acredite que actualmente eres vecino de ' +
                clientName +
                '.'}
            </p>
            <div className="mx-auto">
              <button
                className="btn-default w-100 btn-next mt-3"
                id="gtm-webapp-home-no-registered-button"
                onClick={() => {
                  clickContinue(false)
                }}>
                Continuar
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default Home
